import React, { useState, useContext, useCallback } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import CustomSelect from "shared/components/FormElements/Select";

import { CheckAuthorization, isReceiptIntheSchool } from "shared/util/util";

import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import ReceiptNew from "./ReceiptNew";
import ReceiptList from "./ReceiptList";
import ReceiptItem from "./ReceiptItem";

import { CURRENT_EDUCATION_YEAR_ID } from "shared/util/constants";

import TabPanel from "shared/components/UIElements/TabPanel";

const Receipt = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const [pageItems, setPageItems] = useState(store.receipts);
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);
  const [educationYear, setEducationYear] = useState(CURRENT_EDUCATION_YEAR_ID);

  // const Authorization = CheckAuthorization(auth.userId);
  // if (Authorization.isSchoolResponsible) {
  //   const currentYearContracts = store.contracts.filter((item) => item.year === CURRENT_EDUCATION_YEAR_ID);
  //   const currentpageItems = store.receipts.filter((item) =>
  //     isReceiptIntheSchool(item, Authorization.currentSchoolId, [...currentYearContracts])
  //   );
  //   setPageItems(currentpageItems);
  // }

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const yearChangeHandler = (id, value) => {
    setEducationYear(value);
    const currentYearContracts = store.contracts.filter((item) => item.year === value);
    const currentYearContractIds = currentYearContracts.map((item) => item.id);
    const currentpageItems = store.receipts.filter((item) => currentYearContractIds.includes(item.contract.toString()));
    setPageItems(currentpageItems);
  };

  const ItemSelectHandler = useCallback((itemid) => {
    setSelectedItemShow(true);
    setSelectedItemId(itemid);
  }, []);

  const ItemUpdateHandler = useCallback((itemid) => {
    setSelectedItemId(itemid);
  }, []);

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `receipt-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Tahsilatlar" {...a11yProps(0)} />
          <Tab label="Yeni Tahsilat" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <div style={{ width: "300px" }}>
        <CustomSelect
          options={store.educationYearsOptions}
          id="year"
          onInput={(id, value) => yearChangeHandler(id, value)}
          label=""
          initialValid={true}
          initialValue={store.educationYearsOptions.find((item) => item.value.toString() === educationYear)}
        ></CustomSelect>
      </div>
      <TabPanel value={tabValue} index={0}>
        <div className="adminpageContainer">
          {pageItems && (
            <div className="adminListContainer">
              <ReceiptList items={pageItems} onItemSelect={ItemSelectHandler} />
            </div>
          )}
          {selectedItemId && selectedItemShow && (
            <div className="adminUpdateContainer">
              <ReceiptItem
                itemid={selectedItemId}
                onItemUpdate={ItemUpdateHandler}
                onClose={() => setSelectedItemShow(false)}
              ></ReceiptItem>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ReceiptNew selectedYear={educationYear}></ReceiptNew>
      </TabPanel>
    </React.Fragment>
  );
};

export default Receipt;
