import React from "react";

import "./LoadingSpinner.css";

const LoadingSpinner = (props) => {
  return (
    <div
      className={`${props.asOverlay && "loading-spinner__overlay"}`}
      style={{ alignItems: "center", display: "flex" }}
    >
      {props.small && <div className="lds-dual-ring-small"></div>}
      {!props.small && <div className="lds-dual-ring"></div>}
    </div>
  );
};

export default LoadingSpinner;
