import React, { useState } from "react";

import "./Marker.css";
import { yellow, blue, red } from "@mui/material/colors";
import Chip from "@mui/material/Chip";
import ErrorIcon from "@mui/icons-material/Error";

const Marker = (props) => {
  const MarkerIcon = props.Icon;

  const [showMarkerInfo, setShowMarkerInfo] = useState(props.initialShow && props.info);

  let markerColor = { color: yellow[900] };

  if (props?.alwaysBlue) {
    markerColor = { color: blue[500] };
  }

  if (!props.alwaysShowMarker) {
    if (showMarkerInfo) {
      markerColor = { color: yellow[700] };
    } else {
      markerColor = { color: blue[500] };
    }
  }

  return (
    <React.Fragment>
      {props.error && (
        <Chip
          style={{
            textAlign: "center",
            transform: "translate(-50%, -50%)",
            scale: "1.5",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            top: "-25px",
            left: "8px",
          }}
          className="blinking"
          sx={{ color: red[500], fontSize: "48px" }}
          size="large"
          color={"warning"}
          icon={<ErrorIcon />}
        />
      )}

      <MarkerIcon
        style={{
          textAlign: "center",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          height: "150px",
        }}
        sx={markerColor}
        fontSize="large"
        // color= {markerColor}
        onClick={() => setShowMarkerInfo(!showMarkerInfo)}
      />
      {(showMarkerInfo || props.alwaysShowMarker) && (
        <div className="markerinfo">
          <p style={{ padding: "10px", margin: "0" }}>{props.info}</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default Marker;
