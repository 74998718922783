import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";

import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import Marker from "shared/components/UIElements/Marker";

import { VALIDATOR_REQUIRE } from "shared/util/validators";

import { useBackend } from "shared/hooks/backend-hook";

import "./Map.css";

const MapSinglePoint = ({
  id,
  title,
  label,
  initialAddress,
  initialLocation,
  initialAddressInSync,
  onMapDataChange,
}) => {
  const { t } = useTranslation();
  const { getLocationFromAddress, getAddressFromLocation } = useBackend();
  const [openError, setOpenError] = useState(false);

  const addressRef = useRef(initialAddress || "");

  const [address, setAddress] = useState(initialAddress || "");
  const [location, setLocation] = useState(initialLocation || null);
  const [mapCenter, setMapCenter] = useState(initialLocation || null);
  const [addressInSync, setAddressInSync] = useState(initialAddressInSync || false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const mapClickHandler = async (event) => {
    const currentLocation = { lat: event.lat, lng: event.lng };
    let addressInfo = null;

    try {
      addressInfo = await getAddressFromLocation(currentLocation);
    } catch (error) {
      setOpenError(true);
    }
    if (addressInfo) {
      addressRef.current = addressInfo.address;
      setLocation(currentLocation);
      setAddress(addressInfo.address);
      onMapDataChange(addressInfo.address, currentLocation, true);
      setAddressInSync(true);
    } else {
      setOpenError(true);
      setAddressInSync(false);
      onMapDataChange(null, null, false);
    }
  };

  const SetLocationFromAddress = async () => {
    setAddress(addressRef.current);
    const currentLocation = await getLocationFromAddress(addressRef.current);
    if (currentLocation) {
      setLocation(currentLocation?.coordinates);
      setAddress(currentLocation?.formattedAddress);
      setMapCenter(currentLocation?.coordinates);
      setAddressInSync(true);
      onMapDataChange(currentLocation?.formattedAddress, currentLocation?.coordinates, true);
    } else {
      setOpenError(true);
      setAddressInSync(false);
      onMapDataChange(null, null, false);
    }
  };

  const handleKeyUpOnAddress = async (event) => {
    const addressText = addressRef.current;
    if (event.key === "Enter") {
      SetLocationFromAddress();
    } else if (!addressText || addressText !== address) {
      setAddressInSync(false);
      onMapDataChange(null, null, false);
    } else {
      setAddressInSync(true);
      onMapDataChange(addressText, location, true);
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {t("noMatchingLocation")}
        </MuiAlert>
      </Snackbar>

      <Input
        key={`${id}_${address}`}
        id={`${id}_${address}`}
        element="input"
        type="text"
        label={title || t("address")}
        validators={[VALIDATOR_REQUIRE()]}
        errorText={t("requireField", { item: t("address") })}
        initialValue={address}
        initialValid={true}
        onInput={(_id, value) => (addressRef.current = value)}
        onKeyUp={handleKeyUpOnAddress}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Chip
          label={addressInSync ? t("addressAndLocationInSync") : t("addressAndLocationNotInSync")}
          color={addressInSync ? "success" : "warning"}
          icon={addressInSync ? <DoneIcon /> : <CancelIcon />}
        />

        <Button type="button" danger onClick={SetLocationFromAddress}>
          {t("getLocationFromAddress")}
        </Button>
      </div>

      {location ? (
        <div className="single-point-map-container">
          <GoogleMapReact
            options={function (maps) {
              return {
                mapTypeControl: true,
                mapTypeId: "roadmap",
                mapTypeControlOptions: {
                  mapTypeIds: ["satellite", "roadmap"],
                  style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  position: maps.ControlPosition.TOP_LEFT,
                },
                fullscreenControl: false,
              };
            }}
            center={mapCenter}
            defaultZoom={16}
            onClick={mapClickHandler.bind(this)}
          >
            {location && (
              <Marker
                Icon={RoomIcon}
                lat={location.lat}
                lng={location.lng}
                info={label || null}
                error={!addressInSync}
              />
            )}
          </GoogleMapReact>
        </div>
      ) : (
        <div style={{ marginBottom: "20px" }}></div>
      )}
    </React.Fragment>
  );
};

export default MapSinglePoint;
