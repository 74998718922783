import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { SCHOOL_TYPES } from "shared/util/constants";
import StopItem from "components/stop/StopItem";
import "./Admin.css";

const Stops = () => {
  const { t } = useTranslation();
  const itemType = "stop";
  const listName = "main_stops";

  const { stops, schoolOptions, DeleteStop, InsertStop, UpdateStop } = useContext(StoreContext);
  const pageItems = stops;

  let listColumns = [
    { field: "name", headerName: t("name"), width: 300 },
    { field: "address", headerName: t("address"), width: 600 },
    {
      field: "school",
      headerName: t("client"),
      width: 300,
      valueGetter: (params) =>
        schoolOptions.find((school) => school?.value?.toString() === params.row?.school?.toString())?.label?.toString(),
    },
  ];

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={StopItem}
      NewItemComponent={StopItem}
      DeleteItem={DeleteStop}
      InsertItem={InsertStop}
      UpdateItem={UpdateStop}
    />
  );
};

export default Stops;
