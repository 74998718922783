import React, { useEffect, useState, useContext, useCallback, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";
import NotFound from "shared/components/UIElements/NotFound";

import MapWithTransferTimesContainer from "components/shared/MapWithTransferTimesContainer";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import VehicleRouteSms from "components/shared/VehicleRouteSms";
// #endregion Components

import { isArray } from "lodash";

// #region Utils
import { vehicleroutetypes } from "shared/util/types";
// import { GetPhoneNumberofStudent, GetParentFromStudent } from "shared/util/relationaldata";
import { VALIDATOR_REQUIRE, VALIDATOR_TIME_EMPTY_ACCEPTED } from "shared/util/validators";

import { GetStartStopTime } from "shared/util/time";
import { CheckTimeString } from "shared/util/time";
import { isPickUp } from "shared/util/util";
import { useBackend } from "shared/hooks/backend-hook";
// #endregion Utils

import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

const VehicleRouteItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  //standard code
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // context
  const auth = useContext(AuthContext);
  const {
    vehicleRoutes,
    users,
    students,
    schoolOptions,
    driverOptions,
    studentOptions,
    schools,
    drivers,
    GetParentFromStudent,
  } = useContext(StoreContext);
  const { sendSMS, getSMSByVehicleRoute } = useBackend();

  // item info
  const itemType = "vehicleRoute";
  const endPointUpdate = "vehicleroutes";
  const endPointDelete = "vehicleroutes";
  const endPointCreate = "vehicleroutes";

  // item special states
  const [currentStudents, setCurrentStudents] = useState([]);
  const [view, setView] = useState(1);
  const [currentSmses, setCurrentSmses] = useState([]);
  const [currentDrivers, setCurrentDrivers] = useState([]);

  // item special functions
  // const loadSMS = useCallback(async () => {
  //   setCurrentSmses(await getSMSByVehicleRoute(itemId));
  // }, [getSMSByVehicleRoute, itemId]);

  const updateCurrentStudents = (data) => {
    if (!data) {
      setCurrentStudents([]);
      return;
    }

    const nowStudents = students.filter((item) =>
      isArray(data) ? data.includes(item.id) : formState.inputs.students.value.includes(item.id)
    );

    const newStudents = [];

    const currentStudentIds = currentStudents.map((item) => item.id);

    for (let i = 0; i < nowStudents.length; i++) {
      const student = nowStudents[i];

      if (currentStudentIds.includes(student.id)) {
        student.transferTime = currentStudents.find((item) => item.id === student.id).transferTime;
      } else {
        if (loadedItem.routelocations && loadedItem.routelocations.length > 0) {
          const currentRoutelocations = loadedItem.routelocations.find((item) => item?.student === student.id);
          if (currentRoutelocations) {
            student.transferTime = currentRoutelocations.transfertime;
          }
        }
      }
      newStudents.push(student);
    }

    setCurrentStudents(newStudents);
  };

  const GotoView = (view) => {
    setView(view);
  };

  const updateDrivers = (updatedDrivers) => {
    setCurrentDrivers(
      drivers
        .filter((item) => updatedDrivers?.includes(item.id.toString()))
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            phonenumber: item.phonenumber,
          };
        })
    );
  };

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: true },
      type: { value: "", isValid: true },
      name: { value: null, isValid: true },
      driver: { value: [], isValid: true },
      plate: { value: "", isValid: true },
      starttime: { value: null, isValid: true },
      stoptime: { value: null, isValid: true },
      student: { value: [], isValid: true },
      transferStartFinishTime: { value: null, isValid: true },
    },
    true
  );

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentVehicleRoute = vehicleRoutes.find((vehicleRoute) => vehicleRoute.id === itemId);
      setLoadedItem(currentVehicleRoute);

      if (!currentVehicleRoute) {
        setIsItemLoaded(true);
        return;
      }
      setCurrentDrivers(
        drivers
          .filter((item) =>
            currentVehicleRoute?.driver.map((driverItem) => driverItem.id.toString()).includes(item.id.toString())
          )
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              phonenumber: item.phonenumber,
            };
          })
      );

      setCurrentStudents(
        currentVehicleRoute.student.map((student) => ({
          ...student,
          transferTime:
            currentVehicleRoute.routelocations.find((order) => order?.student?.toString() === student?.id?.toString())
              ?.transfertime || null,
        }))
      );

      // loadSMS();
    } catch (err) {}
    setIsItemLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, vehicleRoutes, users, students]);

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  // eslint-disable-next-line
  const itemUpdateSubmitHandler = useCallback(async (event) => {
    event.preventDefault();

    // if (!CheckTimeString(formState.inputs.starttime.value) || !CheckTimeString(formState.inputs.stoptime.value)) {
    //   alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
    //   setShowAlert(true);
    //   return;
    // }

    const endpoint = isNewItem
      ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
      : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

    const postType = isNewItem ? "POST" : "PATCH";

    let routelocations = [
      {
        student: "2",
        transfertime: "1",
      },
    ];

    for (let i = 0; i < currentStudents.length; i++) {
      if (currentStudents[i].transferTime) {
        routelocations.push({
          student: currentStudents[i].id,
          transfertime: currentStudents[i].transferTime,
        });
      }
    }

    const infoToBeSent = {
      name: formState.inputs.name.value,
      type: formState.inputs.type.value,
      school: formState.inputs.school.value,
      driver: formState.inputs.driver.value,
      plate: formState.inputs.plate.value,
      starttime: formState.inputs.starttime.value || "00:00",
      stoptime: formState.inputs.stoptime.value || "23:59",
      student: formState.inputs.student.value,
      routeClientArrivalTime: CheckTimeString(formState.inputs.transferStartFinishTime.value)
        ? formState.inputs.transferStartFinishTime.value
        : undefined,
      routelocations: routelocations,
    };

    //standard code
    try {
      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.message) {
        window.toast.error(err.message);
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  });

  const memoizedStops = useMemo(() => {
    return currentStudents.map((student) => ({ ...student, location: student.locationgeton }));
  }, [currentStudents]);

  const memoizedOrigin = useMemo(() => {
    return schools.find((school) => formState.inputs.school.value === school.id);
  }, [schools, formState.inputs.school.value]);

  const memoizedTransferStartFinishTime = useMemo(() => {
    const check = CheckTimeString(formState.inputs.transferStartFinishTime.value);
    if (!check) {
      return null;
    }
    return formState.inputs.transferStartFinishTime.value;
  }, [formState.inputs.transferStartFinishTime.value]);

  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container">
          {!isNewItem && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {view === 1 && loadedItem && (
                <Button type="button" size="small" danger onClick={() => GotoView(2)}>
                  {t("smsManagement")} &#62;
                </Button>
              )}

              {view === 2 && (
                <Button type="button" size="small" danger onClick={() => GotoView(1)}>
                  {t("serviceManagement")} &#62;
                </Button>
              )}
            </div>
          )}

          {view === 1 && (
            <div>
              <div className="horizontal-flex">
                <div className="vertical-flex">
                  <CustomSelect
                    options={schoolOptions}
                    id="school"
                    onInput={inputHandler}
                    label={t("client")}
                    initialValue={schoolOptions.find((option) => option.value === loadedItem?.school?.id)}
                    initialValid={isNewItem ? false : true}
                  ></CustomSelect>

                  <CustomSelect
                    options={vehicleroutetypes}
                    id="type"
                    onInput={inputHandler}
                    label={t("routeType")}
                    initialValue={
                      vehicleroutetypes.find((option) => option.value.toString() === loadedItem?.type?.toString()) ||
                      vehicleroutetypes[0]
                    }
                    initialValid={true}
                  ></CustomSelect>

                  <Input
                    id="name"
                    element="input"
                    type="text"
                    label={t("name")}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText={t("requireField", { item: t("name") })}
                    initialValue={loadedItem?.name}
                    initialValid={isNewItem ? false : true}
                    onInput={inputHandler}
                  />

                  <div className="horizontal-flex">
                    <Input
                      id="plate"
                      element="input"
                      type="text"
                      label={t("plateForRoute")}
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText={t("requireField", { item: t("plate") })}
                      onInput={inputHandler}
                      initialValue={loadedItem?.plate}
                      initialValid={isNewItem ? false : true}
                    />

                    <Input
                      id="transferStartFinishTime"
                      element="input"
                      type="text"
                      label={
                        isPickUp(formState.inputs.type.value)
                          ? t("pickUpRouteClientArrivalTime")
                          : t("dropOffRouteClientArrivalTime")
                      }
                      validators={[VALIDATOR_TIME_EMPTY_ACCEPTED()]}
                      errorText={t("invalidTimeField", {
                        item: isPickUp(formState.inputs.type.value)
                          ? t("pickUpRouteClientArrivalTime")
                          : t("dropOffRouteClientArrivalTime"),
                      })}
                      onInput={inputHandler}
                      initialValue={
                        CheckTimeString(loadedItem?.routeClientArrivalTime) ? loadedItem?.routeClientArrivalTime : ""
                      }
                      initialValid={true}
                    />
                  </div>

                  <div className="horizontal-flex">
                    <Input
                      id="starttime"
                      element="input"
                      type="text"
                      label={t("serviceObserverStartTime")}
                      validators={[VALIDATOR_TIME_EMPTY_ACCEPTED()]}
                      errorText={t("invalidTimeField", { item: t("serviceObserverStartTime") })}
                      onInput={inputHandler}
                      initialValue={loadedItem?.starttime ? GetStartStopTime(loadedItem?.starttime) : ""}
                      initialValid={true}
                    />
                    <Input
                      id="stoptime"
                      element="input"
                      type="text"
                      label={t("serviceObserverEndTime")}
                      validators={[VALIDATOR_TIME_EMPTY_ACCEPTED()]}
                      errorText={t("invalidTimeField", { item: t("serviceObserverEndTime") })}
                      onInput={inputHandler}
                      initialValue={loadedItem?.stoptime ? GetStartStopTime(loadedItem?.stoptime) : ""}
                      initialValid={true}
                    />
                  </div>
                </div>

                <div className="vertical-flex" style={{ flex: 2 }}>
                  <CustomSelect
                    options={driverOptions}
                    id="driver"
                    isMulti={true}
                    onInput={inputHandler}
                    label={t("driversForRoute")}
                    initialValue={driverOptions.filter((option) =>
                      currentDrivers.map((driver) => driver.id).includes(option.value)
                    )}
                    initialValid={true}
                    fireChange={(val) => updateDrivers(val)}
                  ></CustomSelect>
                  <CustomSelect
                    options={studentOptions}
                    id="student"
                    isMulti={true}
                    onInput={inputHandler}
                    label={t("passengersIntheRoute")}
                    initialValue={
                      currentStudents?.length
                        ? studentOptions.filter((filteredStudent) =>
                            currentStudents?.map((student) => student.id).includes(filteredStudent.value)
                          )
                        : []
                    }
                    initialValid={true}
                    fireChange={(val) => updateCurrentStudents(val)}
                  ></CustomSelect>
                </div>
              </div>

              {currentStudents.length > 0 && (
                <React.Fragment>
                  <div style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
                    <MapWithTransferTimesContainer
                      stops={memoizedStops}
                      origin={memoizedOrigin}
                      type={formState.inputs.type.value}
                      enablePolyline={true}
                      transferStartFinishTime={memoizedTransferStartFinishTime}
                      isFullScreen={true}
                      onOptimize={(newStops) => {
                        setCurrentStudents(newStops);
                      }}
                    />
                  </div>
                </React.Fragment>
              )}

              <FormFooter
                isNewItem={isNewItem}
                onUpdate={itemUpdateSubmitHandler}
                onDelete={() => setShowConfirmModal(true)}
                isUpdating={isLoading}
                disabled={!formState.inputs.school.value || !formState.isValid || isLoading}
              />
            </div>
          )}

          {view === 2 && (
            <VehicleRouteSms students={currentStudents} drivers={currentDrivers} vehicleRouteId={itemId} />
          )}
        </form>
      )}
    </React.Fragment>
  );
};

export default VehicleRouteItem;
