import React, { useContext } from "react";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";

// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import { paymentMethods, banks, paymentTypes } from "shared/util/types";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const ReceiptNew = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const updateStoreItems = store.RetrieveReceipts;
  const ItemTypeTR = "Tahsilat";
  const APIEndPoint = "receipts";

  const currentYearContracts = store.contracts.filter((item) => item.year.toString() === props.selectedYear.toString());
  const currentYearContractIds = currentYearContracts.map((item) => item.id);

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          contract: formState.inputs.contract.value,
          date: formState.inputs.date.value,
          paymentMethod: formState.inputs.paymentMethod.value,
          paymentType: formState.inputs.paymentType.value,
          amount: formState.inputs.amount.value,
          bank: formState.inputs.bank.value,
          receiptNo: formState.inputs.receiptNo.value,
          official: true,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
      // history.push(`/${ItemEndPointType}`);
    } catch (err) {
      console.log(err);
    }
  };

  const [formState, inputHandler] = useForm(
    {
      contract: { value: null, isValid: true },
      date: { value: "", isValid: true },
      paymentMethod: { value: "", isValid: true },
      paymentType: { value: "", isValid: true },
      amount: { value: null, isValid: false },
      bank: { value: null, isValid: false },
      receiptNo: { value: null, isValid: true },
      official: { value: true, isValid: true },
    },
    false
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>

        <CustomSelect
          options={store.contractsOptions.filter((item) => currentYearContractIds.includes(item.value))}
          id="contract"
          onInput={inputHandler}
          label="Öğrenci Sözleşmesi"
          initialValid={true}
          validators={[VALIDATOR_NONE()]}
        ></CustomSelect>

        <DatePicker
          id="date"
          onInput={inputHandler}
          label="Fatura Tarihi"
          initialValid={false}
          validators={[VALIDATOR_REQUIRE()]}
        ></DatePicker>

        <CustomSelect
          options={paymentMethods}
          id="paymentMethod"
          onInput={inputHandler}
          label="Ödeme Yöntemi"
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={false}
        ></CustomSelect>

        <CustomSelect
          options={paymentTypes}
          id="paymentType"
          onInput={inputHandler}
          label="Ödeme Şekli"
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={false}
        ></CustomSelect>

        <CustomSelect
          options={banks}
          id="bank"
          onInput={inputHandler}
          label="Ödemenin Yapıldığı Banka"
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={true}
        ></CustomSelect>

        <Input
          id="receiptNo"
          element="input"
          type="text"
          label="Fatura Numarası (Banka Ödemesi)"
          validators={[VALIDATOR_NONE()]}
          errorText="Fatura no giriniz."
          initialValid={true}
          onInput={inputHandler}
        />

        <Input
          id="amount"
          element="input"
          type="text"
          label="Ödeme Tutarı"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Ödeme tutarını giriniz."
          initialValid={false}
          onInput={inputHandler}
        />

        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default ReceiptNew;
