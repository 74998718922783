import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import NotFound from "shared/components/UIElements/NotFound";
import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";
import { getShiftDate } from "shared/util/util";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_TIME, VALIDATOR_TIME_EMPTY_ACCEPTED } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import { days, days_en } from "shared/util/types";
import { sortArrayByLabel } from "shared/util/string";
import { isPickUp } from "shared/util/util";

const ShiftItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  //standard code
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { schoolOptions, shiftPersonelServiceOptions, userOptions, shifts } = useContext(StoreContext);

  const itemType = "shift";
  const endPointUpdate = "shifts";
  const endPointDelete = "shifts";
  const endPointCreate = "shifts";

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: true },
      description: { value: "", isValid: true },
      day: { value: "", isValid: true },
      serviceArrivalTime: { value: "", isValid: true },
      serviceLeaveTime: { value: "", isValid: true },
      users: { value: [], isValid: true },
      personelservices: { value: [], isValid: true },
      school: { value: "", isValid: true },
    },
    true
  );

  const language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentRelation = shifts.find((shift) => shift?.id === itemId);
      setLoadedItem(currentRelation);
    } catch (err) {}
    setIsItemLoaded(true);
    // eslint-disable-next-line
  }, [itemId]);

  useEffect(() => {
    fetchItem();
    // const interval = setInterval(() => {
    //   fetchItem();
    // }, 60000);

    // return () => clearInterval(interval);
  }, [itemId, fetchItem]);

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    const endpoint = isNewItem
      ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
      : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

    const postType = isNewItem ? "POST" : "PATCH";

    const infoToBeSent = {
      name: formState.inputs.name.value,
      description: formState.inputs.description.value,
      day: formState.inputs.day.value,

      serviceArrivalTime: formState.inputs.serviceArrivalTime.value,
      serviceLeaveTime: formState.inputs.serviceLeaveTime.value,
      users: formState.inputs.users.value,
      personelservices: formState.inputs.personelservices.value,
      school: formState.inputs.school.value,
    };

    try {
      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.message) {
        window.toast.error(err.message);
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };
  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }
  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <CustomSelect
            options={schoolOptions}
            id="school"
            onInput={inputHandler}
            label={t("client")}
            initialValue={schoolOptions.find((option) => option.value === loadedItem?.school?.toString())}
            initialValid={isNewItem ? false : true}
          ></CustomSelect>
          <CustomSelect
            // options={language === "en" ? days_en : days}
            options={days}
            id="day"
            onInput={inputHandler}
            label={t("day")}
            initialValue={days.find((day) => day?.value.toString() === loadedItem?.day?.toString()) || null}
            initialValid={true}
          ></CustomSelect>
          <Input
            id="name"
            element="input"
            type="text"
            label={t("name")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("name") })}
            initialValue={loadedItem?.name || ""}
            initialValid={isNewItem ? false : true}
            onInput={inputHandler}
          />

          {!isNewItem && (
            <CustomSelect
              options={sortArrayByLabel(userOptions)}
              id="users"
              isMulti={true}
              onInput={inputHandler}
              label={` ${getShiftDate(loadedItem)} Vardiyasındaki Personeller`}
              initialValue={userOptions.filter((option) => loadedItem?.users?.includes(option.value))}
              initialValid={true}
            ></CustomSelect>
          )}

          <Input
            id="description"
            element="input"
            type="text"
            label={t("description")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("description") })}
            initialValue={loadedItem?.description || ""}
            initialValid={isNewItem ? false : true}
            onInput={inputHandler}
          />

          <div className="horizontal-flex">
            <Input
              id="serviceArrivalTime"
              element="input"
              type="text"
              label="Vardiya Başlangıcı İçin Servislerin Geliş Saati"
              validators={[VALIDATOR_TIME(formState.inputs.serviceArrivalTime.value)]}
              initialValue={loadedItem?.serviceArrivalTime || ""}
              initialValid={isNewItem ? false : true}
              errorText={t("invalidTimeField", { item: t("serviceArrivalTime") })}
              onInput={inputHandler}
            />
            <Input
              id="serviceLeaveTime"
              element="input"
              type="text"
              label="Vardiya Bitişi İçin Servislerin Çıkış Saati"
              validators={[VALIDATOR_TIME(formState.inputs.serviceLeaveTime.value)]}
              initialValue={loadedItem?.serviceLeaveTime || ""}
              initialValid={isNewItem ? false : true}
              errorText={t("invalidTimeField", { item: t("serviceLeaveTime") })}
              onInput={inputHandler}
            />
          </div>

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={
              !formState.inputs?.school?.value || !formState.inputs?.day?.value || !formState.isValid || isLoading
            }
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default ShiftItem;
