import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
//import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// #region Components
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import DatePicker from "shared/components/FormElements/DatePicker";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const ReportTransfers = (props) => {
  const store = useContext(StoreContext);
  const [reportCreated, setReportCreated] = useState(false);
  const [finalAmount, setFinalAmount] = useState(0);
  const [finalVehicleNumbers, setFinalVehicleNumbers] = useState(0);
  const [finalTransferNumbers, setFinalTransferNumbers] = useState(0);
  const [finalPeopleNumbers, setFinalPeopleNumbers] = useState(0);
  const [finalTransfers, setFinalTransfers] = useState(0);

  const { isLoading } = useHttpClient();

  useEffect(() => {});

  const [formState, inputHandler] = useForm(
    {
      datestarter: { value: "", isValid: false },
      datefinisher: { value: "", isValid: false },
      school: { value: "", isValid: false },
    },
    false
  );

  const createReport = async () => {
    let allTransfers = [];

    const startDate = new Date(formState.inputs.datestarter.value);
    const endDate = new Date(formState.inputs.datefinisher.value);

    allTransfers = store.transfers.filter(
      (transfer) => new Date(transfer.transferdate) >= startDate && new Date(transfer.transferdate) <= endDate
    );

    let currentTransfers = [];
    let totalAmount = 0;
    let totalNumberOfVehiclesUsed = 0;
    let totalNumberOfPeople = 0;

    allTransfers.forEach((transfer) => {
      transfer.vehicleRoutes.forEach((vehicleRoute) => {
        const currentVehicleRoute = store.vehicleRoutes.find((item) => item.id.toString() === vehicleRoute.toString());

        if (
          currentVehicleRoute.routelocations.length > 0 &&
          currentVehicleRoute.routelocations[0].hasOwnProperty("transferregion")
        ) {
          let currentRegion = null;
          try {
            currentRegion = store.transferregions.find(
              (transferregion) =>
                transferregion.id.toString() === currentVehicleRoute.routelocations[0].transferregion.toString()
            );
          } catch {}
          if (currentRegion) {
            totalAmount += currentRegion.amount;
            totalNumberOfVehiclesUsed += 1;

            let people = [];
            for (let i = 0; i < currentVehicleRoute.routelocations.length; i++) {
              const currentPersonel = store.students.find(
                (student) => currentVehicleRoute.routelocations[i].student.toString() === student.id.toString()
              );
              if (currentPersonel) {
                people.push(currentPersonel.name);
              }
            }

            totalNumberOfPeople += currentVehicleRoute.routelocations.length - 1;
            currentTransfers.push({
              transfer: transfer,
              route: currentVehicleRoute,
              amount: currentRegion.amount,
              people: people,
            });
          }
        }
      });
    });

    setFinalAmount(totalAmount);
    setFinalVehicleNumbers(totalNumberOfVehiclesUsed);
    setFinalTransferNumbers(allTransfers.length);
    setFinalPeopleNumbers(totalNumberOfPeople);
    setFinalTransfers(currentTransfers);

    setReportCreated(true);
  };

  // #region Loading
  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  // const Item = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   minHeight: 80,
  //   alignItems: "center",
  //   justifyContent: "center",
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <React.Fragment>
      <form>
        <Grid
          sx={{
            fontsize: 16,
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "row", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            textAlign: "left",
            width: "100%",
            id: "report-receipts-container",
          }}
          container
          spacing={1}
        >
          <Grid item xs={4}>
            <CustomSelect
              options={store.schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Okul"
              errorText="Okul seçimini yapınız."
              initialValid={false}
              validators={[VALIDATOR_REQUIRE()]}
            ></CustomSelect>
          </Grid>

          <Grid item xs={4}>
            <DatePicker
              id="datestarter"
              onInput={inputHandler}
              label="Başlangıç Tarihi"
              errorText="Başlangıç tarihi seçiniz."
              initialValid={false}
              validators={[VALIDATOR_REQUIRE()]}
            ></DatePicker>
          </Grid>

          <Grid item xs={4}>
            <DatePicker
              id="datefinisher"
              initialValid={false}
              onInput={inputHandler}
              label="Bitiş Tarihi"
              errorText="Bitiş tarihi seçiniz."
              validators={[VALIDATOR_REQUIRE()]}
            ></DatePicker>
          </Grid>

          <Grid xs={3}>
            <Button type="button" disabled={!formState.isValid} onClick={createReport}>
              Rapor Oluştur
            </Button>
          </Grid>
        </Grid>
      </form>
      {reportCreated && (
        <div>
          <h4>GENEL ÖDEME BİLGİLERİ</h4>
          <TableContainer component={Paper} sx={{ minWidth: 650, width: "inherit" }}>
            <Table
              sx={{ minWidth: 650, width: "100%", justifyContent: "center", margin: "auto" }}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Toplam Transfer Sayısı</b>
                  </TableCell>
                  <TableCell>
                    <b>Transferlerde Kullanılan Toplam Araç Sayısı</b>
                  </TableCell>
                  <TableCell>
                    <b>Transfer Edilen Toplam Kişi Sayısı</b>
                  </TableCell>
                  <TableCell>
                    <b>Toplam Transfer Ücreti</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {finalTransferNumbers}
                  </TableCell>

                  <TableCell> {finalVehicleNumbers}</TableCell>

                  <TableCell>{finalPeopleNumbers}</TableCell>
                  <TableCell>{finalAmount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <br />

          <h4>Transfer Ayrıntıları</h4>
          <TableContainer component={Paper} sx={{ minWidth: 650, width: "inherit" }}>
            <Table
              sx={{ minWidth: 650, width: "100%", justifyContent: "center", margin: "auto" }}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Transfer İsmi</b>
                  </TableCell>
                  <TableCell>
                    <b>Rota İsmi</b>
                  </TableCell>
                  <TableCell>
                    <b>Rota Ücreti</b>
                  </TableCell>
                  <TableCell>
                    <b>Transferdeki Kişiler</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {finalTransfers.map((row) => (
                  <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.transfer.name}
                    </TableCell>

                    <TableCell>{row.route.name}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.people.toString()}</TableCell>
                    {/* <TableCell>{row.finalAmount.toLocaleString()}</TableCell>
            <TableCell>{(row.finalAmount - row.amount).toLocaleString()}</TableCell> */}
                    {/* <TableCell align="right">{row.fat}</TableCell>
      <TableCell align="right">{row.carbs}</TableCell>
      <TableCell align="right">{row.protein}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </React.Fragment>
  );
};

export default ReportTransfers;
