import React, { useState, useContext, useEffect } from "react";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { parseComplexDate } from "shared/util/util";
import Grid from "@mui/material/Grid";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import { useForm } from "shared/hooks/form-hook";
import DatePicker from "shared/components/FormElements/DatePicker";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import * as XLSX from "xlsx";

// import "./Admin.css";

const Plans = () => {
  const { shiftPersonelServices, shifts, driverOptions, schoolOptions, UpdatePersonelService } =
    useContext(StoreContext);

  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [today, setToday] = useState(null);
  const [calculatedPersonelServices, setCalculatedPersonelServices] = useState([]);
  const [calculatedPersonelServicesPickup, setCalculatedPersonelServicesPickup] = useState([]);
  const [calculatedPersonelServicesDropoff, setCalculatedPersonelServicesDropoff] = useState([]);
  const [initDateSelected, setInitDateSelected] = useState();
  const [finishDateSelected, setFinishDateSelected] = useState();

  //const [storeTransfers, setStoreTransfers] = useState([]);
  //const [storeVehicleRoutes, setStoreVehicleRoutes] = useState([]);

  const [savingPersonelServiceId, setSavingPersonelServiceId] = useState(null);

  const [defaultFormat, setDefaultFormat] = useState(true);

  const [formState, inputHandler] = useForm(
    {
      datestarter: { value: "", isValid: false },
      datefinisher: { value: "", isValid: false },
      school: { value: "", isValid: true },
    },
    false
  );

  const [formStateDriver, inputHandlerDriver] = useForm(
    {
      driver: { value: "", isValid: true },
    },
    true
  );

  const ItemEndPointType = "personelservices";

  useEffect(() => {
    const todayString = new Date();
    const date = parseComplexDate(todayString);
    setToday(date);
    setInitDateSelected(date);
    setFinishDateSelected(date);

    //setStoreTransfers(store.transfers);
    //setStoreVehicleRoutes(store.vehicleRoutes);
  }, [setToday, setInitDateSelected, setFinishDateSelected]);

  const createReport = async () => {
    let allPersonelServices = [];
    debugger;

    const startDate = new Date(formState.inputs.datestarter.value);
    const endDate = new Date(formState.inputs.datefinisher.value);

    setInitDateSelected(parseComplexDate(startDate));
    setFinishDateSelected(parseComplexDate(endDate));

    allPersonelServices = shiftPersonelServices.filter(
      (personelService) => new Date(personelService.date) >= startDate && new Date(personelService.date) <= endDate
    );

    let calculatedShifts = [];
    let calculatedPersonelServices = [];

    for (let m = 0; m < shifts.length; m++) {
      const currentShift = shifts[m];

      const currentShiftPersonelServices = allPersonelServices.filter(
        (personelService) => personelService.shift === currentShift._id
      );

      for (let i = 0; i < currentShiftPersonelServices.length; i++) {
        const currentShiftPersonelService = currentShiftPersonelServices[i];

        if (currentShiftPersonelService && currentShiftPersonelService.users) {
          let currentPeople = [];
          for (let k = 0; k < currentShiftPersonelService.users.length; k++) {
            try {
              const currentPersonId = currentShiftPersonelService.users[k].id;
              const currentPersonTransferTime = currentShiftPersonelService.usersdetails.find(
                (userdetail) => userdetail.user === currentPersonId
              )?.transfertime;
              const currentPersonName = currentShiftPersonelService.users[k].name;
              currentPeople.push(
                currentPersonTransferTime ? currentPersonTransferTime + " - " + currentPersonName : currentPersonName
              );
            } catch {}
          }

          calculatedPersonelServices.push({
            shift: currentShift,
            service: currentShiftPersonelService,
            people: currentPeople,
          });
        }
      }
      //   calculatedShifts.push({
      //     shiftInfo: currentShift,
      //     personelServices: calculatedPersonelServices,
      //   });
    }

    // let sortedShifts = calculatedShifts.sort((t1, t2) =>
    //   t1.transferInfo.transferdate + t1.transferInfo.transfertime.split(" ")[4].substring(0, 5) >
    //   t2.transferInfo.transferdate + t2.transferInfo.transfertime.split(" ")[4].substring(0, 5)
    //     ? 1
    //     : -1
    // );

    debugger;
    setCalculatedPersonelServicesPickup(
      calculatedPersonelServices.filter((personelService) => personelService.service.type === "100")
    );
    setCalculatedPersonelServicesDropoff(
      calculatedPersonelServices.filter((personelService) => personelService.service.type === "200")
    );
    //setCalculatedPersonelServices(calculatedPersonelServices);
  };

  const peopleComponent = (people) => {
    if (!people || people.length === 0) {
      return <p>Yolcu bulunamadı.</p>;
    }

    return (
      <div>
        {people.map((person) => (
          <p key={person + Math.random()}> {person}</p>
        ))}
      </div>
    );
  };

  const saveDriverHandler = async (event, elementId, personelServiceId) => {
    event.preventDefault();
    setSavingPersonelServiceId(personelServiceId);
    const selectedDriverId = formStateDriver.inputs[elementId].value;
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${personelServiceId}`,
        "PATCH",
        JSON.stringify({
          drivers: [selectedDriverId],
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      if (responseData.personelservice) {
        UpdatePersonelService(responseData.personelservice);
      }
      //await createReport();
      //setItemState(Date.now());
      //   let currentPersonelServices = [...calculatedPersonelServices];
      //   let currentPersonelService = currentPersonelServices.find((personelService) => personelService._id === personelServiceId);
      //   let currentUpdatedVehicleRoute = storeVehicleRoutes.find((route) => route._id === vehicleRouterId);
      //   currentUpdatedVehicleRoute.driver[0] = store.driverOptions.find((driver) => driver.value === selectedDriverId);
      //   currentUpdatedVehicleRoute.driver[0]._id = selectedDriverId;
      //   currentUpdatedVehicleRoute.driver[0].id = selectedDriverId;
      //   currentUpdatedVehicleRoute.driver[0].name = currentUpdatedVehicleRoute.driver[0].label;
      //   currentTransfer.route = currentUpdatedVehicleRoute;
      //   for (let i = 0; i < currentCalculatedTransfers.length; i++) {
      //     if (currentCalculatedTransfers[i].route._id === vehicleRouterId) {
      //       currentCalculatedTransfers[i] = currentTransfer;
      //     }
      //   }
      //   setCalculatedTransfers(currentCalculatedTransfers);
    } catch (err) {}
    setSavingPersonelServiceId(null);
    // setFormDataDriver(
    //   {
    //     ...formStateDriver.inputs,
    //     [elementId]: {
    //       value: store.driverOptions.filter((option) => option.value === selectedDriverId),
    //       isValid: true,
    //     },
    //   },
    //   true
    // );
    // formStateDriver.inputs[elementId].initialValue = store.driverOptions.filter((option) => option.value === selectedDriverId);
    // setItemState(Date.now());
  };

  const driverComponent = (personelservice) => {
    let currentDriverName = "";

    try {
      currentDriverName = personelservice.drivers[0].name;
    } catch {
      return <p>Şoför bulunamadı.</p>;
    }
    if (!currentDriverName) {
      return <p>Şoför bulunamadı.</p>;
    }

    return defaultFormat ? (
      <div style={{ marginBottom: "10px" }}>
        <CustomSelect
          options={driverOptions}
          key={`vehiclerouterid_driver_${personelservice._id}_${personelservice.drivers[0]._id}`}
          id={`vehiclerouterid_driver_${personelservice._id}`}
          onInput={inputHandlerDriver}
          initialValue={driverOptions.filter((option) => personelservice.drivers[0]._id === option.value)}
          initialValid={true}
        ></CustomSelect>

        <Button
          type="button"
          onClick={(event) =>
            saveDriverHandler(event, `vehiclerouterid_driver_${personelservice._id}`, personelservice._id)
          }
          disabled={savingPersonelServiceId === personelservice._id}
        >
          Kaydet
        </Button>
      </div>
    ) : (
      <div>
        <p>{currentDriverName}</p>
      </div>
    );
  };

  const downloadExcel = () => {
    let data = calculatedPersonelServicesPickup.map((service) => ({
      "Vardiya İsmi": service.shift.name + " - " + service.shift.description,
      "Servis Tarihi": service.service.date,
      "Servis Tipi": "Toplama",
      "Servis İsmi": service.service.name,
      "Varış/Kalkış Saati": service.service.routeClientArrivalTime || "",
      Sürücü: service.service.drivers?.[0]?.name || "-",
      Yolcular: service?.people.join(", "),
      "Yolcu Sayısı": service?.people?.length || 0,
    }));

    data = data.concat(
      calculatedPersonelServicesDropoff.map((service) => ({
        "Vardiya İsmi": service.shift.name + " - " + service.shift.description,
        "Servis Tarihi": service.service.date,
        "Servis Tipi": "Dağıtma",
        "Servis İsmi": service.service.name,
        "Varış/Kalkış Saati": service.service.routeClientArrivalTime || "",
        Sürücü: service.service.drivers?.[0]?.name || "-",
        Yolcular: service?.people.join(", "),
        "Yolcu Sayısı": service?.people?.length || 0,
      }))
    );

    const worksheet = XLSX.utils.json_to_sheet(data);

    const MAX_COLUMN_WIDTH = 40; // Set the maximum width in characters
    const columns = Object.keys(data[0]);
    worksheet["!cols"] = columns.map((col, index) => {
      const maxLength = Math.max(
        ...data.map((row) => (row[col] ? row[col].toString().length : 0)), // Length of each cell in the column
        col.length // Length of the header
      );
      return { wch: Math.min(maxLength + 2, MAX_COLUMN_WIDTH) }; // Apply max width limit
    });

    // Style headers (first row)
    // const range = XLSX.utils.decode_range(worksheet["!ref"]);
    // for (let col = range.s.c; col <= range.e.c; col++) {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col }); // First row
    //   if (!worksheet[cellAddress]) continue;

    //   worksheet[cellAddress].s = {
    //     font: {
    //       bold: true, // Make font bold
    //     },
    //   };
    // }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, initDateSelected + " - " + finishDateSelected);

    XLSX.writeFile(workbook, "Servistesin_Vardiya_Raporu.xlsx");
  };

  // const regionComponent = (vehicleRoute) => {
  //   let currentRegionName = "";
  //   try {
  //     currentRegionName = store.transferregionOptions.find(
  //       (transferregion) => transferregion.value === vehicleRoute.routelocations[0].transferregion
  //     ).label;
  //   } catch {
  //     return <p>Bölge bulunamadı.</p>;
  //   }
  //   if (!currentRegionName) {
  //     return <p>Bölge bulunamadı.</p>;
  //   }

  //   return (
  //     <div>
  //       <p>{currentRegionName}</p>
  //     </div>
  //   );
  // };

  if ((isLoading || !today) && !savingPersonelServiceId) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <form>
        <Grid
          sx={{
            fontsize: 16,
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "row", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
            justifyContent: "flex-start",
            horizontalGap: "20px",
            textAlign: "left",
            width: "100%",
            id: "report-receipts-container",
          }}
          container
          spacing={1}
        >
          <Grid item xs={3}>
            <CustomSelect
              options={schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Kurum"
              initialValue={schoolOptions[0]}
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
            ></CustomSelect>
          </Grid>

          <Grid item xs={1}>
            <DatePicker
              id="datestarter"
              onInput={inputHandler}
              label="Başlangıç"
              errorText="Başlangıç tarihi seçiniz."
              initialValid={true}
              initialValue={initDateSelected}
              validators={[VALIDATOR_REQUIRE()]}
            ></DatePicker>
          </Grid>

          <Grid item xs={1}>
            <DatePicker
              id="datefinisher"
              initialValid={true}
              onInput={inputHandler}
              label="Bitiş"
              errorText="Bitiş tarihi seçiniz."
              initialValue={finishDateSelected}
              validators={[VALIDATOR_REQUIRE()]}
            ></DatePicker>
          </Grid>

          <Grid item xs={2}>
            <div style={{ position: "relative", top: "8px" }}>
              <Button type="button" disabled={!formState.isValid} onClick={createReport}>
                Rapor Oluştur
              </Button>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div style={{ position: "relative", top: "8px" }}>
              <Button type="button" onClick={downloadExcel} disabled={!calculatedPersonelServicesPickup.length}>
                Raporu İndir
              </Button>
            </div>
          </Grid>

          {/* <Grid item xs={4}>
            <div style={{ position: "relative", float: "right", top: "8px" }}>
              <Button type="button" onClick={() => setDefaultFormat(!defaultFormat)}>
                Format Değiştir
              </Button>
              {!defaultFormat && (
                <Button type="button" onClick={() => window.print()}>
                  Yazdır
                </Button>
              )}
            </div>
          </Grid> */}
        </Grid>
      </form>

      {calculatedPersonelServicesPickup.length > 0 && <h3>Toplama Servisleri</h3>}

      {calculatedPersonelServicesPickup.length > 0 && (
        <div id="printableArea" style={{ display: "flex", flex: "1", flexDirection: "row" }}>
          <div style={{ width: "100%" }}>
            <table style={{ borderCollapse: `${defaultFormat ? "inherit" : "collapse"}` }}>
              <tbody>
                <tr>
                  <th style={{ width: "400px" }}>Vardiya İsmi</th>
                  <th style={{ width: "120px" }}>Servis Tarihi</th>
                  <th style={{ width: "300px" }}>Servis İsmi</th>
                  <th style={{ width: "70px" }}>Varış Saati</th>
                  <th style={{ width: "400px" }}>Yolcular</th>
                  <th style={{ width: "400px" }}>Sürücü</th>
                </tr>

                {calculatedPersonelServicesPickup.map((personelService, index) => (
                  <tr
                    key={personelService.service._id + "toplamaasd"}
                    style={{
                      backgroundColor: defaultFormat ? "#dddddd" : index % 2 === 0 ? "#dddddd" : "#aaaaaa",
                      textAlign: "center",
                      position: "relative",
                      left: `${personelService.service.type.toString() === "100" || !defaultFormat ? "0" : "720px"}`,
                      borderBottom: `${defaultFormat ? "none" : "1px solid black"}`,
                    }}
                  >
                    <td>{personelService.shift.name + " - " + personelService.shift.description}</td>
                    <td>{personelService.service.date}</td>

                    <td>{personelService.service.name}</td>
                    <td>{personelService.service.routeClientArrivalTime || ""}</td>
                    <td>{peopleComponent(personelService.people)}</td>
                    <td>{driverComponent(personelService.service)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {calculatedPersonelServicesDropoff.length > 0 && <h3 style={{ marginTop: "50px" }}>Dağıtma Servisleri</h3>}
      {calculatedPersonelServicesDropoff.length > 0 && (
        <div id="printableArea" style={{ display: "flex", flex: "1", flexDirection: "row" }}>
          <div style={{ width: "100%" }}>
            <table style={{ borderCollapse: `${defaultFormat ? "inherit" : "collapse"}` }}>
              <tbody>
                <tr>
                  <th style={{ width: "400px" }}>Vardiya İsmi</th>
                  <th style={{ width: "120px" }}>Servis Tarihi</th>
                  <th style={{ width: "300px" }}>Servis İsmi</th>
                  <th style={{ width: "70px" }}>Kalkış Saati</th>
                  <th style={{ width: "400px" }}>Yolcular</th>
                  <th style={{ width: "400px" }}>Sürücü</th>
                </tr>

                {calculatedPersonelServicesDropoff.map((personelService, index) => (
                  <tr
                    key={personelService.service._id + "dagitmaasd"}
                    style={{
                      backgroundColor: defaultFormat ? "#dddddd" : index % 2 === 0 ? "#dddddd" : "#aaaaaa",
                      textAlign: "center",
                      position: "relative",

                      borderBottom: `${defaultFormat ? "none" : "1px solid black"}`,
                    }}
                  >
                    <td>{personelService.shift.name + " - " + personelService.shift.description}</td>
                    <td>{personelService.service.date}</td>

                    <td>{personelService.service.name}</td>
                    <td>{personelService.service.routeClientArrivalTime || ""}</td>
                    <td>{peopleComponent(personelService.people)}</td>
                    <td>{driverComponent(personelService.service)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Plans;
