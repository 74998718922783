import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";

import DriverItem from "components/driver/DriverItem";

const Drivers = () => {
  const { t } = useTranslation();
  const itemType = "driverItem";
  const listName = "main_drivers";

  const { drivers, DeleteDriver, InsertDriver, UpdateDriver } = useContext(StoreContext);

  const pageItems = drivers;

  let listColumns = [];

  listColumns.push({ field: "name", headerName: t("name"), width: 300 });
  listColumns.push({ field: "phonenumber", headerName: t("phone"), width: 200 });
  listColumns.push({ field: "email", headerName: t("plate"), width: 200 });
  // listColumns.push({ field: "email", headerName: t("email"), width: 250 });
  listColumns.push({ field: "address", headerName: t("address"), width: 400 });
  listColumns.push({ field: "identity", headerName: t("identityNumber"), width: 150 });

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={DriverItem}
      NewItemComponent={DriverItem}
      DeleteItem={DeleteDriver}
      InsertItem={InsertDriver}
      UpdateItem={UpdateDriver}
    />
  );
};

export default Drivers;
