import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "shared/components/FormElements/Button";
import Modal from "shared/components/UIElements/Modal";
import "./DeleteModal.css";

const GenericModal = ({ showModal, title, message, onClose }) => {
  const { t } = useTranslation();

  const [showGenericModal, setShowGenericModal] = useState(showModal);

  const cancelGenericHandler = () => {
    setShowGenericModal(false);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setShowGenericModal(showModal);
  }, [showModal]);

  return (
    <React.Fragment>
      <Modal
        show={showGenericModal}
        onCancel={cancelGenericHandler}
        header={title}
        footerClass="delete-modal-footer"
        footer={
          <div className="delete-modal-buttons-container">
            <Button danger onClick={cancelGenericHandler}>
              {t("close")}
            </Button>
          </div>
        }
      >
        <p>{message}</p>
      </Modal>
    </React.Fragment>
  );
};

export default GenericModal;
