import React, { useCallback, useState, useContext } from "react";

import TransferList from "./TransferList";
import TransferItem from "./TransferItem";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import "./Admin.css";

const Transfer = (props) => {
  const store = useContext(StoreContext);
  const { isLoading, error, clearError } = useHttpClient();
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);
  const [listShow, setListShow] = useState(true);

  let pageItems = store.transfers;
  if (props.active) {
    pageItems = store?.transfers?.filter((item) => item.done === false) || [];
  }

  const switchItemView = useCallback(() => {
    setSelectedItemShow(true);
    setListShow(false);
  }, [setSelectedItemShow, setListShow]);

  const ItemSelectHandler = useCallback(
    (itemid) => {
      switchItemView();
      setSelectedItemId(itemid);
    },
    [switchItemView, setSelectedItemId]
  );

  const ItemUpdateHandler = useCallback(
    (itemid) => {
      setSelectedItemId(itemid);
    },
    [setSelectedItemId]
  );

  const switchListView = useCallback(() => {
    setSelectedItemShow(false);
    setListShow(true);
  }, [setSelectedItemShow, setListShow]);

  // if(isLoading || !store.vehicleRoutes) //HERE
  if (isLoading && false) {
    //HERE
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      {/* <div className="adminNavigationHeader">
        <NavLink to={newItemLink} end>
          Yeni {ItemTypeTR}
        </NavLink>
      </div> */}
      <div className="adminpageContainer">
        {!isLoading && pageItems && listShow && (
          <div className="liveListContainer">
            <TransferList items={pageItems} onItemSelect={ItemSelectHandler} />
          </div>
        )}
        {selectedItemId && selectedItemShow && (
          <div className="liveUpdateContainer">
            <TransferItem
              itemid={selectedItemId}
              currentTime={Date.now()}
              onItemUpdate={ItemUpdateHandler}
              // onClose={ () => setSelectedItemShow(false) }
              onClose={() => switchListView()}
            ></TransferItem>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Transfer;
