export function GetHoursMinutesFromDate(date) {
  if (typeof date === "string") date = new Date(date);

  return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
}

export function GetStartStopTime(date) {
  if (!date || date === "" || date === undefined) {
    return "";
  }
  return date.split(" ")[4].split(":")[0] + ":" + date.split(" ")[4].split(":")[1];
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-") +
    " " +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(":")
  );
}

export function getFormattedDate(date) {
  return formatDate(date).split(" ")[0];
}

export function subtractSecondsFromDate(dateString, seconds) {
  var date = new Date(dateString);
  date.setSeconds(date.getSeconds() - seconds);
  return date.toString();
}

export function subtractSecondsFromTime(timeStr, secondsToSubtract) {
  const checkTimeStr = CheckTimeString(timeStr);
  const checkSeconds = typeof secondsToSubtract === "number" && secondsToSubtract >= 0;
  if (!checkTimeStr || !checkSeconds) {
    return null;
  }

  // Split the time string into hours and minutes
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Create a new Date object with today's date and the given time
  const time = new Date();
  time.setHours(hours);
  time.setMinutes(minutes);
  time.setSeconds(0); // Set seconds to 0 for an exact time representation

  // Subtract the specified seconds
  time.setSeconds(time.getSeconds() - secondsToSubtract);

  // Extract the hours and minutes from the updated Date object
  const updatedHours = String(time.getHours()).padStart(2, "0");
  const updatedMinutes = String(time.getMinutes()).padStart(2, "0");

  // Return the updated time in "HH:MM" format
  return `${updatedHours}:${updatedMinutes}`;
}

export function CheckTimeString(time) {
  //check if the string is HH:MM format
  time = time?.trim();
  if (time?.length === 5) {
    const hours = time.split(":")[0];
    const minutes = time.split(":")[1];
    if (hours.length === 2 && minutes.length === 2) {
      //check if the hours and minutes are valid
      if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
        return true;
      }
    }
  }
  return false;
}

export function CheckTimeString_EmptyAllowed(time) {
  //check if the string is HH:MM format
  time = time?.trim();

  if (!time || time === "") {
    return true;
  }
  if (time?.length === 5) {
    const hours = time.split(":")[0];
    const minutes = time.split(":")[1];
    if (hours.length === 2 && minutes.length === 2) {
      //check if the hours and minutes are valid
      if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
        return true;
      }
    }
  }
  return false;
}
