import React, { useContext } from "react";

import Tabs from "components/shared/Tabs";

import { AuthContext } from "shared/context/auth-context";
import DataErrors from "./DataErrors";
import DistributeStudents from "./DistributeStudents";
import BulkProcesses from "./BulkProcesses";
import ExcelAnalysis from "./ExcelAnalysis";
import ServiceReports from "./ServiceReports";
import { PERMISSION_TYPES } from "shared/util/constants";
// import TransferRegion from './TransferRegion';
// import TransferRegionNew from './TransferRegionNew';

import "./AnalysisItem.css";

const AnalysisManagement = (props) => {
  const auth = useContext(AuthContext);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  let analysisTabs = [];

  if (auth.permissions[PERMISSION_TYPES.ACCESS_TO_DATA_ERRORS_SCHOOL]) {
    analysisTabs.push({
      label: "Eksik Veriler",
      content: <DataErrors></DataErrors>,
    });
  }

  if (auth.permissions[PERMISSION_TYPES.ACCESS_TO_BULK_PROCESSES]) {
    analysisTabs.push({
      label: "Toplu İşlemler",
      content: <BulkProcesses></BulkProcesses>,
    });
  }

  if (auth.permissions[PERMISSION_TYPES.ACCESS_TO_DISTRIBUTE_STUDENTS]) {
    analysisTabs.push({
      label: "Öğrenci Dağıtımı",
      content: <DistributeStudents></DistributeStudents>,
    });
  }

  if (auth.permissions[PERMISSION_TYPES.ACCESS_TO_EXCEL_ANALYSIS]) {
    analysisTabs.push({
      label: "Excel İşlemleri",
      content: <ExcelAnalysis></ExcelAnalysis>,
    });
  }

  if (auth.permissions[PERMISSION_TYPES.ACCESS_TO_SERVICE_REPORTS]) {
    analysisTabs.push({
      label: "Vardiya Servis Raporları",
      content: <ServiceReports></ServiceReports>,
    });
  }

  // const analysisTabs = React.useMemo(() => {
  //   return [
  //     {
  //       label: "Eksik Veriler",
  //       content: <DataErrors></DataErrors>,
  //     },
  //     {
  //       label: "Toplu İşlemler",
  //       content: <BulkProcesses></BulkProcesses>,
  //     },
  //     {
  //       label: "Öğrenci Dağıtımı",
  //       content: <DistributeStudents></DistributeStudents>,
  //     },
  //     {
  //       label: "Excel İşlemleri",
  //       content: <ExcelAnalysis></ExcelAnalysis>,
  //     },
  //     // {
  //     //   label: "Servis İşlemleri",
  //     //   component: <ServiceAnalysis></ServiceAnalysis>,
  //     // },
  //   ];
  // }, []);

  // if (!auth.permissions.accessToAnalysis) {
  //   return <NotFound />;
  // }

  return (
    <React.Fragment>
      <Tabs tabs={analysisTabs} tabValue={tabValue} onTabChange={handleTabChange} isVertical={true} />
    </React.Fragment>
  );
};

export default AnalysisManagement;
