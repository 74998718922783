import React from "react";
import Error from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import Button from "shared/components/FormElements/Button";

const NotFound = () => {
  const { t } = useTranslation();

  const handleGoHome = () => {
    window.location.href = "/";
    //navigate("/login"); // Adjust the route as needed
  };

  return (
    <React.Fragment>
      <div
        className="center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
          textAlign: "center",
          backgroundColor: "#fff",
          color: "#333",
        }}
      >
        <div style={{ marginBottom: "0px" }}>
          <Error style={{ fontSize: "100px", color: "#830000" }} />
        </div>
        <h2 style={{ fontSize: "28px", marginBottom: "10px" }}>{t("NotFound.title")}</h2>
        <p style={{ marginBottom: "20px", color: "#555" }}>{t("NotFound.description")}</p>
        {/* <button
          style={{
            padding: "10px 20px",
            backgroundColor: "#009933",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          {t("NotFound.CTA")}
        </button> */}
        <Button onClick={handleGoHome}> {t("NotFound.CTA")}</Button>
        <p style={{ marginTop: "30px", color: "#999", fontSize: "12px" }}>
          {t("NotFound.code")}
          <br />
        </p>
        <footer style={{ marginTop: "40px", color: "#999", fontSize: "12px" }}>
          © {new Date().getFullYear()} Filokar. {t("allRightsReserved")}
        </footer>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
