import React, { useState, useContext, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Layout from "pages/shared/Layout";

import CustomSelect from "shared/components/FormElements/Select";

import { CheckAuthorization } from "shared/util/util";
import { CURRENT_EDUCATION_YEAR_ID } from "shared/util/constants";

import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import ContractNew from "./ContractNew";
import ContractList from "./ContractList";
import ContractItem from "./ContractItem";

import TabPanel from "shared/components/UIElements/TabPanel";

const Contracts = (props) => {
  const { t } = useTranslation();
  const itemType = "contract";
  const listName = "main_contracts";

  const {
    contracts,
    educationYearsOptions,
    schoolOptions,
    students,
    contractsOptions,
    receipts,
    selectedEducationYearId,
    InsertContract,
    UpdateContract,
    DeleteContract,
  } = useContext(StoreContext);

  const [pageItems, setPageItems] = useState(contracts.filter((item) => item.year === selectedEducationYearId));
  // const [educationYear, setEducationYear] = useState(selectedEducationYear.value);

  // const yearChangeHandler = (id, value) => {
  //   setEducationYear(value);
  //   setPageItems(contracts.filter((item) => item.year === value));
  // };

  useEffect(() => {
    setPageItems(contracts.filter((item) => item.year === selectedEducationYearId));
  }, [contracts, selectedEducationYearId]);

  const listColumns = [
    {
      field: "school",
      headerName: t("school"),
      width: 300,
      valueGetter: (params) =>
        schoolOptions.find((item) => item.value.toString() === params.row.student.school.toString()).label,
    },
    {
      field: "year",
      headerName: t("year"),
      width: 150,
      valueGetter: (params) =>
        educationYearsOptions.find((item) => item.value.toString() === params.row.year.toString()).label,
    },
    { field: "payerName", headerName: t("payerName"), width: 300 },
    {
      field: "student",
      headerName: t("student.title"),
      width: 300,
      valueGetter: (params) => {
        try {
          return students.find((item) => item.id.toString() === params.row.student.id.toString()).name;
        } catch {
          return "";
        }
      },
    },
    { field: "finalAmount", headerName: t("finalAmount"), width: 100 },

    {
      field: "totalPayed",
      headerName: t("paid"),
      width: 100,
      valueGetter: (params) => {
        try {
          const contractId = contractsOptions.find((item) => item.value.toString() === params.row.id.toString()).value;
          const allreceipts = receipts.filter((item) => item.contract.toString() === contractId);

          let totalPayed = 0;
          allreceipts.forEach((item) => {
            totalPayed += item.amount;
          });

          return totalPayed;
        } catch (e) {
          return "";
        }
      },
    },

    {
      field: "totalBorc",
      headerName: t("remainingBalance"),
      width: 100,
      valueGetter: (params) => {
        try {
          const contractId = contractsOptions.find((item) => item.value.toString() === params.row.id.toString()).value;

          const allreceipts = receipts.filter((item) => item.contract.toString() === contractId);

          let totalPayed = 0;
          allreceipts.forEach((item) => {
            totalPayed += item.amount;
          });

          return parseFloat(params.row.finalAmount) - totalPayed;
        } catch (e) {
          return "";
        }
      },
    },

    {
      field: "userApproved",
      headerName: t("userApproved"),
      width: 75,
      valueGetter: (params) => (params.row.userApproved === true ? "Evet" : "Hayır"),
    },

    { field: "payerIdentity", headerName: t("identityNumber"), width: 75, hide: true },
    { field: "payerMail", headerName: t("email"), width: 75, hide: true },
    { field: "payerAddress", headerName: t("address"), width: 150, hide: true },
    {
      field: "isCompanyReceipt",
      headerName: t("isCompanyInvoice"),
      width: 75,
      valueGetter: (params) => (params.row.isCompanyReceipt === true ? t("yes") : t("no")),
      hide: true,
    },

    { field: "companyTitle", headerName: t("companyName"), width: 150, hide: true },
    { field: "taxNo", headerName: t("taxId"), width: 75, hide: true },
    { field: "taxOffice", headerName: t("taxOffice"), width: 75, hide: true },
  ];

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={ContractItem}
      NewItemComponent={ContractItem}
      DeleteItem={DeleteContract}
      InsertItem={InsertContract}
      UpdateItem={UpdateContract}
    />
  );
};

export default Contracts;
