import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "shared/components/FormElements/Button";
import Modal from "shared/components/UIElements/Modal";

import "./DeleteModal.css";

const AreYouSureModal = ({ showModal, text, onClose, onConfirmed }) => {
  const { t } = useTranslation();

  const [showConfirmModal, setShowConfirmModal] = useState(showModal);

  const cancelHandler = () => {
    setShowConfirmModal(false);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setShowConfirmModal(showModal);
  }, [showModal]);

  const confirmHandler = async () => {
    onConfirmed();
    onClose();
  };

  return (
    <React.Fragment>
      <Modal
        show={showConfirmModal}
        onCancel={cancelHandler}
        header={t("areYouSure")}
        footerClass="delete-modal-footer"
        footer={
          <div className="delete-modal-buttons-container">
            <Button inverse onClick={cancelHandler}>
              {t("no")}
            </Button>
            <Button danger onClick={confirmHandler}>
              {t("yes")}
            </Button>
          </div>
        }
      >
        <p>{text}</p>
      </Modal>
    </React.Fragment>
  );
};

export default AreYouSureModal;
