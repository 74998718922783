import { Delete } from "@mui/icons-material";
import { createContext } from "react";

export const StoreContext = createContext({
  isAdmin: false,
  users: [],
  userMap: {},
  studentToParentMap: {},
  userToParentMap: {},
  clientTypes: [],
  userTypes: [],
  serviceType: null,
  companies: [],
  companyOptions: [],
  schools: [],
  vehicles: [],
  students: [],
  drivers: [],
  driverOptions: [],
  vehicleRoutes: [],
  schoolOptions: [],
  stopOptions: [],
  studentOptions: [],
  parentOptions: [],
  personelOptions: [],
  relations: [],
  storeReady: false,
  isProcessing: false,
  contracts: [],
  educationYears: [],
  discounts: [],
  serviceTemplates: [],
  educationYearsOptions: [],
  discountOptions: [],
  serviceTemplateOptions: [],
  receipts: [],
  withdraws: [],
  contractsOptions: [],
  expenses: [],
  vehicleRouteOptions: [],
  vehiclePayments: [],
  transfers: [],
  pLocations: [],
  pLocationsOptions: [],
  transferregions: [],
  transferregionOptions: [],
  ikarusTransfers: [],
  ikarusTransferRegions: [],
  ikarusTransferRegionOptions: [],
  ikarusTransferPassengers: [],
  ikarusTransferPassengerOptions: [],
  shifts: [],
  stops: [],
  userOptions: [],
  shiftOptions: [],
  shiftUsers: [],
  shiftUserOptions: [],
  // Personel Services
  standartPersonelServices: [],
  standartPersonelServiceOptions: [],
  shiftPersonelServices: [],
  shiftPersonelServiceOptions: [],

  selectedEducationYearId: null,
  UpdateSelectedEducationYearId: (educationYearId) => {},

  // Functions
  UpdateVehicles: () => {},
  UpdateVehicleRoutes: () => {},
  InsertVehicleRoute: (vehicleRoute) => {},
  UpdateVehicleRoute: (vehicleRoute) => {},
  DeleteVehicleRoute: (vehicleRouteId) => {},
  UpdateCompanies: () => {},
  UpdateSchools: () => {},
  InsertSchool: (school) => {},
  UpdateSchool: (school) => {},
  DeleteSchool: (schoolId) => {},
  UpdateRelations: async () => {},
  UpdateUsers: () => {},
  InsertUser: (user) => {},
  UpdateUser: (user) => {},
  DeleteUser: (userId) => {},
  InsertStudent: (student) => {},
  UpdateStudent: (student) => {},
  DeleteStudent: (studentId) => {},
  InsertRelation: (relation) => {},
  UpdateRelation: (relation) => {},
  DeleteRelation: (relationId) => {},
  UpdatePersonelServices: () => {},
  InsertPersonelService: (service) => {},
  UpdatePersonelService: (service) => {},
  DeletePersonelService: (serviceId) => {},
  InsertShift: (shift) => {},
  UpdateShift: (shift) => {},
  DeleteShift: (shiftId) => {},
  InsertStop: (stop) => {},
  UpdateStop: (stop) => {},
  DeleteStop: (stopId) => {},
  UpdateTransfers: async () => {},
  UpdateStudents: async () => {},
  UpdateEverything: async () => {},
  RetrieveContracts: async () => {},
  InsertContract: (contract) => {},
  UpdateContract: (contract) => {},
  DeleteContract: (contractId) => {},
  RetrieveReceipts: async () => {},
  RetrieveWithdraws: async () => {},
  RetrieveAccountingUtils: async () => {},
  RetrieveExpenses: async () => {},
  UpdateVehiclePayments: async () => {},
  UpdatePLocations: async () => {},
  UpdateTransferregions: async () => {},
  UpdateIkarusTransfers: async () => {},
  UpdateIkarusTransferRegions: async () => {},
  UpdateIkarusTransferPassengers: async () => {},
  UpdateShifts: async () => {},
  UpdateStops: async () => {},
  UpdateTypes: async (code) => {},
  GetParentFromStudent: (studentId) => {},
  UpdateDrivers: async () => {},
  InsertDriver: (driver) => {},
  UpdateDriver: (driver) => {},
  DeleteDriver: (driverId) => {},
  RetrieveEducationYears: async () => {},
});
