export function getColumnValue(field, headerName, options, id, width) {
  return {
    field,
    headerName,
    width,
    valueGetter: (params) => {
      try {
        return options.find((item) => item?.value?.toString() === params?.row[id]?.toString())?.label?.toString();
      } catch {
        return "-";
      }
    },
  };
}
