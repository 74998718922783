import React, { useCallback, useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import TransferRegionList from "./TransferRegionList";
import TransferRegionItem from "./TransferRegionItem";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import "./Admin.css";

const TransferRegion = (props) => {
  const store = useContext(StoreContext);
  const { isLoading, error, clearError } = useHttpClient();
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);
  const [listShow, setListShow] = useState(true);

  let pageItems = store.transferregions;
  const newItemLink = "/new/transferregion";
  const ItemTypeTR = "Transfer Bölgesi";

  console.log("TransferRegion.js pageItems", pageItems);

  const switchItemView = useCallback(() => {
    setSelectedItemShow(true);
    setListShow(false);
  }, [setSelectedItemShow, setListShow]);

  const ItemSelectHandler = useCallback(
    (itemid) => {
      // setSelectedItemShow(true);
      switchItemView();
      setSelectedItemId(itemid);
    },
    [switchItemView, setSelectedItemId]
  );

  const ItemUpdateHandler = useCallback(
    (itemid) => {
      setSelectedItemId(itemid);
    },
    [setSelectedItemId]
  );

  const switchListView = useCallback(() => {
    setSelectedItemShow(false);
    setListShow(true);
  }, [setSelectedItemShow, setListShow]);

  if (isLoading || !store.vehicleRoutes) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <div className="adminNavigationHeader">
        <NavLink to={newItemLink} end>
          Yeni {ItemTypeTR}
        </NavLink>
      </div>
      <div className="adminpageContainer">
        {!isLoading && pageItems && listShow && (
          <div className="liveListContainer">
            <TransferRegionList items={pageItems} onItemSelect={ItemSelectHandler} />
          </div>
        )}
        {selectedItemId && selectedItemShow && (
          <div className="liveUpdateContainer">
            <TransferRegionItem
              itemid={selectedItemId}
              currentTime={Date.now()}
              onItemUpdate={ItemUpdateHandler}
              // onClose={ () => setSelectedItemShow(false) }
              onClose={() => switchListView()}
            ></TransferRegionItem>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TransferRegion;
