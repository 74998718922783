import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { SERVICE_TYPES } from "shared/util/constants";
import "./Admin.css";

import RelationItem from "components/relation/RelationItem";

const Relations = () => {
  const { t } = useTranslation();
  const itemType = "relation";
  const listName = "main_relations";

  const {
    relations,
    users,
    students,
    schoolOptions,
    studentOptions,
    serviceType,
    DeleteRelation,
    InsertRelation,
    UpdateRelation,
  } = useContext(StoreContext);
  const pageItems = relations;

  let listColumns = [];

  if (schoolOptions?.length > 1) {
    listColumns.push({
      field: "school",
      headerName: t("client"),
      width: 300,
      valueGetter: (params) => {
        try {
          const currentSchool = students.find((item) => item.id.toString() === params.row?.student.toString())?.school;
          if (currentSchool) {
            return schoolOptions.find((item) => item.value.toString() === currentSchool.toString())?.label || "-";
          }
        } catch (e) {
          return "-";
        }
      },
    });
  }

  listColumns.push({
    field: "parent",
    headerName: t("notificationOwner"),
    width: 400,
    valueGetter: (params) => {
      try {
        return users.find((item) => item.id.toString() === params.row?.parent?.toString())?.name || "-";
      } catch (e) {
        return "-";
      }
    },
  });
  listColumns.push({
    field: "student",
    headerName:
      serviceType === SERVICE_TYPES.SCHOOL ? t("notificationStudentDescription") : t("notificationLocationDescription"),
    width: 700,
    valueGetter: (params) => {
      try {
        return studentOptions.find((item) => item.value.toString() === params.row?.student?.toString())?.label || "-";
      } catch (e) {
        return "-";
      }
    },
  });
  listColumns.push({
    field: "isenabled",
    headerName: t("status"),
    width: 150,
    valueGetter: (params) => (params.row.isenabled ? t("enabled") : t("disabled")),
  });

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={RelationItem}
      NewItemComponent={RelationItem}
      DeleteItem={DeleteRelation}
      InsertItem={InsertRelation}
      UpdateItem={UpdateRelation}
    />
  );
};

export default Relations;
