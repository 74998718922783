import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";
import { AuthContext } from "shared/context/auth-context";
import { getInitialFilters } from "shared/util/util";

const UsersList = (props) => {
  const { t } = useTranslation();
  const { schoolOptions, standartPersonelServices, userTypes } = useContext(StoreContext);
  const auth = useContext(AuthContext);

  const filters = getInitialFilters("users");

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(filters.initialColumns);
  const [filterModel, setFilterModel] = useState(filters.initialFilters);

  // let usertypestoshow = props.usertypes;

  // if (isAdmin === false) {
  //   usertypestoshow.pop();
  // }

  const isShiftUserColumn = {
    field: "isshiftuser",
    headerName: t("isShiftUser"),
    width: 150,
    valueGetter: (params) => (params.row.isshiftuser ? t("yes") : t("no")),
  };

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <DataGrid
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          localStorage.setItem("users_visibility", JSON.stringify(newModel));
          setColumnVisibilityModel(newModel);
        }}
        filterModel={filterModel}
        onFilterModelChange={(newModel) => {
          localStorage.setItem("users_filter", JSON.stringify(newModel));
          setFilterModel(newModel);
        }}
        key={props.items}
        density="compact"
        components={{
          Toolbar: CustomToolbar,
        }}
        rows={props.items}
        pageSize={100}
        getRowId={(r) => r.id}
        onSelectionModelChange={OnSelectionChangeHandler}
        selectionModel={props?.selectedId ? [props.selectedId] : []}
        paginationModel={"server"}
        keepNonExistentRowsSelected
      />
    </div>
  );
};

export default UsersList;
