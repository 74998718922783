import React, { useContext, useState, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import "flag-icon-css/css/flag-icons.min.css";
import { NavLink } from "react-router-dom";

import NavLinks from "./NavLinks";
import SideDrawer from "./SideDrawer";
import Backdrop from "shared/components/UIElements/Backdrop";
import Button from "shared/components/FormElements/Button";
import "./MainNavigation.css";
import { languages } from "shared/util/types";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

const MainNavigation = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <Suspense fallback="loading">
      <React.Fragment>
        {auth.isLoggedIn && (
          <React.Fragment>
            {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
            <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
              <nav className="main-navigation-links-mobile">
                <NavLinks />
              </nav>
            </SideDrawer>
          </React.Fragment>
        )}

        <div className="main-navigation">
          <div className="main-navigation-left">
            {auth.isLoggedIn && (
              <React.Fragment>
                <button className="main-navigation-drawer-btn" onClick={openDrawerHandler}>
                  <span />
                  <span />
                  <span />
                </button>
              </React.Fragment>
            )}
            <h3 className="main-navigation-title">
              <Link to="/">{t("leftTopTitle")}</Link>
            </h3>
            <nav className="main-navigation-links-desktop">
              <NavLinks />
            </nav>
          </div>

          {/* <div className="language-area"> */}
          <div className="main-navigation-right">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {auth.isLoggedIn && <h4>{t("settings")}</h4>}
                <FaUserCircle className="user-circle" />
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  minWidth: "300px",
                  borderBottomRightRadius: "5px",
                  borderBottomLeftRadius: "5px",
                  borderTop: "1px solid #555",
                  marginRight: "20px",
                }}
              >
                {auth?.company?.name && auth?.name && (
                  <div style={{ display: "flex", flexDirection: "column", paddingLeft: "10px", paddingRight: "10px" }}>
                    <span style={{ fontSize: "18px", marginBottom: "10px" }}>{auth.name}</span>
                    <span style={{ fontSize: "14px" }}>{auth?.company?.name}</span>
                  </div>
                )}

                {/* {auth.isLoggedIn && (
                  <div
                    style={{
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <NavLink to="/settings" onClick={() => {}}>
                      {t("settings")}
                    </NavLink>
                  </div>
                )} */}

                <Dropdown.Item eventKey="settings" key="settings" to="/" onClick={async () => navigate("/settings")}>
                  <div
                    style={{ paddingBottom: "10px", marginLeft: "10px", fontSize: "16px", textDecoration: "underline" }}
                  >
                    {t("settings")}
                  </div>
                </Dropdown.Item>

                {languages.map(({ code, name, country_code }) => (
                  <Dropdown.Item
                    eventKey={name}
                    key={country_code}
                    to="/"
                    onClick={async () => {
                      i18n.changeLanguage(code);
                      localStorage.setItem("i18nextLng", code);
                      store.UpdateTypes(code);
                      auth.UpdateTheme(code);
                    }}
                  >
                    <span
                      className={`flag-icon flag-icon-${country_code}`}
                      style={{ marginBottom: "10px", marginLeft: "10px" }}
                    ></span>{" "}
                    {name}
                  </Dropdown.Item>
                ))}

                {auth.isLoggedIn && (
                  <div style={{ marginLeft: "10px", marginBottom: "10px" }}>
                    <Button onClick={auth.logout}>{t("logout")}</Button>
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* </div> */}
        </div>
      </React.Fragment>
    </Suspense>
  );
};

export default MainNavigation;
