import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHttpClient } from "shared/hooks/http-hook";
import Button from "shared/components/FormElements/Button";
import Modal from "shared/components/UIElements/Modal";
import "./DeleteModal.css";
import { HTTP_CODES } from "shared/util/constants";

const DeleteModal = ({ showModal, itemType, endPoint, itemId, onDeleted, onClose }) => {
  const { t } = useTranslation();
  const { sendRequest } = useHttpClient();

  const [showConfirmModal, setShowConfirmModal] = useState(showModal);

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setShowConfirmModal(showModal);
  }, [showModal]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${endPoint}/${itemId}`, "DELETE", null);
      window.toast.success(t("itemDeleted", { item: t(itemType + ".title") }));
      onDeleted();
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
    onClose();
  };

  return (
    <React.Fragment>
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header={t("areYouSure")}
        footerClass="delete-modal-footer"
        footer={
          <div className="delete-modal-buttons-container">
            <Button inverse onClick={cancelDeleteHandler}>
              {t("no")}
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              {t("yes")}
            </Button>
          </div>
        }
      >
        <p>{t(`${itemType}.deleteItemWarning`)}</p>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteModal;
