import React, { useEffect } from "react";
import ReactSlider from "react-slider";

import "./slider2.css";

const Slider2 = (props) => {
  const [value, setValue] = React.useState();
  const [text, setText] = React.useState();

  useEffect(() => {
    setValue(props.value);
    setText(props.text);
    const sliderElement = document.getElementById("timeslider");

    if (sliderElement) {
      sliderElement.innerHTML = 30;
    }
  }, [props.value, props.text]);

  const onInputChangeHandler = (newIndex) => {
    // const newIndex = event.target.value;
    setValue(newIndex);
    props.onSliderValueChange(newIndex);
  };

  return (
    <ReactSlider
      onSliderClick={onInputChangeHandler}
      min={props.min}
      max={props.max}
      value={value}
      className="horizontal-slider"
      thumbClassName="slider2-thumb"
      trackClassName="slider2-track"
      renderTrack={(props, state) => <div {...props} />} //custom track
      renderThumb={(props, state) => (
        <div {...props}>
          <div className="thumb-label">{text}</div> {/* Display the value above the thumb */}
        </div>
      )}
    />
  );
};

export default Slider2;
