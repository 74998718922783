import { useBackendContext } from "./backend-hook";

export const useFreeSms = () => {
  const { sendRequest, t } = useBackendContext();

  const sendFreeSms = async (phone, text) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/gfhhdethjjdsd`,
        "POST",
        JSON.stringify({ phone, text })
      );

      return responseData?.sms || null;
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };

  const getFreeSms = async (smsId) => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/gfhhdethjjdsd/${smsId}`, "GET");

      return responseData?.sms || null;
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };

  return { sendFreeSms, getFreeSms };
};
