import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,

  id: null,
  token: null,
  tokenExpirationDate: null,

  name: null,
  email: null,
  serviceType: null,
  schools: [],
  type: null,
  company: null,

  loaded: false,
  theme: null,

  login: () => {},
  logout: () => {},
  UpdateTheme: (code) => {},
});
