import React, { useReducer, useEffect } from "react";

import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import ErrorIcon from "@mui/icons-material/Error";
import { red } from "@mui/material/colors";

import { validate } from "../../util/validators";
import "./Input.css";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH": {
      return {
        ...state,
        isTouched: true,
      };
    }
    default:
      return state;
  }
};

const Input = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || "",
    isTouched: false,
    isValid: props.initialValid || false,
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = (event) => {
    if (props?.onChange) {
      props.onChange(event);
    }
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
    });
    dispatch({
      type: "TOUCH",
    });
  };

  const keyUpHandler = (event) => {
    if (props.onKeyUp) {
      props.onKeyUp(event);
    }
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  const element =
    props.element === "input" ? (
      <input
        autoComplete={props.autoComplete || "off"}
        // autoComplete={props.autoComplete ? "on" : "off"}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        disabled={props.disabled}
        onKeyUp={keyUpHandler}
      />
    ) : (
      <textarea
        id={props.id}
        rows={props.rows || 3}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
      />
    );

  return (
    <div
      // className={`generic-input ${!inputState.isValid && inputState.isTouched && "generic-input--invalid"}`}
      className={`generic-input ${!inputState.isValid && inputState.isTouched && "generic-input--invalid"}`}
      style={{ ...props.style }}
    >
      {props.label && (
        <label htmlFor={props.id} style={{ whiteSpace: "pre-wrap" }}>
          {props.label}

          {!inputState.isValid && inputState.isTouched && !props?.dontShowError && (
            <Tooltip title={props.errorText}>
              <ErrorIcon
                sx={{ color: red[800], width: "22px", height: "auto", marginLeft: "5px" }}
                className="blinking"
              />
            </Tooltip>
          )}
        </label>
      )}
      {element}
      {/* {!inputState.isValid && inputState.isTouched && <p style={{ whiteSpace: "pre-wrap" }}>{props.errorText}</p>} */}
    </div>
  );
};

export default Input;
