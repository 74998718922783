import React, { useEffect, useState, useContext, useCallback } from "react";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import { paymentMethods, banks, isOfficial, paymentTypes, expenseTypes } from "shared/util/types";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./AccountingItem.css";

const ReceiptItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();

  const itemId = props.itemid;
  const updateStoreItems = store.RetrieveExpenses;

  const ItemTypeTR = "Gider";
  const ItemType = "expense";
  const ItemEndPointType = "expenses";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);
      setItemState(Date.now().toString());
    } catch (err) {}
  }, [sendRequest, itemId]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      setItemState(Date.now());
      updateStoreItems();
      setLoadedItem(null);
      //   history.push(`/${ItemEndPointType}`);
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: true },
      date: { value: "", isValid: true },
      type: { value: "", isValid: true },
      description: { value: "", isValid: true },
      vehicle: { value: "", isValid: true },
      person: { value: "", isValid: true },
      paymentMethod: { value: "", isValid: true },
      paymentType: { value: "", isValid: true },
      amount: { value: null, isValid: true },
      bank: { value: null, isValid: true },
      receiptNo: { value: null, isValid: true },
      official: { value: null, isValid: true },
    },
    true
  );

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();
    let responseData;
    try {
      responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "PATCH",
        JSON.stringify({
          school: formState.inputs.school.value,
          date: formState.inputs.date.value,
          type: formState.inputs.type.value,
          description: formState.inputs.description.value,
          vehicle: formState.inputs.vehicle.value,
          person: formState.inputs.person.value,
          paymentMethod: formState.inputs.paymentMethod.value,
          paymentType: formState.inputs.paymentType.value,
          amount: formState.inputs.amount.value,
          bank: formState.inputs.bank.value,
          receiptNo: formState.inputs.receiptNo.value,
          official: formState.inputs.official.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      setLoadedItem(responseData[ItemType]);
      setItemState(Date.now());
      store.RetrieveReceipts();

      // history.push(`/${ItemEndPointType}`);
    } catch (err) {}
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      {!isLoading && loadedItem && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" danger onClick={props.onClose}>
              &#60; Geri
            </Button>
          </div>

          <CustomSelect
            options={store.schoolOptions}
            id="school"
            onInput={inputHandler}
            label="Okul*"
            initialValue={store.schoolOptions.find(
              (option) => option.value.toString() === loadedItem.school.toString()
            )}
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
          ></CustomSelect>

          <DatePicker
            id="date"
            onInput={inputHandler}
            label="Fatura Tarihi"
            initialValid={true}
            errorText="Gider tarihini giriniz."
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={loadedItem.date.toString()}
          ></DatePicker>

          <CustomSelect
            options={expenseTypes}
            id="type"
            onInput={inputHandler}
            label="Gider Türü"
            errorText="Gider türünü giriniz."
            initialValid={true}
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={expenseTypes.find((expense) => expense.value.toString() === loadedItem.type.toString())}
          ></CustomSelect>

          <Input
            id="description"
            element="input"
            type="text"
            label="Açıklama"
            validators={[VALIDATOR_NONE()]}
            errorText="Açıklama giriniz."
            initialValue={loadedItem.description}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="vehicle"
            element="input"
            type="text"
            label="Araç plakası (Araca yönelik bir ödeme ise)"
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem.vehicle}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="person"
            element="input"
            type="text"
            label="Kurum-Kişi Adı"
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem.vehicle}
            initialValid={true}
            onInput={inputHandler}
          />

          <CustomSelect
            options={paymentMethods}
            id="paymentMethod"
            onInput={inputHandler}
            label="Ödeme Yöntemi"
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
            initialValue={paymentMethods.find(
              (paymentMethod) => paymentMethod.value.toString() === loadedItem.paymentMethod.toString()
            )}
          ></CustomSelect>

          <CustomSelect
            options={paymentTypes}
            id="paymentType"
            onInput={inputHandler}
            label="Ödeme Şekli"
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
            initialValue={paymentTypes.find(
              (paymentType) => paymentType.value.toString() === loadedItem.paymentType.toString()
            )}
          ></CustomSelect>

          <CustomSelect
            options={banks}
            id="bank"
            onInput={inputHandler}
            label="Ödemeyi hanga banka hesabınızdan yaptınız. (Banka ödemesi)"
            validators={[VALIDATOR_NONE()]}
            initialValid={true}
            initialValue={banks.find((bank) => bank.value.toString() === loadedItem.bank.toString())}
          ></CustomSelect>

          <Input
            id="receiptNo"
            element="input"
            type="text"
            label="Fatura Numarası"
            validators={[VALIDATOR_NONE()]}
            errorText="Gider karşılık alınan faturasını giriniz."
            initialValue={loadedItem.receiptNo}
            initialValid={true}
            onInput={inputHandler}
          />

          <CustomSelect
            options={isOfficial}
            id="official"
            onInput={inputHandler}
            label="Fatura işleme yazılımına işlensin mi?"
            initialValid={true}
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={isOfficial.find((item) => item.value.toString() === loadedItem.official.toString())}
          ></CustomSelect>

          <Input
            id="amount"
            element="input"
            type="text"
            label="Ödeme Tutarı"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Ödeme tutarını giriniz."
            initialValue={loadedItem.amount}
            initialValid={true}
            onInput={inputHandler}
          />

          <div className="button-container">
            <Button type="submit" disabled={!formState.isValid}>
              Güncelle
            </Button>

            <Button type="button" danger onClick={showDeleteWarningHandler}>
              SİL
            </Button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default ReceiptItem;
