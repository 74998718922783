import { useBackendContext } from "./backend-hook";

export const usePersonelServiceBackend = () => {
  const { sendRequest, t } = useBackendContext();

  const updatePersonelService = async (serviceId, infoToBeSent) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/personelservices/${serviceId}`,
        "PATCH",
        JSON.stringify({ ...infoToBeSent })
      );
      return responseData.personelservice || null;
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
    return null;
  };

  return { updatePersonelService };
};
