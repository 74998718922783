import React, { useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

const List = ({ name, items, columns, onItemSelect }) => {
  const visibilityJSON = localStorage.getItem(`${name}_visibility`);
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);

  const filterJSON = localStorage.getItem(`${name}_filter`);
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);

  // Need version 7
  // const [density, setDensity] = useState(localStorage.getItem(`${name}_density`) || "compact");

  const initPageSize = parseInt(localStorage.getItem(`${name}_pageSize` || 100)) || 100;
  const [pageSize, setPageSize] = useState(initPageSize);

  const OnSelectionChangeHandler = (event) => {
    onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: "100%", flex: 1 }}>
      <DataGrid
        sx={{ borderRadius: 0 }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          localStorage.setItem(`${name}_visibility`, JSON.stringify(newModel));
          setColumnVisibilityModel(newModel);
        }}
        filterModel={filterModel}
        onFilterModelChange={(newModel) => {
          localStorage.setItem(`${name}_filter`, JSON.stringify(newModel));
          setFilterModel(newModel);
        }}
        density="compact"
        // density={density}
        // onDensityChange={(newDensity) => {
        //   localStorage.setItem(`${name}_density`, newDensity);
        //   setDensity(newDensity);
        // }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => {
          localStorage.setItem(`${name}_pageSize`, newPageSize);
          setPageSize(newPageSize);
        }}
        key={items}
        components={{
          Toolbar: CustomToolbar,
        }}
        rows={items}
        columns={columns}
        // pageSize={100}

        getRowId={(r) => r.id}
        onSelectionModelChange={OnSelectionChangeHandler}
      />
    </div>
  );
};

export default List;
