import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export const useBackendContext = () => {
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  return { sendRequest, auth, t };
};
