import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import "css/index.css";
import "css/flex.css";
import "css/card.css";
import "components/Item.css";
import "components/List.css";
import App from "./App";
import "./i18n";

const root = createRoot(document.getElementById("root"));

root.render(<BrowserRouter>{<App />}</BrowserRouter>);
