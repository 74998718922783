import React from "react";
import { useTranslation } from "react-i18next";
import { Oval } from "react-loader-spinner";
import Button from "shared/components/FormElements/Button";

import "./FormFooter.css";

const FormFooter = ({ onUpdate, onDelete, isUpdating, disabled, isNewItem = false }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="footer-container">
        <div className="update-container">
          <Button type="button" disabled={disabled} onClick={onUpdate}>
            {isNewItem ? t("submit") : t("update")}
          </Button>

          {isUpdating && (
            <Oval
              visible={true}
              height="20"
              width="20"
              color="#830000"
              secondaryColor="#830000"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
        </div>

        {!isNewItem && (
          <Button type="button" size="small" danger onClick={onDelete}>
            {t("delete")}
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

export default FormFooter;
