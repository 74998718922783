import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import NotFound from "shared/components/UIElements/NotFound";
import Marker from "shared/components/UIElements/Marker";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";
import DeleteModal from "components/shared/DeleteModal";
import MapSinglePoint from "components/shared/MapSinglePoint";

// #endregion Components

// #region Utils
import { LocationFromAddress, AddressFromLocation } from "shared/util/location";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const StopItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  //standard code
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // context
  const { schoolOptions, serviceType, stops } = useContext(StoreContext);

  // item info
  const itemType = "stop";
  const endPointUpdate = "stops";
  const endPointDelete = "stops";
  const endPointCreate = "stops";

  const [markerLocation, setMarkerLocation] = useState();
  const [stopAddress, setStopAddress] = useState();
  const [addressInSync, setAddressInSync] = useState(false);

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: true },
      address: { value: "", isValid: true },
      school: { value: "", isValid: true },
    },
    true
  );

  // item special functions
  const mapDataChangeHandler = (address, location, inSync) => {
    setMarkerLocation(location);
    setStopAddress(address);
    setAddressInSync(inSync);
    inputHandler("address", address, true);
  };

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      setIsItemLoaded(false);

      const currentStop = stops.find((stop) => stop.id === itemId);
      setLoadedItem(currentStop);

      if (currentStop?.location && currentStop?.location.lat && currentStop?.location.lng) {
        setMarkerLocation(currentStop?.location);
        setAddressInSync(true);
      } else {
        setMarkerLocation(null);
        setAddressInSync(false);
      }

      setStopAddress(currentStop?.address);
    } catch (err) {}
    setIsItemLoaded(true);
  }, [stops, itemId]);

  useEffect(() => {
    fetchItem();
  }, [fetchItem]);

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const infoToBeSent = {
        name: formState.inputs.name.value,
        address: stopAddress,
        school: formState.inputs.school.value,
      };

      if (markerLocation) {
        infoToBeSent.location = { lat: markerLocation?.lat || 1.1, lng: markerLocation?.lng || 1.1 };
      }

      const endpoint = isNewItem
        ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
        : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

      const postType = isNewItem ? "POST" : "PATCH";

      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };

  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <CustomSelect
            options={schoolOptions}
            id="school"
            onInput={inputHandler}
            label={t("client")}
            initialValue={schoolOptions.find((option) => option.value === loadedItem?.school.toString())}
            initialValid={true}
          ></CustomSelect>

          <Input
            id="name"
            element="input"
            type="text"
            label={t("name")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("name") })}
            initialValue={loadedItem?.name || ""}
            initialValid={true}
            onInput={inputHandler}
          />

          <MapSinglePoint
            id="stopItemMap"
            label={formState.inputs.name.value}
            initialAddress={stopAddress || ""}
            initialAddressInSync={addressInSync}
            initialLocation={markerLocation}
            onInput={inputHandler}
            onMapDataChange={mapDataChangeHandler}
          ></MapSinglePoint>
          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={
              !formState.isValid ||
              !formState.inputs.school.value ||
              !formState.inputs.name.value ||
              !addressInSync ||
              isLoading
            }
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default StopItem;
