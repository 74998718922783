import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

import NotFound from "shared/components/UIElements/NotFound";
import MapSinglePoint from "components/shared/MapSinglePoint";
import FormFooter from "shared/components/FormElements/FormFooter";
import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_MAX, VALIDATOR_MIN, VALIDATOR_NONE } from "shared/util/validators";
import { schooltypes, qrcodetypes, yesNoOptions } from "shared/util/types";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const SchoolItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  //standard code
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // context
  const { schools } = useContext(StoreContext);

  // item info
  const itemType = "school";
  const endPointUpdate = "schools";
  const endPointDelete = "schools";
  const endPointCreate = "schools";

  // item special states
  const [markerLocation, setMarkerLocation] = useState();
  const [schoolAddress, setSchoolAddress] = useState();
  const [addressInSync, setAddressInSync] = useState(false);

  // item special functions
  const mapDataChangeHandler = (address, location, inSync) => {
    setMarkerLocation(location);
    setSchoolAddress(address);
    setAddressInSync(inSync);
    inputHandler("address", address, true);
  };

  // formstates
  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: true },
      type: { value: "", isValid: true },
      useqrcode: { value: "", isValid: true },
      phonenumber: { value: "", isValid: true },
      email: { value: "", isValid: true },
      tag: { value: "", isValid: true },
      address: { value: null, isValid: true },
      location: { value: null, isValid: true },
      distance: { value: null, isValid: true },
      usemagneticcard: { value: false, isValid: true },
    },
    true
  );

  // formstates fetch
  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    const currentSchool = schools.find((school) => school.id === itemId);
    setLoadedItem(currentSchool);

    if (currentSchool?.location && currentSchool?.location.lat && currentSchool?.location.lng) {
      setMarkerLocation(currentSchool?.location);
      setAddressInSync(true);
    } else {
      setMarkerLocation(null);
      setAddressInSync(false);
    }

    setSchoolAddress(currentSchool?.address);

    setIsItemLoaded(true);
  }, [itemId, schools]);

  useEffect(() => {
    fetchItem();
  }, [fetchItem]);

  // item update
  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const infoToBeSent = {
        name: formState.inputs.name.value,
        tag: formState.inputs.tag.value,
        type: formState.inputs.type.value,
        useqrcode: formState.inputs.useqrcode.value,
        phonenumber: formState.inputs.phonenumber.value,
        email: formState.inputs.email.value,
        distance: formState.inputs.distance.value,
        usemagneticcard: formState.inputs.usemagneticcard.value,
        address: schoolAddress,
      };

      if (markerLocation) {
        infoToBeSent.location = { lat: markerLocation?.lat || 1.1, lng: markerLocation?.lng || 1.1 };
      }

      const endpoint = isNewItem
        ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
        : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

      const postType = isNewItem ? "POST" : "PATCH";

      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.message) {
        window.toast.error(err.message);
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };

  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType="schoolItem"
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <Input
            id="name"
            element="input"
            type="text"
            label={t("schoolOfficialName")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("name") })}
            initialValue={loadedItem?.name}
            initialValid={isNewItem ? false : true}
            onInput={inputHandler}
          />

          <Input
            id="tag"
            element="input"
            type="text"
            label={t("schoolShortName")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("schoolShortName") })}
            initialValue={loadedItem?.tag}
            initialValid={isNewItem ? false : Boolean(loadedItem?.tag)}
            onInput={inputHandler}
          />

          <CustomSelect
            options={schooltypes}
            id="type"
            onInput={inputHandler}
            label={t("schoolType")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("schoolType") })}
            initialValue={schooltypes.find((option) => option.value.toString() === loadedItem?.type.toString())}
            initialValid={isNewItem ? false : true}
          ></CustomSelect>

          <CustomSelect
            options={qrcodetypes}
            id="useqrcode"
            onInput={inputHandler}
            label={t("isQRCodeUsed")}
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={qrcodetypes.find((option) => option.value.toString() === loadedItem?.useqrcode.toString())}
          ></CustomSelect>

          <CustomSelect
            options={yesNoOptions}
            id="usemagneticcard"
            onInput={inputHandler}
            label={t("isMagneticCardsUsed")}
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={yesNoOptions.find(
              (option) => option.value.toString() === loadedItem?.usemagneticcard.toString()
            )}
            errorText="Zorunlu alan"
          ></CustomSelect>

          <Input
            id="email"
            element="input"
            type="text"
            label={t("email")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("email") })}
            initialValue={loadedItem?.email}
            initialValid={isNewItem ? false : true}
            onInput={inputHandler}
          />

          <MapSinglePoint
            id="userItemMap"
            label={formState.inputs.name.value}
            initialAddress={schoolAddress || ""}
            initialAddressInSync={addressInSync}
            initialLocation={markerLocation}
            onInput={inputHandler}
            onMapDataChange={mapDataChangeHandler}
          ></MapSinglePoint>

          <Input
            id="phonenumber"
            element="input"
            type="text"
            label="Telefon"
            validators={[VALIDATOR_NONE()]}
            //errorText="Telefon giriniz."
            initialValue={loadedItem?.phonenumber || ""}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="distance"
            element="input"
            type="text"
            label={t("distanceToArriveSchool")}
            validators={[VALIDATOR_MAX(5), VALIDATOR_MIN(0.1)]}
            initialValue={loadedItem?.distance || 0.3}
            initialValid={true}
            onInput={inputHandler}
            errorText={t("distanceMinMaxToArrivceSchool")}
          />
          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={!formState.isValid || !addressInSync || isLoading}
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default SchoolItem;
