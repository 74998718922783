import { RemoveWhiteSpace } from "shared/util/string";

export function FixedPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return false;
  }
  let cleanedNumber = RemoveWhiteSpace(phoneNumber);
  const firstFiveIndex = cleanedNumber.indexOf("5");
  if (firstFiveIndex !== -1) {
    cleanedNumber = cleanedNumber.substring(firstFiveIndex);
  }
  if (cleanedNumber.length === 10 && cleanedNumber.startsWith("5")) {
    return cleanedNumber;
  } else {
    return false;
  }
}

export function ValidatePhoneNumber(phoneNumber) {
  const cleanedNumber = FixedPhoneNumber(phoneNumber);
  if (cleanedNumber) {
    return true;
  } else {
    return false;
  }
}
