import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { SERVICE_TYPES } from "shared/util/constants";
import { getColumnValue } from "shared/util/datagrid";
import "./Admin.css";

import UserItem from "components/user/UserItem";

const Users = () => {
  const { t } = useTranslation();
  const itemType = "user";
  const listName = "main_users";

  const { users, userTypes, schoolOptions, serviceType, standartPersonelServices, DeleteUser, InsertUser, UpdateUser } =
    useContext(StoreContext);

  const pageItems = users.filter((item) => userTypes.some((userType) => userType.value === item.usertype));

  let listColumns = [];

  if (schoolOptions?.length > 1) {
    listColumns.push(getColumnValue("school", t("client"), schoolOptions, "school", 300));
  }

  //listColumns.push(getColumnValue("usertype", t("userType"), userTypes, "usertype", 150));
  listColumns.push({ field: "name", headerName: t("name"), width: 200 });

  listColumns.push({ field: "phonenumber", headerName: t("phone"), width: 200 });

  //listColumns.push({ field: "email", headerName: t("email"), width: 250 });

  if (serviceType === SERVICE_TYPES.PERSONEL) {
    listColumns.push({
      field: "getonservice",
      headerName: t("pickUpService"),
      width: 300,
      valueGetter: (params) => {
        try {
          return (
            standartPersonelServices.find(
              (service) => service.type === "100" && service?.users.map((user) => user.id).includes(params.row.id)
            )?.name || "-"
          );
        } catch {
          return "-";
        }
      },
    });
    listColumns.push({
      field: "getoffservice",
      headerName: t("dropOffService"),
      width: 300,
      valueGetter: (params) => {
        try {
          return (
            standartPersonelServices.find(
              (service) => service.type === "200" && service?.users.map((user) => user.id).includes(params.row.id)
            )?.name || "-"
          );
        } catch {
          return "-";
        }
      },
    });
  }

  if (serviceType === SERVICE_TYPES.PERSONEL) {
    listColumns.push({
      field: "isshift",
      headerName: t("isshift"),
      width: 200,
      valueGetter: (params) => (params?.row?.isshift ? t("yes") : t("no")),
    });
  }

  listColumns.push({ field: "address", headerName: t("address"), width: 400 });
  // listColumns.push({ field: "identity", headerName: "Kimlik", width: 150 });
  // if (serviceType === SERVICE_TYPES.PERSONEL) {
  //   listColumns.push({ field: "cardId", headerName: t("cardId"), width: 150 });
  // }

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={UserItem}
      NewItemComponent={UserItem}
      DeleteItem={DeleteUser}
      InsertItem={InsertUser}
      UpdateItem={UpdateUser}
    />
  );
};

export default Users;
