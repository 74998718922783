import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { SERVICE_TYPES } from "shared/util/constants";
import { TRAVEL_STATUS_TYPE_EXPLANATIONS } from "shared/util/types";
import { vehicleroutetypes } from "shared/util/types";
import { getColumnValue } from "shared/util/datagrid";
import "./Admin.css";

import LiveItem from "components/live/LiveItem";

const Live = () => {
  const { t } = useTranslation();
  const itemType = "vehicleRoute";
  const listName = "main_live";

  var today = new Date();
  var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const {
    vehicleRoutes,
    userTypes,
    schoolOptions,
    serviceType,
    standartPersonelServices,
    DeleteUser,
    InsertUser,
    UpdateUser,
  } = useContext(StoreContext);

  const pageItems = vehicleRoutes;

  let listColumns = [];

  if (schoolOptions?.length > 1) {
    listColumns.push({
      field: "school",
      headerName: t("client"),
      width: 350,
      valueGetter: (params) => schoolOptions.find((school) => school.value === params.row?.school?.id)?.label || "-",
    });
  }
  listColumns.push({ field: "name", headerName: t("name"), width: 350 });
  listColumns.push({
    field: "type",
    headerName: "Türü",
    width: 200,
    valueGetter: (params) => vehicleroutetypes.find((item) => item.value === params.row?.type)?.label || "-",
  });
  listColumns.push({
    field: "driver",
    headerName: t("driver"),
    width: 300,
    valueGetter: (params) =>
      Array.isArray(params.row?.driver) && params.row.driver.length > 0
        ? params.row.driver.map((driver) => driver.name).join(", ")
        : "-",
  });
  listColumns.push({ field: "plate", headerName: t("plate"), width: 200 });
  listColumns.push({
    field: "status",
    headerName: date + " için durum",
    width: 200,
    // valueGetter: (params) =>
    // TRAVEL_STATUS_TYPE_EXPLANATIONS.find((exp) => exp.value === params.row.status).label,

    renderCell: (params) => {
      return (
        <>
          <div className="travelStatusLiveIndicator">
            {TRAVEL_STATUS_TYPE_EXPLANATIONS.find((exp) => exp.value === params.row.status)?.label}
          </div>
        </>
      );
    },
  });

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={LiveItem}
      isAlwaysFullScreen={true}
      hideCreateNewItem={true}
    />
  );
};

export default Live;
