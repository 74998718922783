import React, { useState } from "react";
import styled from "styled-components";
import PlayArrow from "@mui/icons-material/PlayArrow";
import Pause from "@mui/icons-material/Pause";

import Slider2 from "./slider2";
import Button from "./playerButton";

import { PlayerStates } from "../../util/types";

const PlayerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: right;
`;

const Player = (props) => {
  //const [value, setValue] = React.useState(props.value);
  const [playerState, setPlayerState] = useState(PlayerStates.NOTSTARTED);

  const togglePlay = (currentState) => {
    if (currentState === PlayerStates.NOTSTARTED || currentState === PlayerStates.PAUSED) {
      setPlayerState(PlayerStates.PLAYING);
      props.onPlayerStateChange(PlayerStates.PLAYING);
    } else {
      setPlayerState(PlayerStates.PAUSED);
      props.onPlayerStateChange(PlayerStates.PAUSED);
    }
  };

  const IndexChangeHandler = (newIndex) => {
    props.onPlayerIndexChange(newIndex);
  };

  return (
    <PlayerContainer>
      <Button type="button" size="xxsmall" onClick={() => togglePlay(playerState)}>
        {playerState === PlayerStates.PLAYING ? <Pause /> : <PlayArrow />}
      </Button>
      <div style={{ marginRight: "20px" }}></div>

      <Slider2
        onSliderValueChange={IndexChangeHandler}
        style={{ flex: 20 }}
        min={0}
        max={props.max}
        value={props.value}
        text={props.text}
      />
    </PlayerContainer>
  );
};

export default Player;
