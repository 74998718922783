import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { StoreContext } from "shared/context/store-context";

import ItemHeader from "pages/shared/ItemHeader";
import { days, days_en } from "shared/util/types";

const ShiftInfo = ({ shift, date, onClose }) => {
  const { t } = useTranslation();
  const language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);

  const { schoolOptions } = useContext(StoreContext);

  return (
    <React.Fragment>
      {shift && (
        <div className="card horizontal-flex align-items-center justify-content-between" style={{ width: "100%" }}>
          <div className="horizontal-flex align-items-center justify-content-start" style={{ gap: "40px", flex: 10 }}>
            <h4 style={{ margin: "0px" }}>
              {schoolOptions.find((option) => option.value === shift.school.toString()).label} Vardiyası
            </h4>
            <span>
              <strong>Tarih:</strong> {date}
            </span>
            <span>
              <strong>Gün:</strong> {days.find((day) => day.value.toString() === shift.day.toString()).label}
            </span>
            <span>
              <strong>Vardiya:</strong> {shift.name} ({shift.description})
            </span>

            <span>
              <strong>Vardiya Girişi Servis Varış:</strong> {shift.serviceArrivalTime}
            </span>
            <span>
              <strong>Vardiya Çıkışı Servis Kalkış:</strong> {shift.serviceLeaveTime}
            </span>
          </div>
          <div style={{ width: "100%", justifyContent: "flex-end", display: "flex", flex: 1 }}>
            <ItemHeader isAlwaysFullScreen={true} onClose={onClose} onFullScreen={() => {}} onHalfScreen={() => {}} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ShiftInfo;
