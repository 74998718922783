import React, { useEffect, useState, useContext, useCallback } from "react";

import { StoreContext } from "shared/context/store-context";

import NotFound from "shared/components/UIElements/NotFound";

import ShiftAnalyzer from "components/service-analyzer/ShiftAnalyzer";
import ShiftInfo from "./ShiftInfo";

import { getShiftDate } from "shared/util/util";

const ManagedShift = ({ itemId, onClose }) => {
  const { shiftOptions, shifts, shiftPersonelServices, schools } = useContext(StoreContext);

  // #region states
  const [currentShiftServicesGETON, setCurrentShiftServicesGETON] = useState([]);
  const [currentShiftServicesGETOFF, setCurrentShiftServicesGETOFF] = useState([]);

  const [currentShiftServicesGETON_Options, setCurrentShiftServicesGETON_Options] = useState([]);
  const [currentShiftServicesGETOFF_Options, setCurrentShiftServicesGETOFF_Options] = useState([]);

  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [currentShift, setCurrentShift] = useState();
  const [school, setSchool] = useState();

  const [shiftDate, setShiftDate] = useState(getShiftDate("---"));
  const [shiftDisplayName, setShiftDisplayName] = useState("---");

  const ItemTypeTR = "Vardiya";

  const fetchItem = useCallback(async () => {
    try {
      const currentShift = shifts.find((shift) => shift.id === itemId);
      if (currentShift) {
        setCurrentShift(currentShift);
        setShiftDate(getShiftDate(currentShift));
        setShiftDisplayName(shiftOptions.find((option) => option.value === currentShift.id).label);
        setSchool(schools.find((school) => school.id === currentShift.school));

        const extractedServicesGETON = shiftPersonelServices.filter(
          (service) => service.shift === itemId && service.type === "100"
        );
        const extractedServicesGETOFF = shiftPersonelServices.filter(
          (service) => service.shift === itemId && service.type === "200"
        );

        setCurrentShiftServicesGETON(extractedServicesGETON);
        setCurrentShiftServicesGETOFF(extractedServicesGETOFF);

        setCurrentShiftServicesGETON_Options(
          extractedServicesGETON.map((service) => {
            return {
              value: service.id,
              label: service.name,
            };
          }) || []
        );

        setCurrentShiftServicesGETOFF_Options(
          extractedServicesGETOFF.map((service) => {
            return {
              value: service.id,
              label: service.name,
            };
          }) || []
        );
      }
    } catch (err) {
      console.log(err);
    }
    setIsItemLoaded(true);
  }, [itemId, shiftOptions, schools, shiftPersonelServices, shifts]);

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  if (!isItemLoaded) {
    return <div></div>;
  }

  if (!currentShift) {
    return <NotFound item={ItemTypeTR}></NotFound>;
  }

  return (
    <React.Fragment>
      {currentShift && (
        <div>
          <ShiftInfo shift={currentShift} date={shiftDate} name={shiftDisplayName} onClose={onClose} />
          <ShiftAnalyzer
            school={school}
            shift={currentShift}
            shiftDate={shiftDate}
            shiftDisplayName={shiftDisplayName}
            services={currentShiftServicesGETON}
            // dropOffServices={currentShiftServicesGETOFF}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ManagedShift;
