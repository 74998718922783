import { useState, useCallback, useEffect, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { trTR, enUS } from "@mui/x-data-grid";
import { trTR as coretrTR, enUS as coreenUS } from "@mui/material/locale";
import { useHttpClient } from "shared/hooks/http-hook";
import { useTranslation } from "react-i18next";
import { PERMISSION_TYPES } from "shared/util/constants";

// import { redirect } from "react-router-dom";

let logoutTimer;

export const useAuth = () => {
  // login related
  const { t, i18n } = useTranslation();

  const [token, setToken] = useState();
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [id, setId] = useState(false);

  // client related
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [serviceType, setServiceType] = useState();
  const [type, setType] = useState();
  const [schools, setSchools] = useState();
  const [company, setCompany] = useState();
  const [permissions, setPermissions] = useState();

  // theme related
  const [theme, setTheme] = useState();

  // state related
  const [loaded, setLoaded] = useState(false);

  const { sendRequest } = useHttpClient();

  const resetUser = () => {
    setToken(null);
    setTokenExpirationDate(null);
    setId(null);

    setName(null);
    setType(null);
    setEmail(null);
    setSchools(null);
    setCompany(null);

    setLoaded(false);
    localStorage.removeItem("clientData");
  };

  const getClientInfo = useCallback(
    async (clientId, token) => {
      if (clientId) {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/clients/${clientId}?populate=1`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );

          setName(responseData.client.name);
          setSchools(responseData.client?.schools);
          setCompany(responseData.client?.company);
          setType(responseData.client?.type);
          setEmail(responseData.client?.email);
          setServiceType(responseData.client?.serviceType);

          const permissions = {};
          Object.values(PERMISSION_TYPES).forEach((key) => {
            permissions[key] = responseData.client?.permissions?.[key] || false;
          });

          setPermissions(permissions);
          // setPermissions({
          //   [PERMISSION_TYPES.ACCESS_TO_USERS]:
          //     responseData.client?.permissions[PERMISSION_TYPES.ACCESS_TO_USERS] || false,
          //   [PERMISSION_TYPES.ACCESS_TO_ALL_DRIVERS_IN_THE_COMPANY]: responseData.client?.permissions[PERMISSION_TYPES.ACCESS_TO_ALL_DRIVERS_IN_THE_COMPANY] || false,
          //   [PERMISSION_TYPES.ACCESS_TO_ACCOUNTING]: responseData.client?.permissions[PERMISSION_TYPES.ACCESS_TO_ACCOUNTING] || false,
          //   [PERMISSION_TYPES.ACCESS_TO_TRANSFERS]: responseData.client?.permissions[PERMISSION_TYPES.ACCESS_TO_TRANSFERS] || false,
          //   accessToDrivers: responseData.client?.permissions?.accessToDrivers || false,
          //   accessToIkarus: responseData.client?.permissions?.accessToIkarus || false,
          //   accessToAnalysis: responseData.client?.permissions?.accessToAnalysis || false,
          //   accessToLive: responseData.client?.permissions?.accessToLive || false,
          // });

          return true;
        } catch (err) {}
      }
      return false;
    },
    [sendRequest]
  );

  const login = useCallback(
    async (clientId, token, expirationDate = null) => {
      try {
        const clientInfoLoaded = await getClientInfo(clientId, token);
        if (!clientInfoLoaded) {
          setLoaded(true);
          return;
        }

        // store token in local storage
        const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365);

        // login related
        setToken(token);
        setId(clientId);
        setTokenExpirationDate(tokenExpirationDate);

        localStorage.setItem(
          "clientData",
          JSON.stringify({
            clientId,
            token,
            expiration: tokenExpirationDate.toISOString(),
          })
        );
      } catch (err) {
        console.log(err);
      }

      setLoaded(true);
    },
    [getClientInfo]
  );

  const logout = useCallback(() => {
    resetUser();
    window.location.href = "/login";
    // redirect("/login");
  }, []);

  const UpdateTheme = useCallback((theme = "en") => {
    setTheme(
      createTheme(
        {
          palette: {
            primary: { main: "#1976d2" },
            secondary: { main: "#dc004e" },
          },
        },
        // Dil desteği eklendiğinde burayı değiştir
        // theme === "tr" ? trTR : enUS,
        // theme === "tr" ? coretrTR : coreenUS,
        //Simdilik TR
        trTR, //Burayı değiştir
        coreenUS, //Burayı değiştir
        {
          GridCsvExportOptions: { fileName: "gh" },
        }
      )
    );
  }, []);

  // auto logout
  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  // auto login
  useEffect(() => {
    let storedLang = localStorage.getItem("i18nextLng");

    if (!storedLang || storedLang === "undefined") {
      storedLang = i18n.resolvedLanguage || "en";
      localStorage.setItem("i18nextLng", storedLang);
      i18n.changeLanguage(storedLang);
    }
    UpdateTheme(storedLang);

    const storedData = JSON.parse(localStorage.getItem("clientData"));
    if (storedData && storedData?.token && new Date(storedData?.expiration) > new Date()) {
      login(storedData.clientId, storedData.token, new Date(storedData.expiration));
    } else {
      setLoaded(true);
    }
  }, [UpdateTheme, i18n, login]);

  const authContextValue = useMemo(
    () => ({
      id,
      token,
      tokenExpirationDate,
      name,
      email,
      serviceType,
      permissions,
      schools,
      company,
      type,
      theme,
      loaded,
      login,
      logout,
      UpdateTheme,
    }),
    [
      id,
      token,
      tokenExpirationDate,
      name,
      email,
      serviceType,
      permissions,
      schools,
      company,
      type,
      theme,
      loaded,
      login,
      logout,
      UpdateTheme,
    ]
  );

  return authContextValue;
};
