import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { useContext } from "react";
import { FixedPhoneNumber } from "shared/util/validate";

export const useBackend = () => {
  const { sendRequest } = useHttpClient();
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const getLocationFromAddress = useCallback(
    async (address) => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/checkaddress/get-coords`,
          "POST",
          JSON.stringify({ address })
        );

        return responseData;
      } catch {}
      return null;
    },
    [sendRequest]
  );

  const getAddressFromLocation = useCallback(
    async (location) => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/checkaddress/get-address`,
          "POST",
          JSON.stringify({ location })
        );

        return responseData;
      } catch {}
      return null;
    },
    [sendRequest]
  );

  const sendSMS = async (phonenumber, message, userId = null, studentId = null, vehicleRouteId = null) => {
    const APIEndPoint = "xrytutdvsdfds";

    const phone = FixedPhoneNumber(phonenumber);
    const text = message.trim();
    if (!phone || !text) {
      window.toast.error(t("actionFailed"));
      return;
    }

    const smsData = {
      user: userId || undefined,
      student: studentId || undefined,
      vehicleRoute: vehicleRouteId || undefined,
      phone,
      text,
    };

    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`, "POST", JSON.stringify(smsData), {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      });

      window.toast.success(t("actionSuccess"));
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };

  const getSMSByVehicleRoute = useCallback(
    async (vehicleRouteId) => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/xrytutdvsdfds/${vehicleRouteId}`,
          "POST"
        );
        return responseData?.smses || [];
      } catch (err) {
        return [];
      }
    },
    [sendRequest]
  );

  return { getLocationFromAddress, getAddressFromLocation, sendSMS, getSMSByVehicleRoute };
};
