/*eslint-disable eqeqeq*/
import { useState, useCallback, useRef } from "react";
import { useHttpClient } from "shared/hooks/http-hook";
import { days } from "shared/util/types";
import i18next from "i18next";
import { set } from "lodash";

export const useStore = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { sendRequest } = useHttpClient();
  const [users, setUsers] = useState([]);
  const [studentToParentMap, setStudentToParentMap] = useState({});
  const [parentToStudentMap, setParentToStudentMap] = useState({});
  const [userMap, setUserMap] = useState({});
  const [clientTypes, setClientTypes] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [driverOptions, setDriverOptions] = useState();
  const [schools, setSchools] = useState();
  const [schoolOptions, setSchoolOptions] = useState();
  const [companies, setCompanies] = useState();
  const [companyOptions, setCompanyOptions] = useState();
  const [vehicleRoutes, setVehicleRoutes] = useState();
  const [vehicleRouteOptions, setVehicleRouteOptions] = useState();
  const [vehicles, setVehicles] = useState();
  const [students, setStudents] = useState();
  const [relations, setRelations] = useState();
  const [studentOptions, setStudentOptions] = useState();
  const [parentOptions, setParentOptions] = useState();
  const [personelOptions, setPersonelOptions] = useState();
  const [storeReady, setStoreReady] = useState();
  const [educationYears, setEducationYears] = useState();
  const [discounts, setDiscounts] = useState();
  const [serviceTemplates, setServiceTemplates] = useState();
  const [educationYearsOptions, setEducationYearsOptions] = useState();
  const [selectedEducationYearId, setSelectedEducationYearId] = useState();
  const [discountOptions, setDiscountOptions] = useState();
  const [serviceTemplateOptions, setServiceTemplateOptions] = useState();
  const [receipts, setReceipts] = useState();
  const [withdraws, setWithdraws] = useState();
  const [transfers, setTransfers] = useState();
  const [contractsOptions, setContractsOptions] = useState();
  const [expenses, setExpenses] = useState();
  const [vehiclePayments, setVehiclePayments] = useState();
  const [pLocations, setPLocations] = useState();
  const [pLocationsOptions, setPLocationsOptions] = useState();
  const [transferregions, setTransferRegions] = useState();
  const [transferregionOptions, setTransferRegionOptions] = useState();
  const [ikarusTransfers, setIkarusTransfers] = useState();
  const [ikarusTransferRegions, setIkarusTransferRegions] = useState();
  const [ikarusTransferRegionOptions, setIkarusTransferRegionOptions] = useState();
  const [ikarusTransferPassengers, setIkarusTransferPassengers] = useState();
  const [ikarusTransferPassengerOptions, setIkarusTransferPassengerOptions] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [shifts, setShifts] = useState();
  const [stops, setStops] = useState();
  const [stopOptions, setStopOptions] = useState();
  const [standartPersonelServices, setStandartPersonelServices] = useState([]);
  const [standartPersonelServiceOptions, setStandartPersonelServiceOptions] = useState([]);
  const [shiftPersonelServices, setShiftPersonelServices] = useState([]);
  const [shiftPersonelServiceOptions, setShiftPersonelServiceOptions] = useState([]);
  const [userOptions, setUserOptions] = useState();
  const [shiftOptions, setShiftOptions] = useState();
  const [shiftUsers, setShiftUsers] = useState();
  const [shiftUserOptions, setShiftUserOptions] = useState();

  //Accounting
  const [contracts, setContracts] = useState([]);

  const schoolsREF = useRef([]);

  const UpdateStudents = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/students`);

      let allStudents = responseData.students;
      setStudents(allStudents);

      const studentoptions = allStudents.map((student) => {
        return {
          value: student.id,
          label: student.name + " - " + student.identifier,
        };
      });
      setStudentOptions(studentoptions);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const DeleteStudent = (studentId) => {
    const newStudents = students.filter((student) => student.id !== studentId);
    setStudents([...newStudents]);
    setStudentOptions(studentOptions.filter((item) => item.value !== studentId));
  };

  const InsertStudent = (student) => {
    setStudents([student, ...students]);
    setStudentOptions([...studentOptions, { value: student.id, label: student.name + " - " + student.identifier }]);
  };

  const UpdateStudent = (student) => {
    const index = students.findIndex((x) => x.id === student.id);
    const newStudents = [...students];
    newStudents[index] = student;
    setStudents([...newStudents]);
    setStudentOptions(
      studentOptions.map((item) => {
        if (item.value === student.id) {
          return { value: student.id, label: student.name + " - " + student.identifier };
        }
        return item;
      })
    );
  };

  const DeleteDriver = (driverId) => {
    const newDrivers = drivers.filter((driver) => driver.id !== driverId);
    setDrivers([...newDrivers]);
    setDriverOptions(driverOptions.filter((item) => item.value !== driverId));
  };

  const InsertDriver = (driver) => {
    setDrivers([driver, ...drivers]);
    setDriverOptions([...driverOptions, { value: driver.id, label: driver.name }]);
  };

  const UpdateDriver = (driver) => {
    const index = drivers.findIndex((x) => x.id === driver.id);
    const newDrivers = [...drivers];
    newDrivers[index] = driver;
    setDrivers([...newDrivers]);
    setDriverOptions(
      driverOptions.map((item) => {
        if (item.value === driver.id) {
          return { value: driver.id, label: driver.name };
        }
        return item;
      })
    );
  };

  const createRelationMaps = (relations) =>
    relations.reduce(
      (acc, relation) => {
        if (relation?.student && relation?.parent) {
          // Initialize student-to-parent map entry if not present
          if (!acc.studentToParent[relation.student.toString()]) {
            acc.studentToParent[relation.student.toString()] = [];
          }
          acc.studentToParent[relation.student.toString()].push(relation.parent.toString());

          // Initialize parent-to-student map entry if not present
          if (!acc.parentToStudent[relation.parent.toString()]) {
            acc.parentToStudent[relation.parent.toString()] = [];
          }
          acc.parentToStudent[relation.parent.toString()].push(relation.student.toString());
        }
        return acc;
      },
      { studentToParent: {}, parentToStudent: {} }
    );

  const UpdateRelations = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/relations`);
      let allRelations = responseData.relations;
      setRelations(allRelations);

      const { studentToParent, parentToStudent } = createRelationMaps(allRelations);
      setStudentToParentMap(studentToParent);
      setParentToStudentMap(parentToStudent);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const InsertRelation = (relation) => {
    setRelations([relation, ...relations]);

    setStudentToParentMap((prevMap) => ({
      ...prevMap,
      [relation.student.toString()]: [...(prevMap[relation.student.toString()] || []), relation.parent.toString()],
    }));

    setParentToStudentMap((prevMap) => ({
      ...prevMap,
      [relation.parent.toString()]: [...(prevMap[relation.parent.toString()] || []), relation.student.toString()],
    }));
  };

  const UpdateRelation = (relation) => {
    const index = relations.findIndex((x) => x.id === relation.id);
    const newRelations = [...relations];
    newRelations[index] = relation;
    setRelations([...newRelations]);

    let oldRelation = relations.filter((rel) => rel.id === relation.id);

    setStudentToParentMap((prevMap) => {
      const studentId = relation.student.toString();
      const parentId = relation.parent.toString();

      // Remove old parent if it has changed

      const updatedParents = prevMap[studentId]?.filter((id) => id !== oldRelation.parent.toString()) || [];
      if (!updatedParents.includes(parentId)) {
        updatedParents.push(parentId);
      }

      return { ...prevMap, [studentId]: updatedParents };
    });

    setParentToStudentMap((prevMap) => {
      const studentId = relation.student.toString();
      const parentId = relation.parent.toString();

      // Remove old student if it has changed
      const updatedStudents = prevMap[parentId]?.filter((id) => id !== oldRelation.student.toString()) || [];
      if (!updatedStudents.includes(studentId)) {
        updatedStudents.push(studentId);
      }

      return { ...prevMap, [parentId]: updatedStudents };
    });
  };

  const DeleteRelation = (relationId) => {
    const newRelations = relations.filter((relation) => relation.id !== relationId);
    setRelations([...newRelations]);

    const relationToDelete = relations.find((relation) => relation.id === relationId);
    if (relationToDelete) {
      setStudentToParentMap((prevMap) => {
        const updatedStudentParents = (prevMap[relationToDelete.student.toString()] || []).filter(
          (parentId) => parentId !== relationToDelete.parent.toString()
        );
        return { ...prevMap, [relationToDelete.student.toString()]: updatedStudentParents };
      });

      setParentToStudentMap((prevMap) => {
        const updatedParentStudents = (prevMap[relationToDelete.parent.toString()] || []).filter(
          (studentId) => studentId !== relationToDelete.student.toString()
        );
        return { ...prevMap, [relationToDelete.parent.toString()]: updatedParentStudents };
      });
    }
  };

  const InsertUser = (user) => {
    setUsers([user, ...users]);
    setUserOptions([...userOptions, { value: user.id, label: user.name }]);

    setUserMap((prevUserMap) => ({ ...prevUserMap, [user.id]: user }));

    if (user?.isshift) {
      setShiftUsers([...shiftUsers, user]);
      setShiftUserOptions([...shiftUserOptions, { value: user.id, label: user.name }]);
    }
  };

  const UpdateUser = (user) => {
    const index = users.findIndex((x) => x.id === user.id);
    const newUsers = [...users];
    newUsers[index] = user;
    setUsers([...newUsers]);

    setUserMap((prevUserMap) => ({ ...prevUserMap, [user.id]: user }));

    setUserOptions(
      userOptions.map((item) => {
        if (item.value === user.id) {
          return { value: user.id, label: user.name };
        }
        return item;
      })
    );

    if (user?.isshift) {
      const shiftUserIndex = shiftUsers.findIndex((x) => x.id === user.id);
      if (shiftUserIndex) {
        const newShiftUsers = [...shiftUsers];
        newShiftUsers[shiftUserIndex] = user;
        setShiftUsers([...newShiftUsers]);
      }
      setShiftUserOptions(
        shiftUserOptions.map((item) => {
          if (item.value === user.id) {
            return { value: user.id, label: user.name };
          }
          return item;
        })
      );
    }
  };

  const DeleteUser = (userId) => {
    const newUsers = users.filter((user) => user.id !== userId);
    setUsers([...newUsers]);

    // Remove user from userMap
    setUserMap((prevUserMap) => {
      const newUserMap = { ...prevUserMap };
      delete newUserMap[userId];
      return newUserMap;
    });

    setUserOptions(userOptions.filter((item) => item.value !== userId));

    if (shiftUsers.map((item) => item.id).includes(userId)) {
      setShiftUsers(shiftUsers.filter((user) => user.id !== userId));
      setShiftUserOptions(shiftUserOptions.filter((item) => item.value !== userId));
    }
  };

  const InsertShift = (shift) => {
    setShifts([shift, ...shifts]);
    setShiftOptions([
      ...shiftOptions,
      {
        value: shift.id,
        label: days.find((day) => day.value === shift.day).label + " - " + shift.name,
      },
    ]);
  };

  const UpdateShift = (shift) => {
    const index = shifts.findIndex((x) => x.id === shift.id);
    const newShifts = [...shifts];
    newShifts[index] = shift;
    setShifts([...newShifts]);
    setShiftOptions(
      shiftOptions.map((item) => {
        if (item.value === shift.id) {
          return {
            value: shift.id,
            label: days.find((day) => day.value === shift.day).label + " - " + shift.name,
          };
        }
        return item;
      })
    );
  };

  const DeleteShift = (shiftId) => {
    const newShifts = shifts.filter((shift) => shift.id !== shiftId);
    setShifts([...newShifts]);
    setShiftOptions(shiftOptions.filter((item) => item.value !== shiftId));
  };

  const InsertStop = (stop) => {
    setStops([stop, ...stops]);
    setStopOptions([...stopOptions, { value: stop.id, label: stop.name }]);
  };

  const UpdateStop = (stop) => {
    const index = stops.findIndex((x) => x.id === stop.id);
    const newStops = [...stops];
    newStops[index] = stop;
    setStops([...newStops]);
    setStopOptions(
      stopOptions.map((item) => {
        if (item.value === stop.id) {
          return { value: stop.id, label: stop.name };
        }
        return item;
      })
    );
  };

  const DeleteStop = (stopId) => {
    const newStops = stops.filter((stop) => stop.id !== stopId);
    setStops([...newStops]);
    setStopOptions(stopOptions.filter((item) => item.value !== stopId));
  };

  const UpdateUsers = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users`);

      let allUsers = responseData.users;

      const useroptions = allUsers.map((user) => {
        return { value: user.id, label: user.name };
      });
      setUserOptions(useroptions);

      setUsers(allUsers);
      setUserMap(
        allUsers.reduce((acc, user) => {
          acc[user.id] = user;
          return acc;
        }, {})
      );

      setShiftUsers(allUsers.filter((user) => user?.isshift));
      setShiftUserOptions(
        allUsers
          .filter((user) => user?.isshift)
          .map((user) => {
            return { value: user.id, label: user.name };
          })
      );

      const allparents = allUsers.filter((user) => user.usertype === "2");
      const allpersonels = allUsers.filter((user) => user.usertype === "10");

      const parentoptions = allparents.map((user) => {
        return {
          value: user.id,
          label: user.name + " - " + user.phonenumber,
        };
      });

      const personeloptions = allpersonels.map((user) => {
        return {
          value: user.id,
          label: user.name + " - " + user.phonenumber,
        };
      });
      setParentOptions(parentoptions);
      setPersonelOptions(personeloptions);
    } catch (err) {}

    return true;
  }, [sendRequest]);

  const UpdateDrivers = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/alldrivers`);
      const allDrivers = responseData.users;

      setDrivers(allDrivers);
      const driveroptions = allDrivers.map((user) => {
        return { value: user.id, label: user.name };
      });
      setDriverOptions(driveroptions);
    } catch (err) {}

    return true;
  }, [sendRequest]);

  const UpdateVehicles = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/vehicles`);
      setVehicles(responseData.vehicles);
    } catch (err) {}

    return true;
  }, [sendRequest]);

  const UpdateCompanies = useCallback(async () => {
    const fetchCompanies = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/companies`);

        setCompanies(responseData.companies);

        const companyoptions = responseData.companies.map((company) => {
          return { value: company.id, label: company.name };
        });
        setCompanyOptions(companyoptions);
      } catch (err) {}
    };
    await fetchCompanies();
  }, [sendRequest]);

  const UpdateSchools = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/schools`);
      let allSchools = responseData.schools;
      allSchools.sort((a, b) => a.name.localeCompare(b.name));
      schoolsREF.current = allSchools;
      setSchools(allSchools);

      const schooloptions = allSchools.map((schoolitem) => {
        return { value: schoolitem.id, label: schoolitem.name };
      });
      setSchoolOptions(schooloptions);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const InsertSchool = (school) => {
    setSchools([school, ...schools]);
    setSchoolOptions([...schoolOptions, { value: school.id, label: school.name }]);
  };

  const UpdateSchool = (school) => {
    const index = schools.findIndex((x) => x.id === school.id);
    const newSchools = [...schools];
    newSchools[index] = school;
    setSchools([...newSchools]);
    setSchoolOptions(
      schoolOptions.map((item) => {
        if (item.value === school.id) {
          return { value: school.id, label: school.name };
        }
        return item;
      })
    );
  };

  const DeleteSchool = (schoolId) => {
    const newSchools = schools.filter((school) => school.id !== schoolId);
    setSchools([...newSchools]);
    setSchoolOptions(schoolOptions.filter((item) => item.value !== schoolId));
  };

  const UpdateShifts = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/shifts`);

      setShifts(responseData.shifts);

      const shiftoptions = responseData.shifts.map((shiftitem) => {
        return {
          value: shiftitem.id,
          label: days.find((day) => day.value === shiftitem.day).label + " - " + shiftitem.name,
        };
      });
      setShiftOptions(shiftoptions);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const UpdateStops = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/stops`);
      setStops(responseData.stops);

      const stopoptions = responseData.stops.map((item) => {
        return { value: item.id, label: item.name };
      });
      setStopOptions(stopoptions);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const UpdatePersonelServices = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/personelservices`);

      setStandartPersonelServices(responseData.personelservices.filter((item) => !item?.isshift));
      setShiftPersonelServices(responseData.personelservices.filter((item) => item?.isshift));

      setStandartPersonelServiceOptions(
        responseData.personelservices
          .filter((item) => !item?.isshift)
          .map((item) => {
            return { value: item.id, label: item.name };
          })
      );
      setShiftPersonelServiceOptions(
        responseData.personelservices
          .filter((item) => item?.isshift)
          .map((item) => {
            return { value: item.id, label: item.name };
          })
      );
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const InsertPersonelService = (service) => {
    if (service?.isshift) {
      setShiftPersonelServices((prevServices) => [service, ...prevServices]);
      setShiftPersonelServiceOptions((prevOptions) => [...prevOptions, { value: service.id, label: service.name }]);
    } else {
      setStandartPersonelServices((prevServices) => [service, ...prevServices]);
      setStandartPersonelServiceOptions((prevOptions) => [...prevOptions, { value: service.id, label: service.name }]);
    }
  };

  const UpdatePersonelService = (service) => {
    if (service?.isshift) {
      const index = shiftPersonelServices.findIndex((x) => x.id === service.id);
      const newShiftPersonelServices = [...shiftPersonelServices];
      newShiftPersonelServices[index] = service;
      setShiftPersonelServices([...newShiftPersonelServices]);
      setShiftPersonelServiceOptions(
        shiftPersonelServiceOptions.map((item) => {
          if (item.value === service.id) {
            return { value: service.id, label: service.name };
          }
          return item;
        })
      );
    } else {
      const index = standartPersonelServices.findIndex((x) => x.id === service.id);
      const newStandartPersonelServices = [...standartPersonelServices];
      newStandartPersonelServices[index] = service;
      setStandartPersonelServices([...newStandartPersonelServices]);
      setStandartPersonelServiceOptions(
        standartPersonelServiceOptions.map((item) => {
          if (item.value === service.id) {
            return { value: service.id, label: service.name };
          }
          return item;
        })
      );
    }
  };

  const DeletePersonelService = (serviceId) => {
    if (shiftPersonelServices.map((item) => item.id).includes(serviceId)) {
      const newShiftPersonelServices = shiftPersonelServices.filter((service) => service.id !== serviceId);
      setShiftPersonelServices([...newShiftPersonelServices]);
      setShiftPersonelServiceOptions(shiftPersonelServiceOptions.filter((item) => item.value !== serviceId));
    }
    if (standartPersonelServices.map((item) => item.id).includes(serviceId)) {
      const newStandartPersonelServices = standartPersonelServices.filter((service) => service.id !== serviceId);
      setStandartPersonelServices([...newStandartPersonelServices]);
      setStandartPersonelServiceOptions(standartPersonelServiceOptions.filter((item) => item.value !== serviceId));
    }
  };

  const UpdatePLocations = useCallback(async () => {
    const fetchPLocations = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/plocations`);

        let allPLocations = responseData.pLocations;

        setPLocations(allPLocations);

        const plocationoptions = allPLocations.map((pLocationItem) => {
          return { value: pLocationItem.id, label: pLocationItem.name };
        });

        setPLocationsOptions(plocationoptions);
      } catch (err) {}
    };
    await fetchPLocations();
  }, [sendRequest]);

  const UpdateVehicleRoutes = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/vehicleroutes`);

      let allVehicleRoutes = responseData.vehicleRoutes;
      const vehiclerouteoptions = allVehicleRoutes
        .filter((route) => route.type == 100)
        .map((vehicleroute) => {
          return {
            value: vehicleroute.id,
            label: vehicleroute.school.name + " - " + vehicleroute.name,
          };
        });

      setVehicleRouteOptions(vehiclerouteoptions.sort((a, b) => a.label.localeCompare(b.label)));
      setVehicleRoutes(allVehicleRoutes);
    } catch (err) {
      console.log(err);
    }
    return true;
  }, [sendRequest]);

  const InsertVehicleRoute = (vehicleRoute) => {
    setVehicleRoutes([vehicleRoute, ...vehicleRoutes]);
    setVehicleRouteOptions([
      ...vehicleRouteOptions,
      {
        value: vehicleRoute.id,
        label: vehicleRoute.school.name + " - " + vehicleRoute.name,
      },
    ]);
  };

  const UpdateVehicleRoute = (vehicleRoute) => {
    const index = vehicleRoutes.findIndex((x) => x.id === vehicleRoute.id);
    const newVehicleRoutes = [...vehicleRoutes];
    newVehicleRoutes[index] = vehicleRoute;
    setVehicleRoutes([...newVehicleRoutes]);
    setVehicleRouteOptions(
      vehicleRouteOptions.map((item) => {
        if (item.value === vehicleRoute.id) {
          return {
            value: vehicleRoute.id,
            label: vehicleRoute.school.name + " - " + vehicleRoute.name,
          };
        }
        return item;
      })
    );
  };

  const DeleteVehicleRoute = (vehicleRouteId) => {
    const newVehicleRoutes = vehicleRoutes.filter((vehicleRoute) => vehicleRoute.id !== vehicleRouteId);
    setVehicleRoutes([...newVehicleRoutes]);
    setVehicleRouteOptions(vehicleRouteOptions.filter((item) => item.value !== vehicleRouteId));
  };

  const RetrieveContracts = useCallback(async () => {
    const getContractYearLabel = (year) => {
      if (year.toString() === "625c94645810394434e7ed79") {
        return "2022-2023";
      } else if (year.toString() === "647d789024676b00145f0d30") {
        return "2023-2024";
      } else if (year.toString() === "661c8d1e862b3f2c307b1276") {
        return "2024-2025";
      }
    };

    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/contracts`);
      setContracts(responseData.contracts);
      const allContracts = responseData.contracts.map((contract) => {
        if (contract.student) {
          return {
            value: contract.id,
            label:
              contract.student.name +
              " - " +
              contract.payerName +
              " - " +
              getContractYearLabel(contract.year.toString()),
          };
        } else {
          return {
            value: contract.id,
            label: contract.id + " - " + contract.payerName,
          };
        }
      });
      setContractsOptions(allContracts);
    } catch (err) {
      setContractsOptions([]);
      setContracts([]);
    }
    return true;
  }, [sendRequest]);

  const InsertContract = (contract) => {
    setContracts([contract, ...contracts]);
    if (contract.student) {
      const student = students.find((student) => student.id === contract.student);
      setContractsOptions([
        ...contractsOptions,
        {
          value: contract.id,
          label: student.name + " - " + contract.payerName + " - " + contract.year,
        },
      ]);
    } else {
      setContractsOptions([
        ...contractsOptions,
        {
          value: contract.id,
          label: contract.id + " - " + contract.payerName,
        },
      ]);
    }
  };

  const UpdateContract = (contract) => {
    const index = contracts.findIndex((x) => x.id === contract.id);
    const newContracts = [...contracts];
    newContracts[index] = contract;
    setContracts([...newContracts]);
    if (contract.student) {
      const student = students.find((student) => student.id === contract.student);
      setContractsOptions(
        contractsOptions.map((item) => {
          if (item.value === contract.id) {
            return {
              value: contract.id,
              label: student.name + " - " + contract.payerName + " - " + contract.year,
            };
          }
          return item;
        })
      );
    } else {
      setContractsOptions(
        contractsOptions.map((item) => {
          if (item.value === contract.id) {
            return {
              value: contract.id,
              label: contract.id + " - " + contract.payerName,
            };
          }
          return item;
        })
      );
    }
  };

  const DeleteContract = (contractId) => {
    const newContracts = contracts.filter((contract) => contract.id !== contractId);
    setContracts([...newContracts]);
    setContractsOptions(contractsOptions.filter((item) => item.value !== contractId));
  };

  const UpdateTransfers = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/transfers`);
      setTransfers(responseData.transfers);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const UpdateTransferregions = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/transferregions`);
      setTransferRegions(responseData.transferregions);

      const transferOptions = responseData.transferregions.map((regionitem) => {
        return { value: regionitem.id, label: regionitem.name };
      });
      setTransferRegionOptions(transferOptions);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const UpdateIkarusTransfers = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/ikarustransfers`);
      setIkarusTransfers(responseData.transfers);
    } catch (err) {}

    return true;
  }, [sendRequest]);

  const UpdateIkarusTransferRegions = useCallback(async () => {
    const fetchIkarusTransferregions = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/ikarustransferregions`);
        setIkarusTransferRegions(responseData.regions);

        const transferOptions = responseData.regions.map((regionitem) => {
          return { value: regionitem.id, label: regionitem.name };
        });
        setIkarusTransferRegionOptions(transferOptions);
      } catch (err) {}
    };

    fetchIkarusTransferregions();
  }, [sendRequest]);

  const UpdateIkarusTransferPassengers = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/ikarustransferpassengers`);
      setIkarusTransferPassengers(responseData.passengers);

      const transferOptions = responseData.passengers.map((passenger) => {
        return { value: passenger.id, label: passenger.name };
      });
      setIkarusTransferPassengerOptions(transferOptions);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const RetrieveReceipts = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/receipts`);
      setReceipts(responseData.receipts);
    } catch (err) {}

    return true;
  }, [sendRequest]);

  const RetrieveWithdraws = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/withdraws`);
      setWithdraws(responseData.withdraws);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const UpdateVehiclePayments = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/vehiclepayments`);
      setVehiclePayments(responseData.vehiclePayments);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const RetrieveExpenses = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/expenses`);
      setExpenses(responseData.expenses);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const RetrieveEducationYears = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/educationyears`);
      setEducationYears(responseData.educationYears);

      const allEducationYears = responseData.educationYears.map((educationyear) => {
        return {
          value: educationyear.id,
          label: educationyear.name,
        };
      });
      setEducationYearsOptions(allEducationYears);
      const storedEducationYearId = localStorage.getItem("selectedEducationYearId");
      if (storedEducationYearId) {
        setSelectedEducationYearId(storedEducationYearId);
      } else {
        setSelectedEducationYearId(allEducationYears[allEducationYears.length - 1].value);
      }
    } catch (err) {}

    return true;
  }, [sendRequest]);

  const RetrieveAccountingUtils = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/discounts`);
      setDiscounts(responseData.discounts);
      const allDiscounts = responseData.discounts.map((discount) => {
        return {
          value: discount.id,
          label: discount.name,
        };
      });
      setDiscountOptions(allDiscounts);
    } catch (err) {}

    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/servicetemplates`);
      setServiceTemplates(responseData.serviceTemplates);

      const allserviceTemplates = responseData.serviceTemplates.map((serviceTemplate) => {
        return {
          value: serviceTemplate.id,
          label: serviceTemplate.name,
        };
      });
      setServiceTemplateOptions(allserviceTemplates);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const UpdateSelectedEducationYearId = (selectedYearId) => {
    localStorage.setItem("selectedEducationYearId", selectedYearId);
    setSelectedEducationYearId(selectedYearId);
  };

  const UpdateTypes = useCallback(async () => {
    const code = localStorage.getItem("i18nextLng") || "en";
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/clients/initialinfo/${code}`);
      setClientTypes(responseData.clientTypes);
      setUserTypes(responseData.userTypes);
      setServiceType(responseData.serviceType);
    } catch (err) {}
    return true;
  }, [sendRequest]);

  const GetParentFromStudent = (studentId) => {
    const student = students.find((student) => student.id === studentId);
    if (student) {
      const relation = relations.find((relation) => relation.student === student.id);
      if (relation) {
        return users.find((user) => user.id === relation.parent);
      }
    }
    return null;
  };

  const UpdateEverything = useCallback(async () => {
    const storedLang = localStorage.getItem("i18nextLng");
    i18next.changeLanguage(storedLang || "en");

    await Promise.all([
      UpdateTypes(storedLang || "en"),
      RetrieveEducationYears(),
      UpdateUsers(),
      UpdateDrivers(),
      UpdateSchools(),
      UpdateStudents(),
      UpdateVehicleRoutes(),
      UpdateRelations(),

      UpdateTransferregions(),
      UpdateTransfers(),
      UpdateIkarusTransfers(),
      UpdateStops(),
      UpdatePersonelServices(),
      UpdateShifts(),

      //RetrieveContracts(),
      // RetrieveReceipts(),
      //RetrieveWithdraws(),
      //RetrieveExpenses(),
      //RetrieveAccountingUtils(),

      //UpdateIkarusTransferRegions(),

      //UpdateCompanies(),
      //UpdateVehicles(); BU ZATEN YOKTU
      //UpdatePLocations(),

      // Contracts

      // UpdateVehiclePayments(),

      // UpdateIkarusTransferPassengers(),
    ]);
    setStoreReady(true);
  }, [
    UpdateTypes,
    RetrieveEducationYears,
    UpdateUsers,
    UpdateDrivers,
    UpdateSchools,
    UpdateStudents,
    UpdateVehicleRoutes,
    UpdateRelations,
    UpdateTransferregions,
    UpdateTransfers,
    UpdateIkarusTransfers,
    UpdateStops,
    UpdatePersonelServices,
  ]);

  return {
    isAdmin,
    users,
    userMap,
    studentToParentMap,
    parentToStudentMap,
    clientTypes,
    userTypes,
    serviceType,
    vehicles,
    schools,
    schoolOptions,
    stopOptions,
    companies,
    relations,
    students,
    studentOptions,
    parentOptions,
    personelOptions,
    companyOptions,
    vehicleRoutes,
    drivers,
    driverOptions,
    storeReady,
    contracts,
    educationYears,
    selectedEducationYearId,
    discounts,
    serviceTemplates,
    educationYearsOptions,
    discountOptions,
    serviceTemplateOptions,
    receipts,
    withdraws,
    contractsOptions,
    expenses,
    vehicleRouteOptions,
    vehiclePayments,
    transfers,
    pLocations,
    pLocationsOptions,
    transferregions,
    transferregionOptions,
    ikarusTransfers,
    ikarusTransferRegions,
    ikarusTransferRegionOptions,
    ikarusTransferPassengers,
    ikarusTransferPassengerOptions,
    isProcessing,
    shifts,
    stops,
    standartPersonelServices,
    standartPersonelServiceOptions,
    shiftPersonelServices,
    shiftPersonelServiceOptions,
    userOptions,
    shiftOptions,
    shiftUsers,
    shiftUserOptions,
    InsertUser,
    UpdateUser,
    DeleteUser,
    InsertStudent,
    UpdateStudent,
    DeleteStudent,
    InsertRelation,
    UpdateRelation,
    DeleteRelation,
    InsertShift,
    UpdateShift,
    DeleteShift,
    InsertStop,
    UpdateStop,
    DeleteStop,
    InsertDriver,
    UpdateDriver,
    DeleteDriver,
    UpdatePersonelServices,
    InsertPersonelService,
    UpdatePersonelService,
    DeletePersonelService,
    InsertVehicleRoute,
    UpdateVehicleRoute,
    DeleteVehicleRoute,
    UpdateVehicles,
    UpdateRelations,
    UpdateVehicleRoutes,
    UpdateCompanies,
    UpdateSchools,
    InsertSchool,
    UpdateSchool,
    DeleteSchool,
    UpdateUsers,
    UpdateDrivers,
    UpdateStudents,
    UpdateEverything,
    RetrieveContracts,
    InsertContract,
    UpdateContract,
    DeleteContract,
    RetrieveAccountingUtils,
    RetrieveReceipts,
    RetrieveWithdraws,
    RetrieveExpenses,
    UpdateVehiclePayments,
    UpdateTransfers,
    UpdatePLocations,
    UpdateTransferregions,
    UpdateIkarusTransfers,
    UpdateIkarusTransferRegions,
    UpdateIkarusTransferPassengers,
    UpdateShifts,
    UpdateStops,
    UpdateTypes,
    GetParentFromStudent,
    UpdateSelectedEducationYearId,
    RetrieveEducationYears,
  };
};
