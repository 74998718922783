import React, { useContext, useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import NotFound from "./NotFound";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

import { AuthContext } from "shared/context/auth-context";
import { useSearchParams } from "react-router-dom";
import { useHttpClient } from "shared/hooks/http-hook";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import "./Auth.css";

const VerifyEmail = () => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const { sendRequest } = useHttpClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = React.useState(0);

  const verifyEmail = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/clients/verifyemail`,
        "POST",
        JSON.stringify({
          code: searchParams.get("c"),
        }),
        {
          "Content-Type": "application/json",
        }
      );

      setState(1);

      if (responseData.code === 200) {
        auth.login(responseData.clientId, responseData.token, null, responseData.clientName);
        window.location.href = "/users";
        //navigate("/users");
      }

      //navigate("/users");
    } catch (err) {
      setState(2);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  return (
    <React.Fragment>
      <div
        className="center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "40%",
          margin: "0 auto",
        }}
      >
        {(state === 0 || state === 1) && <h2>{t("emailVerification")}</h2>}

        {state === 0 && <h2>{<LoadingSpinner />}</h2>}
        {state === 1 && <CheckCircleOutlineIcon />}

        {state === 0 && <p>{t("emailVerificationChecking")}</p>}
        {state === 1 && <p>{t("emailVerificationChecked")}</p>}

        {state === 2 && <NotFound />}
      </div>
    </React.Fragment>
  );
};

export default VerifyEmail;
