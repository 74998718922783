import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

import MapSinglePoint from "components/shared/MapSinglePoint";
import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";
import FormFooter from "shared/components/FormElements/FormFooter";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import NotFound from "shared/components/UIElements/NotFound";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";

import { isActiveOptions } from "shared/util/types";
import { SERVICE_TYPES } from "shared/util/constants";

// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { WindowSharp } from "@mui/icons-material";
// #endregion Hooks

const StudentItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  //standard code
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // context
  const { schoolOptions, serviceType, students } = useContext(StoreContext);

  // item info
  const itemType = "student";
  const endPointUpdate = "students";
  const endPointDelete = "students";
  const endPointCreate = "students";

  // item special states
  const [markerLocationMorning, setMarkerLocationMorning] = useState();
  const [studentAddressMorning, setStudentAddressMorning] = useState();
  const [addressMorningInSync, setAddressMorningInSync] = useState(false);

  const [markerLocationEvening, setMarkerLocationEvening] = useState();
  const [studentAddressEvening, setStudentAddressEvening] = useState();
  const [addressEveningInSync, setAddressEveningInSync] = useState(false);

  // item special functions
  const mapDataChangeHandlerMorning = (address, location, inSync) => {
    setMarkerLocationMorning(location);
    setStudentAddressMorning(address);
    setAddressMorningInSync(inSync);
    inputHandler("addressgeton", address, true);
  };

  const mapDataChangeHandlerEvening = (address, location, inSync) => {
    setMarkerLocationEvening(location);
    setStudentAddressEvening(address);
    setAddressEveningInSync(inSync);
    inputHandler("addressgetoff", address, true);
  };

  // formstates
  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: true },
      identifier: { value: "", isValid: true },
      name: { value: "", isValid: true },
      level: { value: "", isValid: true },
      addressgeton: { value: null, isValid: true },
      addressgetoff: { value: null, isValid: true },
      isActive: { value: "", isValid: true },
      phonenumber: { value: "-", isValid: true },
      email: { value: "-", isValid: true },
    },
    true
  );

  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentStudent = students.find((student) => student.id === itemId);
      setLoadedItem(currentStudent);
      setStudentAddressMorning(currentStudent?.addressgeton);

      if (currentStudent?.locationgeton?.lat && currentStudent?.locationgeton?.lng) {
        setMarkerLocationMorning(currentStudent.locationgeton);
        setAddressMorningInSync(true);
      } else {
        setMarkerLocationMorning(null);
        setAddressMorningInSync(false);
      }

      setStudentAddressEvening(currentStudent?.addressgetoff);
      if (currentStudent?.locationgetoff?.lat && currentStudent?.locationgetoff?.lng) {
        setMarkerLocationEvening(currentStudent.locationgetoff);
        setAddressEveningInSync(true);
      } else {
        setMarkerLocationEvening(null);
        setAddressEveningInSync(false);
      }
    } catch (err) {}
    setIsItemLoaded(true);
  }, [itemId, students]);

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line
  }, [itemId, fetchItem]);

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    const endpoint = isNewItem
      ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
      : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

    const postType = isNewItem ? "POST" : "PATCH";

    const infoToBeSent = {
      school: formState.inputs.school.value,
      identifier: formState.inputs.identifier.value,
      name: formState.inputs.name.value,
      phonenumber: formState.inputs.phonenumber.value,
      email: formState.inputs.email.value,
      level: formState.inputs.level.value,
      addressgeton: studentAddressMorning,
      locationgeton: markerLocationMorning,
      addressgetoff: studentAddressEvening,
      locationgetoff: markerLocationEvening,
      isActive: formState.inputs.isActive.value,
    };

    //standard code
    try {
      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.message) {
        window.toast.error(err.message);
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };

  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {/* <Modal
        show={showHelp}
        header="Yardım"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button danger onClick={() => setShowHelp(false)}>
              Tamam
            </Button>
          </React.Fragment>
        }
      >
        <p>
          <ul>
            <li>
              <b>Açıklama</b> maddesi aynı isimli öğrencilerinizi ayırt etmek için kullanılır. Bu adıma "Öğrenci Ad
              Soyad - Veli Ad Soyad" bilgilerini yazmanızı öneririz.
            </li>
            <br />
            <li>
              <b>Öğrenci adres bilgileri</b> kısmında öğrencinin biniş ve iniş adreslerini giriniz. Adres aynı olsa dahi
              bu bilginin hem biniş, hem iniş adımlarına girilmesi gerekmektedir.
            </li>
            <br />
            <li>
              <b>Biniş/İniş adresinden konum getir</b> tuşlarına basmanız ve haritalar üzerinde öğrencinin adresinin
              doğru şekilde konumlandığını kontrol etmeniz gerekmektedir. Eğer harita üzerinde adresiniz doğru
              konumlandırılmamışsa öğrenci servis uygulamasını <b>kullanamayacaktır</b>.
            </li>
            <br />
            <li>
              <b>Öğrenci eğer artık okulunuz bünyesinde servis kullanmayacaksa </b> Durum bilgisini Pasif olarak
              işaretleyiniz. Pasif durumdaki öğrenciler yukarıdaki <b>Analiz</b> sekmesindeki adımlarda dikkate
              alınmazlar. Bir öğrenci okulunuzdan ayrıldığında öğrenciyi silmek yerine kullanım durumunu pasif'e
              çekmeniz gerekmektedir. Böylece öğrenciye ait geçmiş muhasebe bilgilerine ulaşabilirsiniz ve öğrencinin
              okulunuza geri dönmesi durumunda tekrar kayıt oluşturmanıza gerek kalmaz.
            </li>
            <br />
            <li>
              <b>Öğrenci silme işlemini </b> yalnızca çok bariz bir şekilde hatalı giriş yaptığınızda son seçenek olarak
              kullanınız. Daha önce servis kullanmış, sözleşme ve muhasebe kayıtları oluşturulmuş öğrencileri silmek
              yerine pasif duruma çekmeniz gerekmektedir.
            </li>
          </ul>
        </p>
      </Modal> */}

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          {/* <div className="button-container">
            <Button type="button" onClick={() => setShowHelp(true)}>
              Yardım
            </Button>
          </div> */}

          <div className="horizontal-flex">
            <CustomSelect
              options={schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Okul*"
              initialValue={schoolOptions.find((option) => option.value.toString() === loadedItem?.school.toString())}
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={isNewItem ? false : true}
            ></CustomSelect>

            <CustomSelect
              options={isActiveOptions}
              id="isActive"
              onInput={inputHandler}
              label="Durum"
              initialValue={
                isActiveOptions.find((option) => option.value.toString() === loadedItem?.isActive.toString())
                  ? isActiveOptions.find((option) => option.value.toString() === loadedItem?.isActive.toString())
                  : isActiveOptions[1]
              }
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={isNewItem ? false : true}
            ></CustomSelect>
          </div>

          <div className="horizontal-flex">
            <Input
              id="name"
              element="input"
              type="text"
              label="İsim*"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="İsim giriniz."
              initialValue={loadedItem?.name}
              initialValid={isNewItem ? false : true}
              onInput={inputHandler}
            />

            <Input
              id="level"
              element="input"
              type="text"
              label="Sınıf"
              initialValue={loadedItem?.level}
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              onInput={inputHandler}
            />
          </div>

          <Input
            id="identifier"
            element="input"
            type="text"
            label="Açıklama*"
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={loadedItem?.identifier}
            initialValid={isNewItem ? false : true}
            onInput={inputHandler}
            errorText={`${
              serviceType === SERVICE_TYPES.SCHOOL ? t("studentDescriptionError") : t("userDescrtiptionError")
            }`}
          />

          <MapSinglePoint
            id="studentMorningItemMap"
            title={t("pickUpAddress")}
            label={t("pickUpAddress")}
            initialAddress={studentAddressMorning || ""}
            initialAddressInSync={addressMorningInSync}
            initialLocation={markerLocationMorning}
            onInput={inputHandler}
            onMapDataChange={mapDataChangeHandlerMorning}
          ></MapSinglePoint>

          <div style={{ marginBottom: "20px" }}></div>

          <MapSinglePoint
            id="studentEveningItemMap"
            title={t("dropOffAddress")}
            label={t("dropOffAddress")}
            initialAddress={studentAddressEvening || ""}
            initialAddressInSync={addressEveningInSync}
            initialLocation={markerLocationEvening}
            onInput={inputHandler}
            onMapDataChange={mapDataChangeHandlerEvening}
          ></MapSinglePoint>

          {/* <Input
            id="phonenumber"
            element="input"
            type="text"
            label="Telefon"
            //errorText="Telefon giriniz."
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem.phonenumber}
            initialValid={true}
            onInput={inputHandler}
          />
          <Input
            id="email"
            element="input"
            type="text"
            label="Email"
            errorText="Email giriniz."
            onInput={inputHandler}
            initialValue={loadedItem.email}
            initialValid={true}
            validators={[VALIDATOR_NONE()]}
          /> */}

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={
              !formState.inputs.school.value ||
              !formState.isValid ||
              !addressMorningInSync ||
              !addressEveningInSync ||
              isLoading
            }
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default StudentItem;
