import React, { useEffect, useContext, useCallback } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import NotFound from "pages/auth/NotFound";

import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import TabPanel from "shared/components/UIElements/TabPanel";

import Contracts from "./Contracts/Contracts";
import ServiceTemplate from "./ServiceTemplate";
import EducationYear from "./EducationYear";
import Discount from "./Discount";
import Receipt from "./Receipts/Receipt";
import Withdraw from "./Withdraw";
import Invoice from "./Invoice";
import Expense from "./Expense";
import Report from "./Report";
import VehiclePayment from "./VehiclePayment";
import { PERMISSION_TYPES } from "shared/util/constants";

import { useTranslation } from "react-i18next";

import "./AccountingItem.css";
import "./AccountingList.css";

const Accounting = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const { RetrieveContracts, RetrieveReceipts, RetrieveWithdraws, RetrieveExpenses, RetrieveAccountingUtils } =
    useContext(StoreContext);

  const [loaded, setLoaded] = React.useState(false);

  const retrieveAccountingInfo = useCallback(async () => {
    // if (!auth.permissions[PERMISSION_TYPES.ACCESS_TO_ACCOUNTING]) {
    //   setLoaded(true);
    //   return;
    // }

    await Promise.all([
      RetrieveContracts(),
      RetrieveReceipts(),
      RetrieveWithdraws(),
      RetrieveExpenses(),
      RetrieveAccountingUtils(),
    ]);
    setLoaded(true);
  }, [RetrieveAccountingUtils, RetrieveContracts, RetrieveExpenses, RetrieveReceipts, RetrieveWithdraws]);

  useEffect(() => {
    retrieveAccountingInfo();
  }, [retrieveAccountingInfo]);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!loaded) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  function tabProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <React.Fragment>
      <div className="flex-row">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Accounting Tabs"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label={t("contracts")} {...tabProps(0)} />
          <Tab label={t("receipts")} {...tabProps(1)} />
          <Tab label={t("invoices")} {...tabProps(2)} />
          <Tab label={t("refunds")} {...tabProps(3)} />
          <Tab label={t("vehicleCompensations")} {...tabProps(4)} />
          <Tab label={t("reports")} {...tabProps(5)} />
          <Tab label={t("expenses")} {...tabProps(6)} />
          <Tab label={t("contractTemplates")} {...tabProps(7)} />
          <Tab label={t("discounts")} {...tabProps(8)} />
          <Tab label={t("servicePeriods")} {...tabProps(9)} />
        </Tabs>
        <TabPanel value={tabValue} index={0} sx={{ p: 0 }}>
          <Contracts></Contracts>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Receipt></Receipt>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Invoice></Invoice>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Withdraw></Withdraw>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <VehiclePayment></VehiclePayment>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <Report></Report>
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <Expense></Expense>
        </TabPanel>
        <TabPanel value={tabValue} index={7}>
          <ServiceTemplate></ServiceTemplate>
        </TabPanel>
        <TabPanel value={tabValue} index={8}>
          <Discount></Discount>
        </TabPanel>
        <TabPanel value={tabValue} index={9}>
          <EducationYear></EducationYear>
        </TabPanel>
      </div>
    </React.Fragment>
  );
};

export default Accounting;
