import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { days, days_en } from "shared/util/types";
import "./Admin.css";
import { getColumnValue } from "shared/util/datagrid";

import ManagedShift from "components/shift-management/ManagedShift";

const ShiftManagement = () => {
  const { t } = useTranslation();
  const listName = "main_shiftmanagement";

  const { shifts, schoolOptions } = useContext(StoreContext);
  const pageItems = shifts;

  let listColumns = [];

  if (schoolOptions?.length > 1) {
    listColumns.push(getColumnValue("school", t("client"), schoolOptions, "school", 300));
  }

  listColumns.push({
    field: "day",
    headerName: t("day"),
    width: 200,
    valueGetter: (params) => {
      try {
        //return language === "en"
        return false
          ? days_en.find((day) => day.value.toString() === params.row.day.toString()).label
          : days.find((day) => day.value.toString() === params.row.day.toString()).label;
      } catch (e) {
        return "";
      }
    },
  });

  listColumns.push({ field: "name", headerName: t("name"), width: 250 });
  listColumns.push({ field: "description", headerName: t("description"), width: 300 });
  listColumns.push({
    field: "numberofpersonel",
    headerName: t("numberofpersonel"),
    width: 200,
    color: "red",
    valueGetter: (params) => {
      try {
        return params?.row?.users?.length?.toString() || "0";
      } catch (e) {
        return "Hata";
      }
    },
  });

  // listColumns.push({ field: "starttime", headerName: t("shiftStartTime"), width: 200 });
  listColumns.push({ field: "serviceArrivalTime", headerName: t("shiftServiceArrivalTime"), width: 350 });
  // listColumns.push({ field: "endtime", headerName: t("shiftEndTime"), width: 200 });
  listColumns.push({ field: "serviceLeaveTime", headerName: t("shiftServiceLeaveTime"), width: 350 });

  return (
    <Layout
      listName={listName}
      items={pageItems}
      listColumns={listColumns}
      SelectedItemComponent={ManagedShift}
      NewItemComponent={ManagedShift}
      hideCreateNewItem={true}
      isAlwaysFullScreen={true}
      hideRightHeader={true}
    />
  );
};

export default ShiftManagement;
