import React from "react";
import { useTranslation } from "react-i18next";

import Button from "shared/components/FormElements/Button";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const ItemHeader = ({ isFullScreen, onClose, onFullScreen, onHalfScreen, isMobile, isAlwaysFullScreen }) => {
  const { t } = useTranslation();

  const fullScreenHandler = () => {
    if (isFullScreen) {
      onHalfScreen();
    } else {
      onFullScreen();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: !isFullScreen ? "space-between" : "flex-end",
        columnGap: "10px",
      }}
    >
      <Button type="button" size="small" danger onClick={onClose}>
        &#60; {t("close")}
      </Button>

      {!isMobile && !isAlwaysFullScreen && (
        <Button type="button" size="icon" danger onClick={fullScreenHandler}>
          {!isFullScreen ? <FullscreenIcon /> : <FullscreenExitIcon />}
        </Button>
      )}
    </div>
  );
};

export default ItemHeader;
