import Geocode from "react-geocode";
import axios from "axios";
const API_KEY = "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc";

export const AIRPORT_ID = "airport";
export const AIRPORT_LAT = 36.9043327;
export const AIRPORT_LNG = 30.7993019;

// returns array consisting of
// id (from)
// distance,
// distanceText
// duration,
// durationText
// steps { distance, duration, end_location, html_instructions, polyline, start_location, travel_mode }

export const getRouteSteps = async (origin, destination, waypoints, token) => {
  try {
    const data = {
      origin,
      destination,
      waypoints,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/automated/getwaypointdistances`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );

    return response?.data?.routeSteps || [];
  } catch (err) {
    return [];
  }
  // Rest of the function code...
};

// origin, destination: { location: { lat, lng } }
export const getDirection = async (origin, destination, token) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/directions/getDirectionDetails`,
      JSON.stringify({ origin, destination }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );

    return response?.data?.direction || {};
  } catch (err) {
    return {};
  }
};

export const getDirectionsTSPDetails = async (origin, destination, waypoints, token) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/directions/getDirectionsTSPDetails`,
      JSON.stringify({ origin, destination, waypoints }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );

    return response?.data?.directionsTSPDetails || [];
  } catch (err) {
    return [];
  }
};

export async function AddressFromLocation(location) {
  Geocode.setApiKey(API_KEY);
  Geocode.setLanguage("tr");
  Geocode.setRegion("tr");
  Geocode.setLocationType("ROOFTOP");

  let address;

  try {
    await Geocode.fromLatLng(location.lat, location.lng).then((response) => {
      address = response.results[0].formatted_address;
    });
  } catch (error) {
    return null;
  }

  return address;
}

export async function LocationFromAddress(address) {
  Geocode.setApiKey(API_KEY);
  Geocode.setLanguage("tr");
  Geocode.setRegion("tr");
  Geocode.setLocationType("ROOFTOP");

  let location;

  await Geocode.fromAddress(address).then(
    (response) => {
      location = response.results[0].geometry.location;
    },
    (error) => {
      throw new Error(error);
    }
  );

  return location;
}

export function calculateDistance(points, distances, transferType) {
  let totalDistance = 0;
  for (let i = 0; i < points.length - 1; i++) {
    const currentPoint = points[i];
    const nextPoint = points[i + 1];

    if (transferType.toString() === "100") {
      totalDistance += distances.find((d) => d.to === currentPoint && d.from === nextPoint)?.data?.distance?.value || 0;
    } else {
      totalDistance += distances.find((d) => d.from === currentPoint && d.to === nextPoint)?.data?.distance?.value || 0;
    }
  }
  return totalDistance;
}

function generatePermutations(numbers) {
  if (numbers.length === 1) {
    return [numbers];
  }

  const permutations = [];

  for (let i = 0; i < numbers.length; i++) {
    const currentNumber = numbers[i];
    const remainingNumbers = [...numbers.slice(0, i), ...numbers.slice(i + 1)];
    const subPermutations = generatePermutations(remainingNumbers);

    for (const permutation of subPermutations) {
      permutations.push([currentNumber, ...permutation]);
    }
  }

  return permutations;
}

export function tsp(startingPoint, peopleIds, distances, transferType, destinationId = null) {
  let destinationIdToCheck;
  if (destinationId) {
    destinationIdToCheck = destinationId;
  } else {
    destinationIdToCheck = AIRPORT_ID;
  }

  const allPermutations = generatePermutations([...peopleIds]);
  let shortestDistance = Infinity;
  let shortestRoute;

  for (const permutation of allPermutations) {
    const route = [startingPoint, ...permutation];
    const distance = calculateDistance(route, distances, transferType);

    if (distance < shortestDistance) {
      shortestDistance = distance;
      shortestRoute = route;
    }
  }

  if (transferType.toString() === "100") {
    return {
      distance: shortestDistance,
      route: shortestRoute?.filter((item) => item !== destinationIdToCheck)?.reverse() || [],
    };
  } else {
    return { distance: shortestDistance, route: shortestRoute?.filter((item) => item !== destinationIdToCheck) || [] };
  }
}

// export function tsp(startingPoint, people, distances) {
//   const visited = new Set();
//   visited.add(startingPoint);
//   let currentPoint = startingPoint;
//   let totalDistance = 0;
//   let minDistance = Infinity;
//   let nextPoint;
//   let nextName;
//   const route = [];
//   while (visited.size < people.length + 1) {
//     // eslint-disable-next-line no-loop-func
//     people.forEach(person => {
//       const distance = distances.find(distance => distance.to === currentPoint && distance.from === person.id)?.data?.distance?.value || Infinity;
//       if (!visited.has(person.id) && distance < minDistance) {
//         minDistance = distance;
//         nextPoint = person.id;
//         nextName = person.name;
//       }
//     });

//     visited.add(nextPoint);
//     if(nextPoint !== AIRPORT_ID) {
//     route.push({nextPoint, nextName});
//     }
//     totalDistance += minDistance;
//     currentPoint = nextPoint;
//     minDistance = Infinity;
//   }

//   // totalDistance += distances[currentPoint][startingPoint]; // Return to starting point
//   // route.push(startingPoint);

//   return { distance: totalDistance, route: route.reverse() };
// }
