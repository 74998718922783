import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { SCHOOL_TYPES } from "shared/util/constants";
import "./Admin.css";

import SchoolItem from "components/school/SchoolItem";

const Schools = () => {
  const { t } = useTranslation();
  const itemType = "schoolItem";
  const listName = "main_schools";

  const { schools, DeleteSchool, InsertSchool, UpdateSchool } = useContext(StoreContext);
  const pageItems = schools;

  let listColumns = [];
  listColumns.push({ field: "name", headerName: t("name"), width: 400 });
  listColumns.push({
    field: "type",
    headerName: t("schoolType"),
    width: 300,
    valueGetter: (params) => {
      return params.row?.type === SCHOOL_TYPES.SCHOOL ? t("school") : t("employee");
    },
  });

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={SchoolItem}
      NewItemComponent={SchoolItem}
      DeleteItem={DeleteSchool}
      InsertItem={InsertSchool}
      UpdateItem={UpdateSchool}
    />
  );
};

export default Schools;
