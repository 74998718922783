import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./Item.css";

const TransferRegionItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();
  const [openError, setOpenError] = React.useState(false);

  const itemId = props.itemid;
  const updateStoreItems = store.UpdateTransferregions;

  const ItemTypeTR = "Transfer Bölgesi";
  const ItemType = "transferregion";
  const ItemEndPointType = "transferregions";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);
      setItemState(Date.now());
    } catch (err) {}
  }, [sendRequest, itemId]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      setItemState(Date.now());
      updateStoreItems();
      setLoadedItem(null);
      navigate(`/${ItemEndPointType}`);
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: true },
      name: { value: "", isValid: true },
      amount: { value: 0, isValid: true },
    },
    true
  );

  const itemUpdateSubmitHandler = useCallback(
    async (event) => {
      event.preventDefault();

      let amounttoWrite = 0;
      //check if amount is number
      if (isNaN(formState.inputs.amount.value)) {
        setOpenError(true);
        return;
      } else {
        amounttoWrite = parseInt(formState.inputs.amount.value);
      }

      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
          "PATCH",
          JSON.stringify({
            school: formState.inputs.school.value,
            name: formState.inputs.name.value,
            amount: amounttoWrite,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        setLoadedItem(responseData[ItemType]);
        setItemState(Date.now());
        updateStoreItems();

        //navigate(`/${ItemEndPointType}`);
      } catch (err) {}
    },
    [sendRequest, itemId, formState, auth, updateStoreItems]
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Ücret bilgisi sayısal bir değer olmalıdır!
        </MuiAlert>
      </Snackbar>

      {!isLoading && loadedItem && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" danger onClick={props.onClose}>
              &#60; Geri
            </Button>
          </div>

          <CustomSelect
            options={store.schoolOptions}
            id="school"
            onInput={inputHandler}
            label="Kurum"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Zorunlu alan"
            initialValue={store.schoolOptions.find(
              (option) => option.value.toString() === loadedItem.school.toString()
            )}
          ></CustomSelect>

          <Input
            id="name"
            element="input"
            type="text"
            label="İsim"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="İsim giriniz."
            initialValue={loadedItem.name}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="amount"
            element="input"
            type="text"
            label="Ücret"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Ücret giriniz."
            initialValue={loadedItem.amount}
            initialValid={true}
            onInput={inputHandler}
          />

          <div className="button-container">
            <Button type="submit" disabled={!formState.isValid}>
              Güncelle
            </Button>

            <Button type="button" danger onClick={showDeleteWarningHandler}>
              SİL
            </Button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default TransferRegionItem;
