import React, { useContext } from "react";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import DatePicker from "shared/components/FormElements/DatePicker";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";

// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./AccountingItem.css";

const EducationYearNew = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const updateStoreItems = store.RetrieveAccountingUtils;
  const ItemTypeTR = "Eğitim Öğretim Yılı";
  const APIEndPoint = "educationyears";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          yearStartDate: formState.inputs.yearStartDate.value,
          yearEndingDate: formState.inputs.yearEndingDate.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
    } catch (err) {
      console.log(err);
    }
  };

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: false },
      yearStartDate: { value: "", isValid: false },
      yearEndingDate: { value: "", isValid: false },
    },
    false
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>

        <Input
          id="name"
          element="input"
          type="text"
          label="Eğitim Yılı"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Eğitim yılı giriniz."
          initialValid={false}
          onInput={inputHandler}
        />

        <DatePicker
          id="yearStartDate"
          onInput={inputHandler}
          label="Eğitim Başlangıç Tarihi"
          initialValid={false}
        ></DatePicker>

        <DatePicker
          id="yearEndingDate"
          onInput={inputHandler}
          label="Eğitim Bitiş Tarihi"
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={false}
        ></DatePicker>

        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default EducationYearNew;
