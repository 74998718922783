import React, { useState, useContext, useEffect } from "react";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { parseComplexDate } from "shared/util/util";
import Grid from "@mui/material/Grid";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import { useForm } from "shared/hooks/form-hook";
import DatePicker from "shared/components/FormElements/DatePicker";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import * as XLSX from "xlsx";

import "./Admin.css";

const TransferReport = (props) => {
  const store = useContext(StoreContext);
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [today, setToday] = useState(null);
  const [calculatedTransfers, setCalculatedTransfers] = useState([]);
  const [initDateSelected, setInitDateSelected] = useState();
  const [finishDateSelected, setFinishDateSelected] = useState();

  const [storeTransfers, setStoreTransfers] = useState([]);
  const [storeVehicleRoutes, setStoreVehicleRoutes] = useState([]);

  const [savingVehicleRouteId, setSavingVehicleRouteId] = useState();

  const [defaultFormat, setDefaultFormat] = useState(true);

  const [formState, inputHandler] = useForm(
    {
      datestarter: { value: "", isValid: false },
      datefinisher: { value: "", isValid: false },
      school: { value: "62a7b93b180c51001643701d", isValid: true },
    },
    false
  );

  const [formStateDriver, inputHandlerDriver] = useForm(
    {
      driver: { value: "", isValid: true },
    },
    true
  );

  const ItemEndPointType = "vehicleroutes";

  useEffect(() => {
    const todayString = new Date();
    const date = parseComplexDate(todayString);
    setToday(date);
    setInitDateSelected(date);
    setFinishDateSelected(date);

    setStoreTransfers(store.transfers);
    setStoreVehicleRoutes(store.vehicleRoutes);
  }, [setToday, setInitDateSelected, setFinishDateSelected, store.transfers, store.vehicleRoutes]);

  const createReport = async () => {
    let allTransfers = [];

    const startDate = new Date(formState.inputs.datestarter.value);
    const endDate = new Date(formState.inputs.datefinisher.value);

    setInitDateSelected(parseComplexDate(startDate));
    setFinishDateSelected(parseComplexDate(endDate));

    allTransfers = storeTransfers.filter(
      (transfer) =>
        new Date(transfer.transferdate) >= startDate && new Date(transfer.transferdate) <= endDate && !transfer.done
    );

    let calculatedTransfers = [];

    for (let m = 0; m < allTransfers.length; m++) {
      const currentTransfer = allTransfers[m];

      for (let i = 0; i < currentTransfer.vehicleRoutes.length; i++) {
        const currentVehicleRoute = storeVehicleRoutes.find(
          (vehicleRoute) => vehicleRoute._id.toString() === currentTransfer.vehicleRoutes[i].toString()
        );

        if (
          currentVehicleRoute &&
          currentVehicleRoute.routelocations &&
          currentVehicleRoute.routelocations.length > 1
        ) {
          let currentPeople = [];
          for (let k = 1; k < currentVehicleRoute.routelocations.length; k++) {
            try {
              const currentPersonId = currentVehicleRoute.routelocations[k].student;
              const currentPersonTransferTime = currentVehicleRoute.routelocations[k].transfertime;
              const currentPersonName = store.students.find(
                (item) => item._id.toString() === currentPersonId.toString()
              ).name;

              currentPeople.push(
                currentPersonTransferTime ? currentPersonTransferTime + " - " + currentPersonName : currentPersonName
              );
            } catch {}
          }

          calculatedTransfers.push({
            transferInfo: currentTransfer,
            route: currentVehicleRoute,
            people: currentPeople.sort(),
          });
        }
      }
    }

    let sortedTransfers = calculatedTransfers.sort((t1, t2) =>
      t1.transferInfo.transferdate + t1.transferInfo.transfertime.split(" ")[4].substring(0, 5) >
      t2.transferInfo.transferdate + t2.transferInfo.transfertime.split(" ")[4].substring(0, 5)
        ? 1
        : -1
    );

    setCalculatedTransfers(sortedTransfers);
  };

  const peopleComponent = (people) => {
    if (!people || people.length === 0) {
      return <p>Yolcu bulunamadı.</p>;
    }

    return (
      <div>
        {people.map((person) => (
          <p key={person + Math.random()}> {person}</p>
        ))}
      </div>
    );
  };

  const saveDriverHandler = async (event, elementId, vehicleRouterId) => {
    event.preventDefault();
    setSavingVehicleRouteId(vehicleRouterId);
    const selectedDriverId = formStateDriver.inputs[elementId].value;

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${vehicleRouterId}`,
        "PATCH",
        JSON.stringify({
          driver: selectedDriverId,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      store.UpdateVehicleRoutes();

      //await createReport();
      //setItemState(Date.now());

      let currentCalculatedTransfers = [...calculatedTransfers];

      let currentTransfer = currentCalculatedTransfers.find((transfer) => transfer.route._id === vehicleRouterId);

      let currentUpdatedVehicleRoute = storeVehicleRoutes.find((route) => route._id === vehicleRouterId);
      currentUpdatedVehicleRoute.driver[0] = store.driverOptions.find((driver) => driver.value === selectedDriverId);
      currentUpdatedVehicleRoute.driver[0]._id = selectedDriverId;
      currentUpdatedVehicleRoute.driver[0].id = selectedDriverId;
      currentUpdatedVehicleRoute.driver[0].name = currentUpdatedVehicleRoute.driver[0].label;
      currentTransfer.route = currentUpdatedVehicleRoute;

      for (let i = 0; i < currentCalculatedTransfers.length; i++) {
        if (currentCalculatedTransfers[i].route._id === vehicleRouterId) {
          currentCalculatedTransfers[i] = currentTransfer;
        }
      }
      setCalculatedTransfers(currentCalculatedTransfers);
    } catch (err) {}

    setSavingVehicleRouteId(null);

    // setFormDataDriver(
    //   {
    //     ...formStateDriver.inputs,
    //     [elementId]: {
    //       value: store.driverOptions.filter((option) => option.value === selectedDriverId),
    //       isValid: true,
    //     },
    //   },
    //   true
    // );

    //formStateDriver.inputs[elementId].initialValue = store.driverOptions.filter((option) => option.value === selectedDriverId);
    //setItemState(Date.now());
  };

  const driverComponent = (vehicleRoute) => {
    let currentDriverName = "";

    try {
      currentDriverName = vehicleRoute.driver[0].name;
    } catch {
      return <p>Şoför bulunamadı.</p>;
    }
    if (!currentDriverName) {
      return <p>Şoför bulunamadı.</p>;
    }

    return defaultFormat ? (
      <div style={{ marginBottom: "10px" }}>
        <CustomSelect
          options={store.driverOptions}
          key={`vehiclerouterid_driver_${vehicleRoute._id}_${vehicleRoute.driver[0]._id}`}
          id={`vehiclerouterid_driver_${vehicleRoute._id}`}
          onInput={inputHandlerDriver}
          initialValue={store.driverOptions.filter((option) => vehicleRoute.driver[0]._id === option.value)}
          initialValid={true}
        ></CustomSelect>

        <Button
          type="button"
          onClick={(event) => saveDriverHandler(event, `vehiclerouterid_driver_${vehicleRoute._id}`, vehicleRoute._id)}
          disabled={savingVehicleRouteId === vehicleRoute._id}
        >
          Kaydet
        </Button>
      </div>
    ) : (
      <div>
        <p>{currentDriverName}</p>
      </div>
    );
  };

  // const regionComponent = (vehicleRoute) => {
  //   let currentRegionName = "";
  //   try {
  //     currentRegionName = store.transferregionOptions.find(
  //       (transferregion) => transferregion.value === vehicleRoute.routelocations[0].transferregion
  //     ).label;
  //   } catch {
  //     return <p>Bölge bulunamadı.</p>;
  //   }
  //   if (!currentRegionName) {
  //     return <p>Bölge bulunamadı.</p>;
  //   }

  //   return (
  //     <div>
  //       <p>{currentRegionName}</p>
  //     </div>
  //   );
  // };

  const downloadExcel = () => {
    let data = calculatedTransfers.map((transfer) => ({
      "Transfer Tarihi": transfer.transferInfo.transferdate,
      "Transfer İsmi": transfer.transferInfo.name,
      "Transfer Tipi": transfer.route.type.toString() === "100" ? "Toplama" : "Dağıtma",
      Bölge: store.transferregionOptions.find(
        (transferregion) => transferregion.value === transfer.route.routelocations[0].transferregion
      ).label,
      "Transfer Saati": transfer.transferInfo.transfertime.split(" ")[4].substring(0, 5),
      Sürücü: transfer.route.driver[0]?.name || "-",
      Yolcular: transfer?.people.join(", "),
      "Yolcu Sayısı": transfer?.people?.length || 0,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);

    const MAX_COLUMN_WIDTH = 40; // Set the maximum width in characters
    const columns = Object.keys(data[0]);
    worksheet["!cols"] = columns.map((col, index) => {
      const maxLength = Math.max(
        ...data.map((row) => (row[col] ? row[col].toString().length : 0)), // Length of each cell in the column
        col.length // Length of the header
      );
      return { wch: Math.min(maxLength + 2, MAX_COLUMN_WIDTH) }; // Apply max width limit
    });

    // Style headers (first row)
    // const range = XLSX.utils.decode_range(worksheet["!ref"]);
    // for (let col = range.s.c; col <= range.e.c; col++) {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col }); // First row
    //   if (!worksheet[cellAddress]) continue;

    //   worksheet[cellAddress].s = {
    //     font: {
    //       bold: true, // Make font bold
    //     },
    //   };
    // }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, initDateSelected + " - " + finishDateSelected);

    XLSX.writeFile(workbook, "Servistesin_Transfer_Raporu.xlsx");
  };

  if (
    (isLoading || !store.vehicleRoutes || !store.studentOptions || !store.schoolOptions || !today) &&
    !savingVehicleRouteId
  ) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <form>
        <Grid
          sx={{
            padding: "16px",
            fontsize: 16,
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "row", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            textAlign: "left",
            width: "100%",
            id: "report-receipts-container",
          }}
          container
          spacing={1}
        >
          <Grid item xs={3}>
            <CustomSelect
              options={store.schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Kurum"
              initialValue={store.schoolOptions.find(
                (option) => option.value.toString() === "62a7b93b180c51001643701d"
              )}
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
            ></CustomSelect>
          </Grid>

          <Grid item xs={1}>
            <DatePicker
              id="datestarter"
              onInput={inputHandler}
              label="Başlangıç Tarihi"
              errorText="Başlangıç tarihi seçiniz."
              initialValid={true}
              initialValue={initDateSelected}
              validators={[VALIDATOR_REQUIRE()]}
            ></DatePicker>
          </Grid>

          <Grid item xs={1}>
            <DatePicker
              id="datefinisher"
              initialValid={true}
              onInput={inputHandler}
              label="Bitiş Tarihi"
              errorText="Bitiş tarihi seçiniz."
              initialValue={finishDateSelected}
              validators={[VALIDATOR_REQUIRE()]}
            ></DatePicker>
          </Grid>

          <Grid item xs={2}>
            <div style={{ position: "relative", top: "13px" }}>
              <Button type="button" disabled={!formState.isValid} onClick={createReport}>
                Rapor Oluştur
              </Button>
            </div>
          </Grid>

          <Grid item xs={2}>
            <div style={{ position: "relative", top: "8px" }}>
              <Button type="button" onClick={downloadExcel} disabled={!calculatedTransfers.length}>
                Raporu İndir
              </Button>
            </div>
          </Grid>
          {/* 
          <Grid item xs={4}>
            <div style={{ position: "relative", float: "right", top: "13px" }}>
              <Button type="button" onClick={() => setDefaultFormat(!defaultFormat)}>
                Format Değiştir
              </Button>
              {!defaultFormat && (
                <Button type="button" onClick={() => window.print()}>
                  Yazdır
                </Button>
              )}
            </div>
          </Grid> */}
        </Grid>
      </form>

      <div id="printableArea" style={{ display: "flex", flex: "1", flexDirection: "row" }}>
        <div style={{ width: "100%" }}>
          <table style={{ borderCollapse: `${defaultFormat ? "inherit" : "collapse"}` }}>
            <tbody>
              <tr>
                <th style={{ width: "100px" }}>Tarih</th>
                <th style={{ width: "120px" }}>İsim</th>
                <th style={{ width: "70px" }}>Havaalanı Saati</th>
                <th style={{ width: "200px" }}>Yolcular</th>
                <th style={{ width: "200px" }}>Sürücü</th>
                <th style={{ width: "200px" }}>Transfer</th>
              </tr>

              {calculatedTransfers.map((transfer, index) => (
                <tr
                  key={transfer.route._id + "asd"}
                  style={{
                    backgroundColor: defaultFormat ? "#dddddd" : index % 2 === 0 ? "#dddddd" : "#aaaaaa",
                    textAlign: "center",
                    position: "relative",
                    left: `${transfer.transferInfo.type.toString() === "100" || !defaultFormat ? "0" : "720px"}`,
                    borderBottom: `${defaultFormat ? "none" : "1px solid black"}`,
                  }}
                >
                  <td>{transfer.transferInfo.transferdate}</td>
                  <td>{transfer.transferInfo.name}</td>
                  <td>{transfer.transferInfo.transfertime.split(" ")[4].substring(0, 5)}</td>
                  <td>{peopleComponent(transfer.people)}</td>
                  <td>{driverComponent(transfer.route)}</td>
                  <td>{transfer.route.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TransferReport;
