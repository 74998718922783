import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import TabPanel from "shared/components/UIElements/TabPanel";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import CheckAccess from "components/shared/CheckAccess";

import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import Transfer from "./Transfer";
import TransferRegion from "./TransferRegion";
import TransferRegionNew from "./TransferRegionNew";

import { PERMISSION_TYPES } from "shared/util/constants";

import "./IkarusItem.css";

const IkarusManagement = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const navigate = useNavigate();

  //const [isLoading, setIsLoading] = React.useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-personel--${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  // const CheckAccessComponent = CheckAccess({ access: PERMISSION_TYPES.ACCESS_TO_IKARUS });
  // if (CheckAccessComponent) {
  //   return CheckAccessComponent;
  // }

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Ikarus Transfer Tabs"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Transferler" {...a11yProps(0)} />
          <Tab label="Bölgeler" {...a11yProps(1)} />
          <Tab label="Yeni Bölge Ekle" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Transfer></Transfer>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <TransferRegion></TransferRegion>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <TransferRegionNew></TransferRegionNew>
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};

export default IkarusManagement;
