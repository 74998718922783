import React, { useEffect, useState, useContext } from "react";

//import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// #region Components
import Chart from "./charts/chart/Chart";

import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

// #endregion Components

// #region Hooks
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import { CURRENT_EDUCATION_YEAR_ID } from "shared/util/constants";

import "./AccountingItem.css";

const ReportSummary = (props) => {
  const store = useContext(StoreContext);
  const [totalSchoolReceipts, setTotalSchoolReceipts] = useState([]);
  const [totalSchoolExpenses, setTotalSchoolExpenses] = useState([]);
  const [totalSchoolWithdraws, setTotalSchoolWithdraws] = useState([]);
  const [monthlyReceipts, setMonthlyReceipts] = useState([]);
  const [monthlyExpenses, setMonthlyExpenses] = useState([]);
  const [educationYear, setEducationYear] = useState(CURRENT_EDUCATION_YEAR_ID);

  const [reportCreated, setReportCreated] = useState(false);

  const { isLoading } = useHttpClient();

  useEffect(() => {
    const currentContractIds = store.contracts
      .filter((item) => item.year === educationYear)
      .map((item) => item.id.toString());

    let tempMonthlyReceipts = [
      {
        name: "Ocak",
        Total: 0,
      },
      {
        name: "Şubat",
        Total: 0,
      },
      {
        name: "Mart",
        Total: 0,
      },
      {
        name: "Nisan",
        Total: 0,
      },
      {
        name: "Mayıs",
        Total: 0,
      },
      {
        name: "Haziran",
        Total: 0,
      },
      {
        name: "Temmuz",
        Total: 0,
      },
      {
        name: "Ağustos",
        Total: 0,
      },
      {
        name: "Eylül",
        Total: 0,
      },
      {
        name: "Ekim",
        Total: 0,
      },
      {
        name: "Kasım",
        Total: 0,
      },
      {
        name: "Aralık",
        Total: 0,
      },
    ];
    let tempMonthlyExpenses = [
      {
        name: "Ocak",
        Total: 0,
      },
      {
        name: "Şubat",
        Total: 0,
      },
      {
        name: "Mart",
        Total: 0,
      },
      {
        name: "Nisan",
        Total: 0,
      },
      {
        name: "Mayıs",
        Total: 0,
      },
      {
        name: "Haziran",
        Total: 0,
      },
      {
        name: "Temmuz",
        Total: 0,
      },
      {
        name: "Ağustos",
        Total: 0,
      },
      {
        name: "Eylül",
        Total: 0,
      },
      {
        name: "Ekim",
        Total: 0,
      },
      {
        name: "Kasım",
        Total: 0,
      },
      {
        name: "Aralık",
        Total: 0,
      },
    ];

    let tempMonthlyWithdraws = [
      {
        name: "Ocak",
        Total: 0,
      },
      {
        name: "Şubat",
        Total: 0,
      },
      {
        name: "Mart",
        Total: 0,
      },
      {
        name: "Nisan",
        Total: 0,
      },
      {
        name: "Mayıs",
        Total: 0,
      },
      {
        name: "Haziran",
        Total: 0,
      },
      {
        name: "Temmuz",
        Total: 0,
      },
      {
        name: "Ağustos",
        Total: 0,
      },
      {
        name: "Eylül",
        Total: 0,
      },
      {
        name: "Ekim",
        Total: 0,
      },
      {
        name: "Kasım",
        Total: 0,
      },
      {
        name: "Aralık",
        Total: 0,
      },
    ];

    let schoolReceipts = [];
    schoolReceipts.push({
      key: "619c62eb5d123200169418a8",
      name: "SERVİSTESİN",
      amount: 0,
      finalAmount: 0,
    });
    let schoolExpenses = [];

    store.expenses.reduce((acc, expense) => {
      if (educationYear.toString() === CURRENT_EDUCATION_YEAR_ID.toString() && expense.date < "2023-09-01") {
        return acc;
      }

      if (educationYear.toString() !== CURRENT_EDUCATION_YEAR_ID.toString() && expense.date > "2023-09-01") {
        return acc;
      }

      //BURADA NORMAL TARIH KONTROLU OLACAK
      tempMonthlyExpenses[parseInt(expense.date.split("-")[1]) - 1]["Total"] += expense.amount;

      const currentSchoolExpense = schoolExpenses.find(
        (schoolExpense) => schoolExpense.key === expense.school.toString()
      );

      if (currentSchoolExpense) {
        currentSchoolExpense.amount = currentSchoolExpense.amount + expense.amount;
      } else {
        const schoolName = store.schoolOptions.find((school) => school.value === expense.school.toString()).label;

        schoolExpenses.push({
          key: expense.school.toString(),
          name: schoolName,
          amount: expense.amount,
        });
      }
      return acc + expense.amount;
    }, 0);

    setTotalSchoolExpenses(schoolExpenses);
    setMonthlyExpenses(tempMonthlyExpenses);

    store.receipts.reduce((acc, receipt) => {
      if (!currentContractIds.includes(receipt.contract.toString())) {
        return acc;
      }

      try {
        tempMonthlyReceipts[parseInt(receipt.date.split("-")[1]) - 1]["Total"] += receipt.amount;
      } catch {}

      const currentContract = store.contracts.find(
        (contract) =>
          contract._id.toString() === receipt.contract.toString() &&
          contract.year.toString() === educationYear.toString()
      );

      if (currentContract?.student) {
        const currentStudent = store.students.find(
          (student) => student._id.toString() === currentContract.student.id.toString()
        );

        const currentSchoolReceipt = schoolReceipts.find(
          (schoolReceipt) => schoolReceipt.key === currentStudent.school.toString()
        );

        if (currentSchoolReceipt) {
          currentSchoolReceipt.amount = currentSchoolReceipt.amount + receipt.amount;
        } else {
          const schoolName = store.schoolOptions.find(
            (school) => school.value === currentStudent.school.toString()
          ).label;

          const currentContracts = store.contracts.filter(
            (contract) =>
              contract?.student?.school.toString() === currentStudent?.school?.toString() &&
              contract?.year.toString() === educationYear.toString()
          );

          const totalContracts = currentContracts.reduce((acc, contract) => {
            return acc + contract.finalAmount;
          }, 0);

          schoolReceipts.push({
            key: currentStudent.school.toString(),
            name: schoolName,
            amount: receipt.amount,
            finalAmount: totalContracts,
          });
        }
      }
      if (currentContract) {
        return acc + receipt.amount;
      } else {
        return acc;
      }
    }, 0);

    setTotalSchoolReceipts(schoolReceipts);
    setMonthlyReceipts(tempMonthlyReceipts);

    let schoolWithdraws = [];
    store.withdraws.reduce((acc, withdraw) => {
      if (!currentContractIds.includes(withdraw.contract.toString())) {
        return acc;
      }

      tempMonthlyWithdraws[parseInt(withdraw.date.split("-")[1]) - 1]["Total"] += withdraw.amount;

      const currentContract = store.contracts.find(
        (contract) =>
          contract._id.toString() === withdraw.contract.toString() &&
          contract.year.toString() === educationYear.toString()
      );

      if (currentContract) {
        const currentStudent = store.students.find(
          (student) => student._id.toString() === currentContract.student.id.toString()
        );

        const currentSchoolWithdraw = schoolWithdraws.find(
          (schoolWithdraw) => schoolWithdraw.key === currentStudent.school.toString()
        );

        if (currentSchoolWithdraw) {
          currentSchoolWithdraw.amount = currentSchoolWithdraw.amount + withdraw.amount;
        } else {
          const schoolName = store.schoolOptions.find(
            (school) => school.value === currentStudent.school.toString()
          ).label;

          const currentContracts = store.contracts.filter(
            (contract) =>
              contract?.student?.school.toString() === currentStudent?.school.toString() &&
              contract?.year.toString() === educationYear.toString()
          );

          const totalWithdrawsAmount = currentContracts.reduce((acc, contract) => {
            return acc + contract.finalAmount;
          }, 0);

          schoolWithdraws.push({
            key: currentStudent.school.toString(),
            name: schoolName,
            amount: withdraw.amount,
            finalAmount: totalWithdrawsAmount,
          });
        }
      }
      if (currentContract) {
        return acc + withdraw.amount;
      } else {
        return acc;
      }
    }, 0);

    setTotalSchoolWithdraws(schoolWithdraws);

    // const totalExpense = store.expenses.reduce((acc, expense) => {
    //   monthlyExpenses[parseInt(expense.date.split('-')[1]) - 1]['Total'] += expense.amount;

    //   return acc + expense.amount;
    // }, 0);

    setReportCreated(true);
  }, [
    store.receipts,
    store.expenses,
    store.withdraws,
    store.schoolOptions,
    store.contracts,
    store.students,
    educationYear,
  ]);

  const yearChangeHandler = (id, value) => {
    setEducationYear(value);
    // const currentYearContracts = store.contracts.filter((item) => item.year === value )
    // const currentpageItems =  store.receipts.filter(item => isReceiptIntheSchool(item, Authorization.currentSchoolId, [...currentYearContracts]));
    // setPageItems(currentpageItems);
  };

  // #region Loading
  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  // const Item = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   minHeight: 80,
  //   alignItems: "center",
  //   justifyContent: "center",
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <React.Fragment>
      <div style={{ width: "300px", zIndex: "99", position: "relative" }}>
        <CustomSelect
          options={store.educationYearsOptions}
          id="year"
          onInput={(id, value) => yearChangeHandler(id, value)}
          label=""
          initialValid={true}
          initialValue={store.educationYearsOptions.find((item) => item.value.toString() === educationYear)}
        ></CustomSelect>
      </div>
      {reportCreated && (
        <div>
          <h4>GENEL ÖDEME BİLGİLERİ</h4>
          <TableContainer component={Paper} sx={{ minWidth: 650, width: "inherit" }}>
            <Table
              sx={{ minWidth: 650, width: "100%", justifyContent: "center", margin: "auto" }}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>YILLIK ALACAK</b>
                  </TableCell>
                  <TableCell>
                    <b>TOPLAM GELİR</b>
                  </TableCell>
                  <TableCell>
                    <b>TOPLAM GİDER</b>
                  </TableCell>
                  <TableCell>
                    <b>TOPLAM İADE</b>
                  </TableCell>
                  <TableCell>
                    <b>NET DURUM</b>
                  </TableCell>
                  <TableCell>
                    <b>KALAN TOPLAM ALACAK</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>
                    {" "}
                    {totalSchoolReceipts
                      .reduce((acc, receipt) => {
                        return acc + receipt.finalAmount;
                      }, 0)
                      .toLocaleString()}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {totalSchoolReceipts
                      .reduce((acc, receipt) => {
                        return acc + receipt.amount;
                      }, 0)
                      .toLocaleString()}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {totalSchoolExpenses
                      .reduce((acc, expense) => {
                        return acc + expense.amount;
                      }, 0)
                      .toLocaleString()}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {totalSchoolWithdraws
                      .reduce((acc, withdraw) => {
                        return acc + withdraw.amount;
                      }, 0)
                      .toLocaleString()}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {(
                      totalSchoolReceipts.reduce((acc, receipt) => {
                        return acc + receipt.finalAmount;
                      }, 0) -
                      totalSchoolExpenses.reduce((acc, expense) => {
                        return acc + expense.amount;
                      }, 0) -
                      totalSchoolWithdraws.reduce((acc, withdraw) => {
                        return acc + withdraw.amount;
                      }, 0)
                    ).toLocaleString()}
                  </TableCell>

                  <TableCell>
                    {(
                      totalSchoolReceipts.reduce((acc, receipt) => {
                        return acc + receipt.finalAmount;
                      }, 0) -
                      totalSchoolReceipts.reduce((acc, receipt) => {
                        return acc + receipt.amount;
                      }, 0) -
                      totalSchoolWithdraws.reduce((acc, withdraw) => {
                        return acc + withdraw.amount;
                      }, 0)
                    ).toLocaleString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <br />

          <h4>OKUL ÖDEME BİLGİLERİ</h4>
          <TableContainer component={Paper} sx={{ minWidth: 650, width: "inherit" }}>
            <Table
              sx={{ minWidth: 650, width: "100%", justifyContent: "center", margin: "auto" }}
              stickyHeader
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>OKUL</b>
                  </TableCell>
                  <TableCell>
                    <b>YILLIK ALACAK</b>
                  </TableCell>
                  <TableCell>
                    <b>TOPLAM GELİR</b>
                  </TableCell>
                  <TableCell>
                    <b>TOPLAM GİDER</b>
                  </TableCell>
                  <TableCell>
                    <b>TOPLAM İADE</b>
                  </TableCell>
                  <TableCell>
                    <b>YILLIK NET DURUM</b>
                  </TableCell>
                  <TableCell>
                    <b>KALAN ALACAK</b>
                  </TableCell>

                  {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {totalSchoolReceipts.map((row) => (
                  <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.finalAmount.toLocaleString()}</TableCell>
                    <TableCell>{row.amount.toLocaleString()}</TableCell>
                    <TableCell>
                      {totalSchoolExpenses
                        .find((schooleExpense) => schooleExpense.name === row.name)
                        ?.amount.toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {totalSchoolWithdraws
                        .find((schoolWithdraw) => schoolWithdraw.name === row.name)
                        ?.amount.toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {(
                        row.finalAmount -
                        (isNaN(totalSchoolExpenses.find((schooleExpense) => schooleExpense.name === row.name)?.amount)
                          ? 0
                          : totalSchoolExpenses.find((schooleExpense) => schooleExpense.name === row.name)?.amount) -
                        (isNaN(totalSchoolWithdraws.find((schoolWithdraw) => schoolWithdraw.name === row.name)?.amount)
                          ? 0
                          : totalSchoolWithdraws.find((schoolWithdraw) => schoolWithdraw.name === row.name)?.amount)
                      ).toLocaleString()}
                    </TableCell>

                    <TableCell>
                      {(
                        row.finalAmount -
                        row.amount -
                        -(isNaN(totalSchoolWithdraws.find((schoolWithdraw) => schoolWithdraw.name === row.name)?.amount)
                          ? 0
                          : totalSchoolWithdraws.find((schoolWithdraw) => schoolWithdraw.name === row.name)?.amount)
                      ).toLocaleString()}
                    </TableCell>
                    {/* <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br />
          <h4>AYLIK ÖDEME BİLGİLERİ</h4>
          {/* <FeaturedInfo totalReceipt={totalReceipt} totalExpense={totalExpense}></FeaturedInfo> */}
          {/* <FeaturedInfo totalReceipt={totalReceipt}></FeaturedInfo> */}
          <Chart data={monthlyReceipts} title="Aylara Göre Gelirler" grid dataKey="Total" />
          <Chart data={monthlyExpenses} title="Aylara Göre Giderler" grid dataKey="Total" />
        </div>
      )}
    </React.Fragment>
  );
};

export default ReportSummary;
