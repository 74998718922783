import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ShiftItem from "../components/shift/ShiftItem";
import NewShiftItem from "../components/shift/NewShiftItem";
import { getColumnValue } from "shared/util/datagrid";

import { StoreContext } from "shared/context/store-context";
import i18next from "i18next";
import Layout from "./shared/Layout";
import { days, days_en } from "shared/util/types";
import "./Admin.css";

const Shifts = () => {
  const { t } = useTranslation();
  const language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);

  const { shifts, schoolOptions, DeleteShift, InsertShift, UpdateShift } = useContext(StoreContext);
  const itemType = "shift";
  const listName = "main_shifts";

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     UpdateShifts();
  //   }, 60000);

  //   return () => clearInterval(interval);
  // }, [UpdateShifts]);

  const standardHelpItems = [
    "1. Şu anda erişim yetkinizin olduğu personel vardiyaları sekmesini görüntülemektesiniz.",
    "2. Filtreleri kullanarak ilgili vardiyalara daha kolay erişim sağlayabilirsiniz.",
    "3. Sütun isimlerine tıklayarak sıralamayı değiştirebilirsiniz.",
    "4. Vardiyaları ayrıntıları görmek ve düzenlemek için ilgili satıra tıklayın.",
    "5. Yeni vardiya eklemek için 'Yeni Vardiya Yarat' butonuna tıklayın.",
  ];

  const highlightedHelpItems = [
    "1. Vardiya listeleri otomatik olarak güncellenmekte, personeller vardiyalara kendilerini ekleyebilmektedir.",
    "2. Liste ekranındaki personel sayısı her 60 saniyede otomatik olarak güncellenmektedir.",
    "3. Aynı şekilde vardiya ayrıntıları ekranındaki bilgiler de her 60 saniyede otomatik olarak güncellenmektedir.",
    "4. Kullanıcılar ile senkron olma zorunluluğundan dolayı, bir vardiya ayrıntısını değiştirmek istediğinizde bunu 60 saniyelik bir süre içerisinde yapmanız gerekmektedir.",
    "5. Güncellemek istediğiniz bilgiyi girerek güncelle tuşuna 60 saniye içerisinde basmamanız durumunda ekrana girdiğiniz bilgiler kaybolacak ve kayıtlı bilgiler ile güncellenmiş olacaktır.",
  ];

  let listColumns = [];

  if (schoolOptions?.length > 1) {
    listColumns.push(getColumnValue("school", t("client"), schoolOptions, "school", 300));
  }

  listColumns.push({
    field: "day",
    headerName: t("day"),
    width: 200,
    valueGetter: (params) => {
      try {
        //return language === "en"
        return false
          ? days_en.find((day) => day.value.toString() === params.row.day.toString()).label
          : days.find((day) => day.value.toString() === params.row.day.toString()).label;
      } catch (e) {
        return "";
      }
    },
  });

  listColumns.push({ field: "name", headerName: t("name"), width: 250 });
  listColumns.push({ field: "description", headerName: t("description"), width: 300 });
  listColumns.push({
    field: "numberofpersonel",
    headerName: t("numberofpersonel"),
    width: 200,
    color: "red",
    valueGetter: (params) => {
      try {
        return params?.row?.users?.length?.toString() || "0";
      } catch (e) {
        return "Hata";
      }
    },
  });

  listColumns.push({ field: "serviceArrivalTime", headerName: t("shiftServiceArrivalTime"), width: 350 });
  listColumns.push({ field: "serviceLeaveTime", headerName: t("shiftServiceLeaveTime"), width: 350 });

  return (
    <React.Fragment>
      <Layout
        itemType={itemType}
        items={shifts}
        listName={listName}
        listColumns={listColumns}
        SelectedItemComponent={ShiftItem}
        NewItemComponent={ShiftItem}
        DeleteItem={DeleteShift}
        InsertItem={InsertShift}
        UpdateItem={UpdateShift}
        standardHelpItems={standardHelpItems}
        highlightedHelpItems={highlightedHelpItems}
      />
    </React.Fragment>
  );
};

export default Shifts;
