import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { SERVICE_TYPES } from "shared/util/constants";
import { getColumnValue } from "shared/util/datagrid";
import "./Admin.css";

import StudentItem from "../components/student/StudentItem";

const Students = (props) => {
  const { t } = useTranslation();
  const itemType = "student";
  const listName = "main_students";

  const { students, serviceType, vehicleRoutes, DeleteStudent, InsertStudent, UpdateStudent, schoolOptions } =
    useContext(StoreContext);

  let pageItems = [];

  if (props?.students) {
    pageItems = props.students;
  } else {
    pageItems = students;
  }

  let listColumns = [];

  if (schoolOptions?.length > 1) {
    listColumns.push(getColumnValue("school", t("client"), schoolOptions, "school", 300));
  }
  listColumns.push({ field: "name", headerName: t("name"), width: 350 });

  if (serviceType === SERVICE_TYPES.SCHOOL || serviceType === SERVICE_TYPES.SOUTHWIND) {
    listColumns.push({
      field: "routemorning",
      headerName: t("pickUpService"),
      width: 250,
      valueGetter: (params) => {
        try {
          const studentroutes = vehicleRoutes.filter(
            (route) =>
              route.type === 100 && route.student.map((student) => student.id).includes(params.row.id.toString())
          );
          if (studentroutes.length === 0) {
            return "-";
          } else {
            return vehicleRoutes.filter((route) =>
              route.student.map((student) => student.id).includes(params.row.id.toString())
            )[0].name;
          }
        } catch {
          return "-";
        }
      },
    });
  }

  if (serviceType === SERVICE_TYPES.SCHOOL || serviceType === SERVICE_TYPES.SOUTHWIND) {
    listColumns.push({
      field: "routeevening",
      headerName: t("dropOffService"),
      width: 250,
      valueGetter: (params) => {
        try {
          const studentroutes = vehicleRoutes.filter(
            (route) =>
              route.type === 200 && route.student.map((student) => student.id).includes(params.row.id.toString())
          );
          if (studentroutes.length === 0) {
            return "-";
          } else {
            return vehicleRoutes.filter((route) =>
              route.student.map((student) => student.id).includes(params.row.id.toString())
            )[0].name;
          }
        } catch {
          return "-";
        }
      },
    });
  }

  listColumns.push({ field: "identifier", headerName: t("description"), width: 450 });

  if (serviceType === SERVICE_TYPES.SCHOOL) {
    listColumns.push({
      field: "isActive",
      headerName: t("status"),
      width: 100,
      valueGetter: (params) => {
        const student = students.find((student) => student.id.toString() === params.row.id.toString());
        if (!student.hasOwnProperty("isActive")) {
          return "Aktif";
        } else {
          return student.isActive ? "Aktif" : "Pasif";
        }
      },
    });
  }

  if (serviceType === SERVICE_TYPES.SCHOOL) {
    listColumns.push({ field: "level", headerName: t("studentGrade"), width: 100 });
  }

  // listColumns.push({ field: "phonenumber", headerName: t("phone"), width: 350, hide: true });
  // listColumns.push({ field: "addressgeton", headerName: t("pickUpAddress"), width: 150, hide: true });
  // listColumns.push({ field: "addressgetoff", headerName: t("dropOffAddress"), width: 100, hide: true });

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={StudentItem}
      NewItemComponent={StudentItem}
      DeleteItem={DeleteStudent}
      InsertItem={InsertStudent}
      UpdateItem={UpdateStudent}
    />
  );
};

export default Students;
