import React from "react";
import MapWithTransferTimes from "./MapWithTransferTimes";

const MapContainer = ({ stops, origin, type, enablePolyline, transferStartFinishTime, onOptimize }) => {
  return (
    <MapWithTransferTimes
      stops={stops}
      origin={origin}
      type={type}
      enablePolyline={enablePolyline}
      transferStartFinishTime={transferStartFinishTime}
      isFullScreen={true}
      onOptimize={(newStops) => {
        onOptimize(newStops);
      }}
    />
  );
};

export default MapContainer;
