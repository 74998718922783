import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

const CheckAccess = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  if (!store.storeReady) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  } else if (props?.access && !auth.permissions[props.access]) {
    return <Navigate to="/not-found" replace />;
    // navigate("/not-found");
    // return (
    //   <div className="center">
    //     <LoadingSpinner />
    //   </div>
    // );
  } else {
    return <Outlet />;
  }
};

export default CheckAccess;
