import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import NotFound from "shared/components/UIElements/NotFound";
import MapSinglePoint from "components/shared/MapSinglePoint";
import FormFooter from "shared/components/FormElements/FormFooter";
import DeleteModal from "components/shared/DeleteModal";
import ItemGenerated from "components/shared/ItemGenerated";

import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";

import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import { USER_TYPES } from "shared/util/constants";

const DriverItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  //standard code
  const { t } = useTranslation();
  const [isItemLoaded, setIsItemLoaded] = useState(false);
  const [loadedItem, setLoadedItem] = useState();
  const [generatedItemId, setGeneratedItemId] = useState();
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // context
  const { drivers, schoolOptions } = useContext(StoreContext);

  // item info
  const itemType = "user";
  const endPointUpdate = "users";
  const endPointDelete = "users";
  const endPointCreate = "users";

  // item special states
  const [markerLocation, setMarkerLocation] = useState();
  const [userAddress, setUserAddress] = useState();
  const [addressInSync, setAddressInSync] = useState(false);

  // item special functions
  const mapDataChangeHandler = (address, location, inSync) => {
    setMarkerLocation(location);
    setUserAddress(address);
    setAddressInSync(inSync);
    inputHandler("address", address, true);
  };

  // formstates
  const [formState, inputHandler] = useForm(
    {
      //inputs
      name: { value: "", isValid: true },
      plate: { value: "", isValid: true },
      isshift: { value: false, isValid: true },
      phonenumber: { value: "", isValid: true },
      email: { value: "", isValid: true },
      address: { value: null, isValid: true },
      identity: { value: "", isValid: true },
    },
    true
  );

  // formstates fetch
  const fetchItem = useCallback(async () => {
    setIsItemLoaded(false);
    try {
      const currentUser = drivers.find((user) => user.id === itemId);
      setLoadedItem(currentUser);
      if (currentUser?.location && currentUser?.location.lat && currentUser?.location.lng) {
        setMarkerLocation(currentUser?.location);
        setAddressInSync(true);
      } else {
        setMarkerLocation(null);
        setAddressInSync(false);
      }

      setUserAddress(currentUser?.address);
    } catch (err) {}
    setIsItemLoaded(true);
  }, [drivers, itemId]);

  // standard code
  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  // item update
  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const infoToBeSent = {
        name: formState.inputs.name.value.toString().trim(),
        phonenumber: formState.inputs.phonenumber.value.toString().trim(),
        plate: formState.inputs.plate.value.toString().trim(),
        usertype: USER_TYPES.DRIVER,
        email: formState.inputs.email.value,
        address: userAddress,
        identity: formState.inputs.identity.value,
      };

      if (markerLocation) {
        infoToBeSent.location = { lat: markerLocation?.lat || 1.1, lng: markerLocation?.lng || 1.1 };
      }

      const endpoint = isNewItem
        ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
        : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

      const postType = isNewItem ? "POST" : "PATCH";

      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {
      if (err?.code) {
        window.toast.error(t("httpCodes." + err.code));
      } else {
        window.toast.error(t("actionFailed"));
      }
    }
  };

  //standard code
  if (isItemLoaded && !isNewItem && !loadedItem && !error) {
    return <NotFound item="driverItem"></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType="driverItem"
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      {isItemLoaded && (
        <form className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <div className="horizontal-flex">
            <Input
              id="name"
              element="input"
              type="text"
              label={t("name")}
              validators={[VALIDATOR_REQUIRE()]}
              errorText={t("requireField", { item: t("name") })}
              initialValue={loadedItem?.name}
              initialValid={isNewItem ? false : true}
              onInput={inputHandler}
            />
          </div>

          <div className="horizontal-flex">
            <Input
              id="phonenumber"
              element="input"
              type="text"
              label={t("phone")}
              validators={[VALIDATOR_NONE]}
              errorText={t("requireField", { item: t("phone") })}
              initialValue={loadedItem?.phonenumber || ""}
              initialValid={true}
              onInput={inputHandler}
            />

            <Input
              id="plate"
              element="input"
              type="text"
              label={t("driverPlate")}
              validators={[VALIDATOR_NONE()]}
              initialValue={loadedItem?.plate || loadedItem?.email || ""}
              initialValid={true}
              onInput={inputHandler}
            />
          </div>

          <div className="horizontal-flex">
            <Input
              id="identity"
              element="input"
              type="text"
              label={t("identityNumber")}
              validators={[VALIDATOR_NONE()]}
              errorText={t("requireField", { item: t("identityNumber") })}
              initialValue={loadedItem?.identity || ""}
              initialValid={true}
              onInput={inputHandler}
            />

            <Input
              id="email"
              element="input"
              type="text"
              label={t("email")}
              validators={[VALIDATOR_NONE()]}
              errorText={t("requireField", { item: t("email") })}
              onInput={inputHandler}
              initialValue={loadedItem?.email || ""}
              initialValid={true}
            />
          </div>

          <MapSinglePoint
            id="userItemMap"
            label={formState.inputs.name.value}
            initialAddress={userAddress || ""}
            initialAddressInSync={addressInSync}
            initialLocation={markerLocation}
            onInput={inputHandler}
            onMapDataChange={mapDataChangeHandler}
          ></MapSinglePoint>

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={!formState.isValid || !addressInSync || isLoading}
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default DriverItem;
