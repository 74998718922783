/* eslint-disable */
import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FormFooter from "shared/components/FormElements/FormFooter";
import NotFound from "shared/components/UIElements/NotFound";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import DeleteModal from "components/shared/DeleteModal";
import Modal from "shared/components/UIElements/Modal";
import ItemGenerated from "components/shared/ItemGenerated";
import { formatDate } from "shared/util/time";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_PHONENUMBER, VALIDATOR_NONE } from "shared/util/validators";
import { isCompanyReceiptOptions, userApproved } from "shared/util/types";
import { parseComplexDate, CheckAuthorization } from "shared/util/util";
import { GetParentFromStudent, GetPhoneNumberofStudent } from "shared/util/relationaldata";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import { t } from "i18next";
// #endregion Hooks

const ContractItem = ({ itemId, onClose, isNewItem, gotoItem, DeleteItem, UpdateItem, InsertItem }) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const Authorization = CheckAuthorization(auth.userId);
  const [generatedItemId, setGeneratedItemId] = useState();
  const [isItemLoaded, setIsItemLoaded] = useState(false);

  const itemType = "contracts";
  const endPointUpdate = "contracts";
  const endPointDelete = "contracts";
  const endPointCreate = "contracts";

  const [openSmsSend, setOpenSmsSend] = useState(false);

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();

  const [userApprovedStatus, setUserApproved] = useState();
  const [userApprovedKey, setUserApprovedKey] = useState();

  const [serviceStartDate, setServiceStartDate] = useState();
  const [serviceStartDateKey, setServiceStartDateKey] = useState();
  const [serviceEndingDate, setServiceEndingDate] = useState();
  const [serviceEndingDateKey, setServiceEndingDateKey] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [totalAmountKey, setTotalAmountKey] = useState();
  const [educationYear, setEducationYear] = useState();
  const [educationYearKey, setEducationYearKey] = useState();
  const [isCompanyReceipt, setIsCompanyReceipt] = useState(false);
  const [contractDate, setContractDate] = useState(false);
  const [contractDateKey, setContractDateKey] = useState(false);
  const [finalAmount, setFinalAmount] = useState();
  const [finalAmountKey, setFinalAmountKey] = useState();

  const [phoneNumber, setPhoneNumber] = useState();
  const [parent, setParent] = useState();

  const ItemTypeTR = "Sözleşme";
  const ItemType = "contract";
  const ItemEndPointType = "contracts";
  const APIEndPoint = "contracts";

  const updateStoreItems = store.RetrieveContracts;

  // #region Standard Interface functions
  const { isLoading, error, sendRequest } = useHttpClient();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);

  const showSMSHandler = () => {
    if (
      formStatePhoneNumber.inputs.phonenumber.value.trim().length === 10 &&
      formStatePhoneNumber.inputs.phonenumber.value.trim().toString().charAt(0) == "5"
    ) {
      setShowSmsModal(true);
    }
  };

  const cancelSMSHandler = () => {
    setShowSmsModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    if (isNewItem) {
      setIsItemLoaded(true);
      setLoadedItem({});
      return;
    }
    setIsItemLoaded(false);

    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);

      const myuser = GetParentFromStudent(
        store.userMap,
        store.studentToParentMap,
        responseData[ItemType]?.student?.toString()
      );

      if (myuser) {
        setParent(myuser);
      }
      if (myuser?.phonenumber) {
        setPhoneNumber(myuser.phonenumber);
      }

      setLoadedItem(responseData[ItemType]);
      // setItemState(Date.now().toString());
    } catch (err) {}
    setIsItemLoaded(true);
  });

  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [itemId]);

  const [formState, inputHandler, setFormData] = useForm(
    {
      payerName: { value: "", isValid: true },
      payerIdentity: { value: "", isValid: true },
      payerAddress: { value: "", isValid: true },
      payerMail: { value: "", isValid: true },
      student: { value: "", isValid: true },
      year: { value: "", isValid: true },
      date: { value: "", isValid: true },
      contractEnabled: { value: true, isValid: true },
      serviceTemplate: { value: "", isValid: true },
      serviceStartDate: { value: "", isValid: true },
      serviceEndingDate: { value: "", isValid: true },
      totalAmount: { value: "", isValid: true },
      finalAmount: { value: "", isValid: true },
      isCompanyReceipt: { value: isCompanyReceipt[0], isValid: true },
      taxNo: { value: "", isValid: true },
      taxOffice: { value: "", isValid: true },
      companyTitle: { value: "", isValid: true },
      standardDiscounts: { value: [], isValid: true },
      specialDiscount: { value: 0, isValid: true },
      specialDiscountDescription: { value: "", isValid: true },
      userApproved: { value: userApproved[1], isValid: true },
    },
    true
  );

  const [formStatePhoneNumber, inputHandlerPhoneNumber, setFormDataPhoneNumber] = useForm(
    {
      phonenumber: { value: "", isValid: false },
    },
    false
  );

  const checkContractStatus = async (tey) => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      const contractApprovedStatus = responseData[ItemType].userApproved;

      setUserApproved(contractApprovedStatus);
      setUserApprovedKey(contractApprovedStatus.toString() + "-" + Math.random());
      inputHandler("userApproved", contractApprovedStatus, true);
      loadedItem.userApproved = contractApprovedStatus;
    } catch (err) {}

    //loadedItem.contractEnabled ? setFormData({ ...formState, contractEnabled: { value: true, isValid: true } }) : setFormData({ ...formState, contractEnabled: { value: false, isValid: true } });
  };

  const sendContractApprovalLink = useCallback(async () => {
    setShowSmsModal(false);

    let message = `Öğrenci taşıma sözleşmeniz oluşturulmuştur. Lütfen Servistesin uygulaması üzerinden durak bilgilerinizi kontrol ediniz. Çetin Tur`;

    message = `Sözleşme onayınız için aşağıdaki bağlantıya tıklayınız. 
    https://filokar.com/gbvfdsrfdc?c=${loadedItem.id}`;

    // // const user = loadedItem.user.id.toString();
    // const user = "61e3c7a2cd698a001617f59e";
    // // const foundPhone = loadedItem.user.phonenumber;
    // const foundPhone = "5355802151";
    const phone = formStatePhoneNumber.inputs.phonenumber.value.trim();
    const date = formatDate(new Date());
    let text = message;
    let smsData = { user: parent, phone, text, date };

    // try {
    //   const messageResponse = await sendRequest(
    //     `${process.env.REACT_APP_BACKEND_URL}/gfhhdethjjdsd`,
    //     "POST",
    //     JSON.stringify(smsData),
    //     {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + auth.token,
    //     }
    //   );
    // } catch (err) {}

    // text = message;
    // smsData = { user: parent, phone, text, date };

    try {
      const messageResponse = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/gfhhdethjjdsd`,
        "POST",
        JSON.stringify(smsData),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {}

    setOpenSmsSend(true);
  });

  const SendSMS = async () => {
    const ioslink = "https://tinyurl.com/servistesinios";
    const androidlink = "https://tinyurl.com/servistesinandroid";

    //const message = `Sayın Kullanıcımız, Servistesin uygulaması üzerinde yeni sözleşmeniz oluşturulmuştur. Lütfen uygulama üzerinden sözleşmenizi ve durak bilgilerinizi kontrol ediniz. Uygulamamıza, Android işletim sistemleri için ${androidlink} Apple IOS işletim sistemleri için ${ioslink} bağlantılarından erişebilirsiniz.`;
    const message = `Sözleşmeniz güncellenmiştir. Lütfen Servistesin uygulaması üzerinden durak bilgilerinizi kontrol ediniz. Çetin Tur`;

    const foundPhone = loadedItem.user.phonenumber;

    const user = loadedItem.user.id.toString();
    const student = loadedItem.student.toString();
    const vehicleRoute = "62e32f313da7b2508040c4d7";
    const phone = foundPhone;
    const text = message;
    const date = formatDate(new Date());
    const status = 1;

    const smsData = { user, student, phone, text, date, status, vehicleRoute };

    try {
      const messageResponse = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/xrytutdvsdfds`,
        "POST",
        JSON.stringify(smsData),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {}
  };

  const itemUpdateSubmitHandler = useCallback(async (event) => {
    event.preventDefault();

    let specialDiscountToWrite = 0;
    try {
      specialDiscountToWrite = parseInt(formState.inputs.specialDiscount.value.toString().trim());
    } catch (err) {
      specialDiscountToWrite = 0;
    }

    if (specialDiscountToWrite === null) {
      specialDiscountToWrite = 0;
    }

    try {
      const infoToBeSent = {
        payerName: formState.inputs.payerName.value,
        payerIdentity: formState.inputs.payerIdentity.value,
        payerAddress: formState.inputs.payerAddress.value,
        payerMail: formState.inputs.payerMail.value,
        student: formState.inputs.student.value,
        year: formState.inputs.year.value,
        date: formState.inputs.date.value,
        contractEnabled: formState.inputs.contractEnabled.value,
        serviceTemplate: formState.inputs.serviceTemplate.value,
        serviceStartDate: formState.inputs.serviceStartDate.value,
        serviceEndingDate: formState.inputs.serviceEndingDate.value,
        totalAmount: formState.inputs.totalAmount.value,
        isCompanyReceipt: formState.inputs.isCompanyReceipt.value,
        taxNo: formState.inputs.taxNo.value,
        taxOffice: formState.inputs.taxOffice.value,
        companyTitle: formState.inputs.companyTitle.value,
        standardDiscounts: formState.inputs.standardDiscounts.value,
        specialDiscount: specialDiscountToWrite,
        specialDiscountDescription: formState.inputs.specialDiscountDescription.value,
        userApproved: formState.inputs.userApproved.value,
        finalAmount: formState.inputs.finalAmount.value,
      };

      const endpoint = isNewItem
        ? `${process.env.REACT_APP_BACKEND_URL}/${endPointCreate}`
        : `${process.env.REACT_APP_BACKEND_URL}/${endPointUpdate}/${itemId}`;

      const postType = isNewItem ? "POST" : "PATCH";

      const responseData = await sendRequest(endpoint, postType, JSON.stringify(infoToBeSent));

      if (responseData?.[itemType]) {
        if (isNewItem) {
          InsertItem(responseData[itemType]);
          setGeneratedItemId(responseData[itemType].id);
        } else {
          UpdateItem(responseData[itemType]);
          window.toast.success(t("updateSuccess"));
        }
      }
    } catch (err) {}
  });

  const applyServiceTemplate = useCallback(
    async (tey) => {
      const templateId = formState.inputs.serviceTemplate.value;
      const currentServiceTemplate = store.serviceTemplates.find(
        (serviceTemplate) => serviceTemplate.id === templateId
      );
      if (!currentServiceTemplate) {
        return;
      }

      setServiceStartDate(currentServiceTemplate.serviceStartDate);
      setServiceStartDateKey(currentServiceTemplate.serviceStartDate + "-" + Math.random());
      inputHandler("serviceStartDate", currentServiceTemplate.serviceStartDate, true);
      loadedItem.serviceStartDate = currentServiceTemplate.serviceStartDate;

      setServiceEndingDate(currentServiceTemplate.serviceEndingDate);
      setServiceEndingDateKey(currentServiceTemplate.serviceEndingDate + "-" + Math.random());
      inputHandler("serviceEndingDate", currentServiceTemplate.serviceEndingDate, true);
      loadedItem.serviceEndingDate = currentServiceTemplate.serviceEndingDate;

      setEducationYear(currentServiceTemplate.year.toString());
      setEducationYearKey(currentServiceTemplate.educationYear + "-" + Math.random());
      inputHandler("educationYear", currentServiceTemplate.educationYear, true);
      loadedItem.year = currentServiceTemplate.year.toString();
      //initialValue={store.educationYearsOptions.find((item) => item.value.toString() === educationYear)}

      const today = new Date();
      const date = parseComplexDate(today);
      setContractDate(date);
      setContractDateKey(date + "-" + Math.random());
      inputHandler("date", date, true);
      loadedItem.date = date;

      setTotalAmount(currentServiceTemplate.amount);
      setTotalAmountKey(currentServiceTemplate.amount + "-" + Math.random());
      inputHandler("totalAmount", currentServiceTemplate.amount, true);
      loadedItem.totalAmount = currentServiceTemplate.amount;
    },
    [formState.inputs.serviceTemplate.value, store.serviceTemplates]
  );

  const calculateFinalAmount = useCallback(async () => {
    const totalAmountHere = parseFloat(formState.inputs.totalAmount.value);
    const specialDiscount = parseFloat(formState.inputs.specialDiscount.value);
    const standardDiscounts = formState.inputs.standardDiscounts.value;

    let calculatedAmount = totalAmountHere;

    standardDiscounts.forEach((discount) => {
      const currentDiscount = store.discounts.find((item) => item.id.toString() === discount.toString());

      //1 --- Percentage Discount
      if (currentDiscount.type === "1") {
        calculatedAmount = calculatedAmount - (calculatedAmount * currentDiscount.amount) / 100;
      }

      //2 --- Constant Discount
      if (currentDiscount.type === "2") {
        calculatedAmount = calculatedAmount - currentDiscount;
      }
    });

    if (specialDiscount) {
      calculatedAmount = calculatedAmount - specialDiscount;
    }
    //setEducationYear(0);
    loadedItem.finalAmount = calculatedAmount;
    setFinalAmount(parseInt(calculatedAmount));
    setFinalAmountKey(parseInt(calculatedAmount) + "-" + Math.random());
    inputHandler("finalAmount", parseInt(calculatedAmount), true);
  }, [
    formState.inputs.totalAmount.value,
    formState.inputs.specialDiscount.value,
    formState.inputs.standardDiscounts.value,
  ]);

  const studentChangeHandler = (studentId) => {
    const myuser = GetParentFromStudent(store.userMap, store.studentToParentMap, studentId);
    if (myuser) {
      setParent(myuser);
    }
    if (myuser?.phonenumber) {
      setPhoneNumber(myuser.phonenumber);
    }
  };

  const companyReceiptUIHandler = (value) => {
    setIsCompanyReceipt(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSmsSend(false);
  };

  //standard code
  if (isItemLoaded && !isNewItem && !error) {
    return <NotFound item={itemType}></NotFound>;
  }

  if (generatedItemId) {
    return (
      <ItemGenerated
        itemType={itemType}
        onGotoNewItem={() => gotoItem(generatedItemId)}
        onCreateNewItem={() => setGeneratedItemId(null)}
        onClose={() => {
          setGeneratedItemId(false);
          onClose();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <DeleteModal
        endPoint={endPointDelete}
        itemType={itemType}
        itemId={itemId}
        onDeleted={() => {
          DeleteItem(itemId);
          onClose();
        }}
        onClose={() => setShowConfirmModal(false)}
        showModal={showConfirmModal}
      />

      <Modal
        show={showSmsModal}
        onCancel={cancelSMSHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelSMSHandler}>
              Hayır
            </Button>
            <Button danger onClick={sendContractApprovalLink}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>Kullanıcıya SMS ile sözleşme ve onay linki gönderilecek. Onaylıyor musunuz? Bu işlem geri alınamaz.</p>
        <p>
          Telefon numarası: <b>{formStatePhoneNumber.inputs.phonenumber.value.trim()}</b>
        </p>
      </Modal>
      {isItemLoaded && (
        <form key={itemstate} className="form-container" onSubmit={itemUpdateSubmitHandler}>
          <div className="horizontal-flex" style={{ justifyContent: "flex-start", alignItems: "center" }}>
            <div style={{ width: "400px" }}>
              <CustomSelect
                options={store.serviceTemplateOptions}
                id="serviceTemplate"
                onInput={inputHandler}
                label={t("contractTemplate")}
                initialValid={true}
                initialValue={store.serviceTemplateOptions.find(
                  (item) => item.value.toString() === loadedItem?.serviceTemplate?.toString()
                )}
              ></CustomSelect>
            </div>

            <div style={{ marginTop: "15px" }}>
              <Button type="button" danger onClick={applyServiceTemplate.bind(this, "apply")}>
                {t("applyTemplate")}
              </Button>
            </div>
          </div>

          <div className="groupContainer-accounting">
            <CustomSelect
              options={store.studentOptions}
              id="student"
              onInput={inputHandler}
              label={t("student.title")}
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={isNewItem ? false : true}
              initialValue={store.studentOptions.find(
                (item) => item.value.toString() === loadedItem?.student?.toString()
              )}
              fireChange={studentChangeHandler}
            ></CustomSelect>

            <div className="horizontal-flex">
              <Input
                id="payerName"
                element="input"
                type="text"
                label={t("payerName")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={loadedItem?.payerName?.toString() || ""}
                onInput={inputHandler}
              />

              <Input
                id="payerIdentity"
                element="input"
                type="text"
                label={t("identityNumber")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={loadedItem?.payerIdentity?.toString() || ""}
                onInput={inputHandler}
              />
            </div>

            <div className="horizontal-flex">
              <Input
                id="payerMail"
                element="input"
                type="text"
                label={t("email")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={loadedItem?.payerMail?.toString() || ""}
                onInput={inputHandler}
              />

              <Input
                id="payerAddress"
                element="input"
                type="text"
                label={t("invoiceAddress")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={loadedItem?.payerAddress?.toString() || ""}
                onInput={inputHandler}
              />
            </div>

            <div style={{ width: "200px" }}>
              <CustomSelect
                options={isCompanyReceiptOptions}
                id="isCompanyReceipt"
                onInput={inputHandler}
                label={t("isCompanyInvoice")}
                initialValue={isCompanyReceiptOptions.find(
                  (option) => option.value.toString() === loadedItem?.isCompanyReceipt?.toString()
                )}
                initialValid={true}
                fireChange={companyReceiptUIHandler}
              ></CustomSelect>
            </div>
            {isCompanyReceipt && (
              <div className="horizontal-flex">
                <Input
                  id="taxNo"
                  element="input"
                  type="text"
                  label={t("taxId")}
                  initialValue={loadedItem?.taxNo?.toString() || ""}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                  initialValid={true}
                />

                <Input
                  id="taxOffice"
                  element="input"
                  type="text"
                  label={t("taxOffice")}
                  initialValue={loadedItem?.taxOffice?.toString() || ""}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                  initialValid={true}
                />

                <Input
                  id="companyTitle"
                  element="input"
                  type="text"
                  label={t("companyName")}
                  initialValue={loadedItem?.companyTitle?.toString() || ""}
                  onInput={inputHandler}
                  validators={[VALIDATOR_NONE()]}
                  initialValid={true}
                />
              </div>
            )}
          </div>

          <div className="groupContainer-accounting">
            <div className="horizontal-flex" style={{ alignItems: "baseline", justifyContent: "flex-start" }}>
              <div style={{ width: "200px" }}>
                <CustomSelect
                  key={educationYearKey}
                  options={store.educationYearsOptions}
                  id="year"
                  onInput={inputHandler}
                  label={t("educationYear")}
                  initialValid={true}
                  initialValue={store.educationYearsOptions.find(
                    (item) => item.value.toString() === loadedItem?.year?.toString()
                  )}
                ></CustomSelect>
              </div>

              <DatePicker
                key={contractDateKey}
                id="date"
                onInput={inputHandler}
                label={t("contractDate")}
                initialValid={true}
                //initialValue={contractDate}
                initialValue={loadedItem?.date?.toString() || contractDate}
              ></DatePicker>

              <DatePicker
                key={serviceStartDateKey}
                id="serviceStartDate"
                onInput={inputHandler}
                label={t("serviceStartDate")}
                initialValid={true}
                initialValue={loadedItem?.serviceStartDate?.toString() || serviceStartDate}
              ></DatePicker>

              <DatePicker
                key={serviceEndingDateKey}
                id="serviceEndingDate"
                onInput={inputHandler}
                label={t("serviceEndDate")}
                validators={[VALIDATOR_REQUIRE()]}
                initialValid={true}
                initialValue={loadedItem?.serviceEndingDate?.toString() || serviceEndingDate}
              ></DatePicker>
            </div>
          </div>

          <div className="groupContainer-accounting">
            <CustomSelect
              options={store.discountOptions}
              id="standardDiscounts"
              onInput={inputHandler}
              label={t("standardDiscounts")}
              initialValid={true}
              isMulti={true}
              initialValue={store.discountOptions.filter((option) =>
                loadedItem?.standardDiscounts?.includes(option.value)
              )}
            ></CustomSelect>

            <Input
              id="specialDiscount"
              element="input"
              type="text"
              label={t("specialDiscountAmount")}
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={loadedItem?.specialDiscount?.toString() || "0"}
              onInput={inputHandler}
            />

            <Input
              id="specialDiscountDescription"
              element="input"
              type="text"
              label={t("specialDiscountDescription")}
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={loadedItem?.specialDiscountDescription?.toString() || ""}
              onInput={inputHandler}
            />
          </div>

          <CustomSelect
            key={userApprovedKey}
            options={userApproved}
            id="userApproved"
            onInput={inputHandler}
            label={t("userApprovedContract")}
            initialValue={userApproved.find((item) => item.value.toString() === loadedItem?.userApproved?.toString())}
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
            disabled
          ></CustomSelect>

          <Input
            key={phoneNumber}
            id="phonenumber"
            element="input"
            type="text"
            label={t("phone")}
            validators={[VALIDATOR_PHONENUMBER(10)]}
            errorText="5 ile başlayan 10 haneli telefon numarası giriniz."
            initialValid={true}
            onInput={inputHandlerPhoneNumber}
            initialValue={
              phoneNumber ? phoneNumber : "Öğrenci - Veli eşleşmesi bulunmadı. Bildirimler sekmesini tamamlayınız."
            }
          />

          <div className="button-container">
            <Button type="button" danger onClick={showSMSHandler} disabled={!phoneNumber}>
              Kullanıcıya sözleşme onayı gönder
            </Button>

            <Button type="button" danger onClick={checkContractStatus.bind(this, "apply")}>
              Sözleşme onayı durumunu güncelle
            </Button>
          </div>

          <Snackbar
            open={openSmsSend}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <MuiAlert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
              Kullanıcıya sms bilgilendirmesi ile sözleşme onayı gönderildi.
            </MuiAlert>
          </Snackbar>

          <Input
            key={totalAmountKey}
            id="totalAmount"
            element="input"
            type="text"
            label="Standart Hizmet Ücreti"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Hizmet ücretini giriniz."
            onInput={inputHandler}
            initialValue={loadedItem?.totalAmount?.toString() || ""}
            initialValid={true}
          />

          <Button type="button" danger onClick={calculateFinalAmount}>
            Ödenecek Ücreti Hesapla
          </Button>

          <Input
            key={finalAmountKey}
            id="finalAmount"
            element="input"
            type="text"
            label="Ödenecek Toplam"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Üstteki hizmet ücretini giriniz."
            onInput={inputHandler}
            initialValid={finalAmount}
            //initialValue={finalAmount}
            initialValue={loadedItem?.finalAmount?.toString() || ""}
            disabled={true}
          />

          <FormFooter
            isNewItem={isNewItem}
            onUpdate={itemUpdateSubmitHandler}
            onDelete={() => setShowConfirmModal(true)}
            isUpdating={isLoading}
            disabled={!formState.isValid || !formState.inputs.student.value || isLoading}
          />
        </form>
      )}
    </React.Fragment>
  );
};

export default ContractItem;
