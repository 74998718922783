import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "pages/shared/Layout";
import { StoreContext } from "shared/context/store-context";
import { SERVICE_TYPES } from "shared/util/constants";
import { getColumnValue } from "shared/util/datagrid";
import { vehicleroutetypes } from "shared/util/types";
import "./Admin.css";

import VehicleRouteItem from "components/vehicle-routes/VehicleRouteItem";

const VehicleRoutes = () => {
  const { t } = useTranslation();
  const itemType = "vehicleRoute";
  const listName = "main_vehicleRoutes";

  const { vehicleRoutes, schoolOptions, serviceType, DeleteVehicleRoute, InsertVehicleRoute, UpdateVehicleRoute } =
    useContext(StoreContext);
  const pageItems = vehicleRoutes;

  let listColumns = [];

  if (schoolOptions?.length > 1) {
    listColumns.push({
      field: "school",
      headerName: t("client"),
      width: 350,
      valueGetter: (params) => schoolOptions.find((school) => school.value === params.row?.school?.id)?.label,
    });
  }

  listColumns.push({ field: "name", headerName: t("name"), width: 350 });

  listColumns.push({
    field: "type",
    headerName: "Türü",
    width: 200,
    valueGetter: (params) => vehicleroutetypes.find((item) => item.value === params.row?.type)?.label || "-",
  });

  listColumns.push({
    field: "driver",
    headerName: t("driver"),
    width: 300,
    valueGetter: (params) =>
      Array.isArray(params.row?.driver) && params.row.driver.length > 0
        ? params.row.driver.map((driver) => driver.name).join(", ")
        : "-",
  });

  listColumns.push({
    field: "numOfStudents",
    headerName: t("numberofpassengers"),
    width: 200,
    valueGetter: (params) => params.row?.student?.length?.toString() || "-",
  });

  return (
    <Layout
      itemType={itemType}
      items={pageItems}
      listName={listName}
      listColumns={listColumns}
      SelectedItemComponent={VehicleRouteItem}
      NewItemComponent={VehicleRouteItem}
      DeleteItem={DeleteVehicleRoute}
      InsertItem={InsertVehicleRoute}
      UpdateItem={UpdateVehicleRoute}
      isAlwaysFullScreen={true}
    />
  );
};

export default VehicleRoutes;
