import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import GoogleMapReact from "google-map-react";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Marker from "shared/components/UIElements/Marker";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import { LocationFromAddress, AddressFromLocation } from "shared/util/location";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const NewStudentItem = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const [openError, setOpenError] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  const [markerLocationMorning, setMarkerLocationMorning] = useState();
  const [mapCenterMorning, setMapCenterMorning] = useState();
  const [studentAddressMorning, setStudentAddressMorning] = useState();

  const [markerLocationEvening, setMarkerLocationEvening] = useState();
  const [mapCenterEvening, setMapCenterEvening] = useState();
  const [studentAddressEvening, setStudentAddressEvening] = useState();

  const updateStoreItems = store.UpdateStudents;
  let ItemTypeTR = "Kullanıcı Konumu";
  const ItemEndPointType = "students";
  const APIEndPoint = "students";

  if (auth.schoolType === "1") {
    ItemTypeTR = "Öğrenci";
  }

  const itemSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          school: formState.inputs.school.value,
          identifier: formState.inputs.identifier.value,
          name: formState.inputs.name.value,
          phonenumber: formState.inputs.phonenumber.value,
          email: formState.inputs.email.value,
          level: formState.inputs.level.value,
          addressgeton: formState.inputs.addressgeton.value,
          locationgeton: { lat: markerLocationMorning?.lat || 1.1, lng: markerLocationMorning?.lng || 1.1 },
          addressgetoff: formState.inputs.addressgetoff.value,
          locationgetoff: { lat: markerLocationEvening?.lat || 1.1, lng: markerLocationEvening?.lng || 1.1 },
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
      navigate(`/${ItemEndPointType}`);
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: false },
      identifier: { value: "", isValid: false },
      name: { value: "", isValid: false },
      phonenumber: { value: "", isValid: true },
      email: { value: "", isValid: true },
      level: { value: "", isValid: true },
      addressgeton: { value: "", isValid: false },
      addressgetoff: { value: "", isValid: false },
    },
    false
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const mapClickHandler = async (routetype, event) => {
    const currentLocation = { lat: event.lat, lng: event.lng };
    let address = null;
    if (routetype === "geton") {
      try {
        address = await AddressFromLocation(currentLocation);
      } catch (error) {
        setOpenError(true);
      }
      if (address) {
        setMarkerLocationMorning(currentLocation);
        setStudentAddressMorning(address);
        inputHandler("addressgeton", address, true);
      } else {
        setOpenError(true);
        setStudentAddressMorning("-");
        inputHandler("addressgeton", "-", false);
      }
    }

    if (routetype === "getoff") {
      try {
        address = await AddressFromLocation(currentLocation);
      } catch (error) {
        setOpenError(true);
      }
      if (address) {
        setMarkerLocationEvening(currentLocation);
        setStudentAddressEvening(address);
        inputHandler("addressgetoff", address, true);
      } else {
        setOpenError(true);
        setStudentAddressEvening("-");
        inputHandler("addressgetoff", "-", false);
      }
    }
  };

  const SetLocationFromAddress = async (routetype) => {
    let location;
    if (routetype === "geton") {
      try {
        location = await LocationFromAddress(formState.inputs.addressgeton.value);
      } catch (error) {
        setMarkerLocationMorning(null);
        setOpenError(true);
        return;
      }
      setMarkerLocationMorning(location);
      setMapCenterMorning(location);
    }
    if (routetype === "getoff") {
      try {
        location = await LocationFromAddress(formState.inputs.addressgetoff.value);
      } catch (error) {
        setMarkerLocationEvening(null);
        setOpenError(true);
        return;
      }
      setMarkerLocationEvening(location);
      setMapCenterEvening(location);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {t("noMatchingLocation")}
        </MuiAlert>
      </Snackbar>

      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>
        <CustomSelect
          options={store.schoolOptions}
          id="school"
          onInput={inputHandler}
          label="Kurum*"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Zorunlu alan"
        ></CustomSelect>
        <Input
          id="name"
          element="input"
          type="text"
          label="İsim*"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={inputHandler}
          errorText="Zorunlu alan"
        />
        <Input
          id="identifier"
          element="input"
          type="text"
          label="Açıklama*"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Zorunlu alan"
          onInput={inputHandler}
        />
        <Input
          id="level"
          element="input"
          type="text"
          label="Sınıf"
          validators={[VALIDATOR_NONE()]}
          onInput={inputHandler}
        />

        <Input
          key={studentAddressMorning}
          id="addressgeton"
          element="input"
          type="text"
          label="Biniş Adresi*"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Adres giriniz."
          // initialValue={loadedItem.address}
          initialValue={studentAddressMorning}
          onInput={inputHandler}
        />

        <Button type="button" danger onClick={SetLocationFromAddress.bind(this, "geton")}>
          {t("getLocationFromAddress")}
        </Button>

        {markerLocationMorning && (
          <div className="map-container">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
              }}
              //defaultCenter={markerLocationMorning}
              options={function (maps) {
                return {
                  mapTypeControl: true,
                  mapTypeId: "roadmap",
                  mapTypeControlOptions: {
                    mapTypeIds: ["satellite", "roadmap"],
                    style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: maps.ControlPosition.TOP_LEFT,
                  },
                };
              }}
              center={mapCenterMorning}
              defaultZoom={16}
              onClick={mapClickHandler.bind(this, "geton")}
            >
              <Marker Icon={RoomIcon} lat={markerLocationMorning.lat} lng={markerLocationMorning.lng} />
            </GoogleMapReact>
            {/* <Map center={GetCoordinates(loadedItem.address)} zoom={16} /> */}
          </div>
        )}

        <Input
          key={studentAddressEvening}
          id="addressgetoff"
          element="input"
          type="text"
          label="İniş Adresi*"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Adres giriniz."
          // initialValue={loadedItem.address}
          initialValue={studentAddressEvening}
          onInput={inputHandler}
        />

        <Button type="button" danger onClick={SetLocationFromAddress.bind(this, "getoff")}>
          {t("getLocationFromAddress")}
        </Button>

        {markerLocationEvening && (
          <div className="map-container">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
              }}
              options={function (maps) {
                return {
                  mapTypeControl: true,
                  mapTypeId: "roadmap",
                  mapTypeControlOptions: {
                    mapTypeIds: ["satellite", "roadmap"],
                    style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: maps.ControlPosition.TOP_LEFT,
                  },
                };
              }}
              //defaultCenter={markerLocationMorning}
              center={mapCenterEvening}
              defaultZoom={16}
              onClick={mapClickHandler.bind(this, "getoff")}
            >
              <Marker Icon={RoomIcon} lat={markerLocationEvening.lat} lng={markerLocationEvening.lng} />
            </GoogleMapReact>
            {/* <Map center={GetCoordinates(loadedItem.address)} zoom={16} /> */}
          </div>
        )}

        <Input
          id="phonenumber"
          element="input"
          type="text"
          label="Telefon"
          validators={[VALIDATOR_NONE()]}
          initialValid={true}
          onInput={inputHandler}
        />

        <Input
          id="email"
          element="input"
          type="text"
          label="Email"
          validators={[VALIDATOR_NONE()]}
          initialValid={true}
          onInput={inputHandler}
        />

        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewStudentItem;
