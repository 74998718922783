import React, { useContext } from "react";

import Tabs from "components/shared/Tabs";
import { useTranslation } from "react-i18next";

import { AuthContext } from "shared/context/auth-context";
import Plans from "components/settings/Plans";
// import DistributeStudents from "./DistributeStudents";
// import BulkProcesses from "./BulkProcesses";
// import ExcelAnalysis from "./ExcelAnalysis";
// import ServiceReports from "./ServiceReports";
import { PERMISSION_TYPES } from "shared/util/constants";
// import TransferRegion from './TransferRegion';
// import TransferRegionNew from './TransferRegionNew';

const AnalysisManagement = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  let analysisTabs = [];

  analysisTabs.push({
    label: t("clientInformation"),
    content: <Plans></Plans>,
  });

  // analysisTabs.push({
  //   label: t("plans"),
  //   content: <Plans></Plans>,
  // });

  // if (auth.permissions[PERMISSION_TYPES.ACCESS_TO_BULK_PROCESSES]) {
  //   analysisTabs.push({
  //     label: "Toplu İşlemler",
  //     content: <BulkProcesses></BulkProcesses>,
  //   });
  // }

  // if (auth.permissions[PERMISSION_TYPES.ACCESS_TO_DISTRIBUTE_STUDENTS]) {
  //   analysisTabs.push({
  //     label: "Öğrenci Dağıtımı",
  //     content: <DistributeStudents></DistributeStudents>,
  //   });
  // }

  // if (auth.permissions[PERMISSION_TYPES.ACCESS_TO_EXCEL_ANALYSIS]) {
  //   analysisTabs.push({
  //     label: "Excel İşlemleri",
  //     content: <ExcelAnalysis></ExcelAnalysis>,
  //   });
  // }

  // if (auth.permissions[PERMISSION_TYPES.ACCESS_TO_SERVICE_REPORTS]) {
  //   analysisTabs.push({
  //     label: "Vardiya Servis Raporları",
  //     content: <ServiceReports></ServiceReports>,
  //   });
  // }

  // const analysisTabs = React.useMemo(() => {
  //   return [
  //     {
  //       label: "Eksik Veriler",
  //       content: <DataErrors></DataErrors>,
  //     },
  //     {
  //       label: "Toplu İşlemler",
  //       content: <BulkProcesses></BulkProcesses>,
  //     },
  //     {
  //       label: "Öğrenci Dağıtımı",
  //       content: <DistributeStudents></DistributeStudents>,
  //     },
  //     {
  //       label: "Excel İşlemleri",
  //       content: <ExcelAnalysis></ExcelAnalysis>,
  //     },
  //     // {
  //     //   label: "Servis İşlemleri",
  //     //   component: <ServiceAnalysis></ServiceAnalysis>,
  //     // },
  //   ];
  // }, []);

  // if (!auth.permissions.accessToAnalysis) {
  //   return <NotFound />;
  // }

  return (
    <React.Fragment>
      <Tabs tabs={analysisTabs} tabValue={tabValue} onTabChange={handleTabChange} isVertical={true} />
    </React.Fragment>
  );
};

export default AnalysisManagement;
